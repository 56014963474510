/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { React, useState, useEffect } from 'react';
import '../../style/Site/eventsView.css';
import '../../style/Site/bankEvents.css';
import { connect } from 'react-redux';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  useTransition, animated, config,
} from 'react-spring';
import {
  applyTypeFilter,
  applyGenreFilter,
  getLocation,
  getEventTypeName,
  getEventTypeIcon,
  getSponsor,
  getDate,
  getTime,
  getFilteredEvents,
  getFilteredLocations,
  getRestaurantsWithoutBranches,
  getFirstGenreName,
  filtersWipe,
} from '../../service';

import { endpointCloudfront } from '../../endpoints';
import Filters from '../Filters/Filters';
import {
  FilterIcon, MapIcon, UbicationFilterWhere, RestaurantsIcon,
} from '../../img';

import { setFilters, setOpenFilters } from '../../state/AppState';
import Event from '../Events/Event';
import Events from '../Events/Events';
import Footer from '../Layouts/Footer';
import OrderBy from '../Filters/OrderBy';
import ResultsFilters from '../Filters/ResultsFilters';
import Clients from '../Advertisments/Clients';
import Header from '../Layouts/Header';

import Location from '../Events/Location';

function EventsView({
  events,
  locations,
  localities,
  eventTypes,
  sponsors,
  filters,
  setFiltersState,
  restaurants,
  openFilters,
  setOpenFiltersState,
  genres,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });

    const filtrar = searchParams.get('filtrar');

    if (filtrar !== null) {
      if (filtrar === 'carnaval') {
        applyTypeFilter(5, setFiltersState);
      } else if (filtrar === 'concurso-oficial') {
        applyGenreFilter('82', 5, setFiltersState);
      } else if (filtrar === 'tablados') {
        applyGenreFilter('83', 5, setFiltersState);
      } else if (filtrar === 'festivales') {
        applyGenreFilter('84', 5, setFiltersState);
      } else if (filtrar === 'llamadas') {
        applyGenreFilter('85', 5, setFiltersState);
      }
    }
  }, []);

  const [orderBy, setOrderBy] = useState('relevance');

  const params = useParams();
  const { id } = params;

  const sponsor = id ? getSponsor(id, sponsors, true) : false;

  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [showingRestaurants, setShowingRestaurants] = useState(false);
  const [showingCarnaval, setShowingCarnaval] = useState(false);

  const [metatitle, setMetatitle] = useState(false);
  const [metadescription, setMetadescription] = useState(false);

  useEffect(() => {
    if (sponsor) {
      const getAsync = async () => {
        const filteredRestaurants = await getFilteredLocations(
          {
            sponsor: [parseInt(sponsor.id)],
            distance: filters.distance,
            neighbourhood: filters.neighbourhood,
            city: filters.city,
          },
          getRestaurantsWithoutBranches(restaurants),
          localities,
          false,
          false,
        );
        if (sponsor.id && filteredRestaurants.length === 0) {
          setShowingRestaurants(false);
        }
        setFilteredRestaurants(filteredRestaurants);
      };
      getAsync();
    } else {
      setFilteredRestaurants(restaurants);
    }
  }, [sponsor, filters, showingRestaurants]);

  useEffect(() => {
    if (sponsor) {
      filtersWipe(setFiltersState);
    }
  }, [sponsor]);

  const [filteredEvents, setFilteredEvents] = useState([]);
  const [filteredEventsMusic, setFilteredEventsMusic] = useState([]);
  const [filteredEventsCinema, setFilteredEventsCinema] = useState([]);
  const [filteredEventsTheatre, setFilteredEventsTheatre] = useState([]);
  const [filteredEventsOtherEvents, setFilteredEventsOtherEvents] = useState([]);
  const [filteredEventsCarnaval, setFilteredEventsCarnaval] = useState([]);
  const [filteredEventsFiestas, setFilteredEventsFiestas] = useState([]);
  const [slidersQuantity, setSlidersQuantity] = useState(0);

  function asyncGetEvents(filters) {
    const result = getFilteredEvents(
      filters,
      events,
      locations,
      localities,
      orderBy,
      sponsor,
    );
    return result;
  }

  const sliders = [
    { type: 1, events: filteredEventsMusic, subtitle: 'Este mes' },
    { type: 3, events: filteredEventsTheatre, subtitle: 'Próximas funciones' },
    { type: 2, events: filteredEventsCinema, subtitle: 'Últimos estrenos' },
    { type: 5, events: filteredEventsCarnaval, subtitle: 'Próximos espectáculos' },
    { type: 6, events: filteredEventsFiestas, subtitle: 'Este mes' },
    { type: 4, events: filteredEventsOtherEvents, subtitle: '' },
  ];

  const slidersCarnaval = genres.filter((genre) => (genre.type_id === '5'));

  useEffect(() => {
    const asyncGet = async () => {
      const filteredEvents = await getFilteredEvents(
        filters,
        events,
        locations,
        localities,
        orderBy,
        sponsor,
      );
      setFilteredEvents([]);

      const showingCarnavalBool = (filters.type.length === 1 && filters.type.includes(5) && filters.genre.length === 0);

      setTimeout(() => {
        setFilteredEvents(filteredEvents);
        if (sponsor.id && filteredEvents.length === 0) {
          setShowingRestaurants(true);
        }
        setShowingCarnaval(showingCarnavalBool);
      }, 0);
      const filtersLength = Object.values(filters).flat().length;
      if (filteredEvents.length === events.length || filters.type.length === 0) {
        const filteredEventsMusic = await asyncGetEvents(filtersLength === 0 ? {
          date: ['Este mes'],
          type: [1],
          quantity: 20,
        } : { ...filters, type: [1] });
        setFilteredEventsMusic(filteredEventsMusic);
        const filteredEventsCinema = await asyncGetEvents(filtersLength === 0 ? {
          date: ['Este mes'],
          type: [2],
          quantity: 20,
        } : { ...filters, type: [2] });
        setFilteredEventsCinema(filteredEventsCinema);
        const filteredEventsTheatre = await asyncGetEvents(filtersLength === 0 ? {
          date: ['Este mes'],
          type: [3],
          quantity: 20,
        } : { ...filters, type: [3] });
        setFilteredEventsTheatre(filteredEventsTheatre);
        const filteredEventsOtherEvents = await asyncGetEvents(filtersLength === 0 ? {
          date: ['Este mes'],
          type: [4],
          quantity: 20,
        } : { ...filters, type: [4] });
        setFilteredEventsOtherEvents(filteredEventsOtherEvents);
        const filteredEventsFiestas = await asyncGetEvents(filtersLength === 0 ? {
          date: ['Este mes'],
          type: [6],
          quantity: 20,
        } : { ...filters, type: [6] });
        setFilteredEventsFiestas(filteredEventsFiestas);
      }
      if (filteredEvents.length === events.length || filters.type.length === 0 || showingCarnavalBool) {
        const filteredEventsCarnaval = await asyncGetEvents(filtersLength === 0 ? {
          date: ['Este mes'],
          type: [5],
          quantity: 20,
        } : { ...filters, type: [5] });
        setFilteredEventsCarnaval(filteredEventsCarnaval);
      }
    };
    asyncGet();
  }, [id, filters, orderBy, events]);

  useEffect(() => {
    if (filteredEventsCarnaval.length > 0) {
      slidersCarnaval.forEach((genre, key) => {
        slidersCarnaval[key].events = filteredEventsCarnaval.filter(
          (event) => (JSON.parse(event.genres).includes(genre.id)),
        );
      });
    } else {
      slidersCarnaval.forEach((genre, key) => {
        slidersCarnaval[key].events = [];
      });
    }
  }, [filteredEventsCarnaval]);

  useEffect(() => {
    setSlidersQuantity(sliders.map((s) => (Object.values(s.events).flat().length ? 1 : 0))
      .reduce((prev, next) => prev + next, 0));
  }, [sliders]);

  useEffect(() => {
    if (filters.type.length === 1) {
      if (filters.type.includes(5) && filters.genre.length !== 1) {
        setMetatitle('VOY ☆ Carnaval 2023: Descubrí todos los eventos');
        setMetadescription('Toda la Cartelera del Carnaval del Uruguay. Encontrá todos los eventos: Tablados, Concurso Oficial, Llamadas, Comparsas, Murgas, Parodistas, Humoristas, Revista y más');
        setSearchParams({ filtrar: 'carnaval' });
      } else if (filters.type.includes(5) && filters.genre.length === 1 && filters.genre.includes('82')) {
        setMetatitle('VOY ☆ Concurso Oficial Carnaval 2023: Descubrí todos los eventos');
        setMetadescription('Toda la cartelera del Concurso Oficial del Carnaval 2023 en Uruguay: Murgas, Parodistas, Humoristas y más');
        setSearchParams({ filtrar: 'concurso-oficial' });
      } else if (filters.type.includes(5) && filters.genre.length === 1 && filters.genre.includes('83')) {
        setMetatitle('VOY ☆ Tablados Carnaval 2023: Descubrí todos los eventos');
        setMetadescription('Toda la cartelera de los Tablados en Uruguay: Murgas, Parodistas, Humoristas y más');
        setSearchParams({ filtrar: 'tablados' });
      } else if (filters.type.includes(5) && filters.genre.length === 1 && filters.genre.includes('84')) {
        setMetatitle('VOY ☆ Festivales Carnaval 2023: Descubrí todos los eventos');
        setMetadescription('Toda la Cartelera del Carnaval del Uruguay. Encontrá todos los eventos');
        setSearchParams({ filtrar: 'festivales' });
      } else if (filters.type.includes(5) && filters.genre.length === 1 && filters.genre.includes('85')) {
        setMetatitle('VOY ☆ Llamadas y Comparsas Carnaval 2023: Descubrí todos los eventos');
        setMetadescription('Toda la Cartelera del Carnaval del Uruguay. Encontrá todos los eventos');
        setSearchParams({ filtrar: 'llamadas' });
      }
    } else {
      setMetatitle('VOY ☆ Todos los Eventos Culturales del Uruguay');
      setMetadescription('Toda la Cartelera de Eventos del Uruguay. Encontrá entradas con descuentos. Música, Teatro, Cine, Fiestas y más!');
      setSearchParams(false);
    }
  }, [filters]);

  const slidersTrail = useTransition(sliders, {
    from: { opacity: 0, x: 100 },
    enter: { opacity: 1, x: 0 },
    // leave: { opacity: 0, scale: 0.5 },
    trail: 325,
    config: config.slow,
  });

  const slidersTrailCarnaval = useTransition(slidersCarnaval, {
    from: { opacity: 0, x: 100 },
    enter: { opacity: 1, x: 0 },
    // leave: { opacity: 0, scale: 0.5 },
    trail: 325,
    config: config.slow,
  });

  const eventsTrail = useTransition(filteredEvents, {
    from: { opacity: 0, scale: 0.625 },
    enter: { opacity: 1, scale: 1 },
    // leave: { opacity: 0, scale: 0.5 },
    trail: 80,
    config: config.slow,
  });

  const restaurantsTrail = useTransition(filteredRestaurants, {
    from: { opacity: 0, scale: 0.625 },
    enter: { opacity: 1, scale: 1 },
    // leave: { opacity: 0, scale: 0.5 },
    trail: 80,
    config: config.slow,
  });

  return (
    <div className="container-event">
      <Helmet>
        <title>{metatitle}</title>
        <meta name="title" content={metatitle} />
        <meta name="description" content={metadescription} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
      </Helmet>
      {sponsor ? (
        <div className="container-bannerBank">
          <div className="bannerBank">
            <img
              src={endpointCloudfront + sponsor.bannerFile?.webp_url}
              alt="icon"
              className="imgBannerBank"
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <Header />
      <div className={`container-events-view${sponsor ? ' sponsors' : ''}`}>
        <div className="container-recommended-filters-made">
          <div className="desktopElement">
            <ResultsFilters />
          </div>

          {sponsor && filteredRestaurants.length > 0 && (
            <div className="pricing-toggle">
              <input
                type="radio"
                id="pricing-toggle-event"
                name="pricing"
                value="event"
                onClick={() => setShowingRestaurants(false)}
                checked={!showingRestaurants}
              />
              <label className="radio-button" htmlFor="pricing-toggle-event">
                {' '}
                Eventos
              </label>

              <input
                type="radio"
                id="pricing-toggle-gastronomy"
                name="pricing"
                value="gastronomy"
                onClick={() => setShowingRestaurants(true)}
                checked={showingRestaurants}
              />
              <label
                className="radio-button"
                htmlFor="pricing-toggle-gastronomy"
              >
                {' '}
                Gastronomía
              </label>
            </div>
          )}

          <div className="desktopElement filters-view-map-container">
            <Link to="/view-map" class="filters-view-map-link">
              <button type="button" className="filters-view-map">
                <img src={MapIcon} alt="Mapa" />
                Ver Mapa
              </button>
            </Link>
          </div>

          <div className="desktopElement">
            <OrderBy orderBy={orderBy} setOrderBy={setOrderBy} />
          </div>
        </div>

        <div className="mobileElement">
          <div className="container-event-view-header">
            <button type="button" onClick={() => { setOpenFiltersState(!openFilters); }}>
              <img src={FilterIcon} alt="Filtrar" />
              Filtrar
            </button>
            <Link to="/view-map">
              <button type="button">
                <img src={MapIcon} alt="Mapa" />
                Mapa
              </button>
            </Link>
            <OrderBy orderBy={orderBy} setOrderBy={setOrderBy} />
          </div>
        </div>

        <div className="sub-container-event-view">
          <div className="container-filters">
            <Filters sponsor={sponsor} forRestaurants={showingRestaurants} />
          </div>

          <div className="container-event-view-filters mobileElement">
            <ResultsFilters />
          </div>

          {!showingRestaurants && !showingCarnaval && (
            <div className="container-event-type-view">
              {(events?.length !== filteredEvents.length && filters.type.length > 0)
                || (sponsor && (slidersQuantity === 1)) ? (
                  eventsTrail((style, item, key, index) => {
                    const location = getLocation(
                      item.eventFunctions[0].location_id,
                      locations,
                    );

                    const eventPromotions = sponsor
                      ? item.eventPromotions?.filter((promo) => {
                        if (promo.sponsor_id === sponsor.id) {
                          return (
                            promo
                          );
                        }
                        return false;
                      }) : (item.eventPromotions);

                    // console.log(getSponsor(eventPromotions[0]?.sponsor_id, sponsors));

                    return (
                      <animated.div
                        key={item.id}
                        style={index < 9 ? { ...style } : null}
                        className="event eventButton"
                      >
                        <Event
                          lazyLoad
                          key={item.id}
                          ImgEventDesktop={endpointCloudfront + item.desktopFile?.webp_thumbnail_url}
                          ImgEventMobile={endpointCloudfront + item.desktopFile?.webp_thumbnail_url}
                          iconEvent={getEventTypeIcon(
                            item.event_type_id,
                            eventTypes,
                          )}
                          iconEventInfo="Icon Music"
                          typeEvent={getEventTypeName(
                            item.event_type_id,
                            eventTypes,
                          )}
                          otherEventsTypeEvent={item.event_type_id === '4' ? getFirstGenreName(item.genres, genres) : ''}
                          idEvent={item.id}
                          link={item.link}
                          titleEvent={item.name}
                          hourEvent={`${getDate(item.eventFunctions[0].date)}, ${getTime(item.eventFunctions[0].start_time)}
                      ${item.eventFunctions.length > 1 ? ` - ${getDate(item.eventFunctions[item.eventFunctions.length - 1].date)}, 
                      ${getTime(item.eventFunctions[item.eventFunctions.length - 1].start_time)}` : ''}`}
                          locationEvent={location?.name}
                          cocardaBank={
                          getSponsor(
                            eventPromotions[0]?.sponsor_id,
                            sponsors,
                          ).file?.webp_thumbnail_url
                        }
                        />
                      </animated.div>
                    );
                  })
                ) : (
                  <>
                    {
                    slidersTrail((style, item, key, index) => (
                      <animated.div
                        style={{ ...style }}
                        className="events"
                      >
                        <Events
                          type={item.type}
                          sponsor={sponsor}
                          iconTitleTypeEvent={getEventTypeIcon(item.type, eventTypes)}
                          titleTypeEvent={getEventTypeName(item.type, eventTypes)}
                          subTitleTypeEvent={item.subtitle}
                          numberShow={3}
                          modeCenter
                          filteredEvents={item.events}
                        />
                      </animated.div>
                    ))
                  }
                  </>
                )}

              {/* { (Object.values(filters).flat().length === 0 && filteredEvents?.length === 0) ? (
                <p>No se encontraron resultados</p>
              ) : '' } */}

            </div>
          )}

          {showingRestaurants && (
            <div className="container-event-type-view">

              {restaurantsTrail((style, item, key, index) => {
                const locationPromotions = sponsor ? item.locationPromotions?.filter((promo) => {
                  if (promo.sponsor_id === sponsor.id) {
                    return (
                      promo
                    );
                  }
                  return false;
                }) : (item.locationPromotions);

                // console.log(getSponsor(locationPromotions[0]?.sponsor_id, sponsors));

                return (
                  <animated.div
                    key={item.id}
                    style={index < 9 ? { ...style } : null}
                    className="event eventButton"
                  >
                    <Location
                      lazyLoad
                      key={item.id}
                      ImgEventDesktop={endpointCloudfront + item.desktopFile?.webp_thumbnail_url}
                      ImgEventMobile={endpointCloudfront + item.desktopFile?.webp_thumbnail_url}
                      iconEvent={RestaurantsIcon}
                      iconEventInfo="Icon Music"
                      typeEvent="Oferta Gastronómica"
                      idEvent={item.id}
                      link={item.link}
                      titleEvent={item.name}
                      locationEvent={item.address}
                      className="event eventButton"
                      cocardaBank={
                        sponsors
                          ? getSponsor(
                            locationPromotions[0]?.sponsor_id,
                            sponsors,
                          ).file?.webp_thumbnail_url
                          : ''
                      }
                    />
                  </animated.div>
                );
              })}
            </div>
          )}

          {!showingRestaurants && showingCarnaval && (
            <div className="container-event-type-view">
              {
                slidersTrailCarnaval((style, item, key, index) => (
                  <animated.div
                    style={{ ...style }}
                    className="events"
                  >
                    <Events
                      carnaval
                      type={item.type_id}
                      genre={item.id}
                      sponsor={sponsor}
                      iconTitleTypeEvent={getEventTypeIcon(item.type_id, eventTypes)}
                      titleTypeEvent={item.name}
                      subTitleTypeEvent={item.subtitle}
                      numberShow={3}
                      modeCenter
                      filteredEvents={item.events}
                    />
                  </animated.div>
                ))
              }

              {/* { (Object.values(filters).flat().length === 0 && filteredEvents?.length === 0) ? (
                <p>No se encontraron resultados</p>
              ) : '' } */}

            </div>
          )}

        </div>
        {sponsor ? (
          ''
        ) : (
          <div className="clients">
            <Clients />
          </div>
        )}
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  localities: state.app.localities,
  cities: state.app.cities,
  eventTypes: state.app.eventTypes,
  sponsors: state.app.sponsors,
  filters: state.app.filters,
  restaurants: state.app.restaurants,
  openFilters: state.app.openFilters,
  genres: state.app.genres,
});

export default connect(mapStateToProps, {
  setFiltersState: setFilters,
  setOpenFiltersState: setOpenFilters,
})(EventsView);
