/* eslint-disable linebreak-style */
/* eslint-disable max-len */
import { React, useEffect } from 'react';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import '../../style/Site/nosotros.css';
import {
  Logo, MusicBlack, UbicationBlack, ImgAboutUs, SocioUno, SocioDos, SocioTres, SocioCuatro, MusicaNosotros,
  CineNosotros,
  GastronomiaNosotros,
  OtrosEventosNosotros,
  TeatroNosotros,
  ImgNosotrosUno,
  ImgNosotrosDos,
} from '../../img';

function Nosotros() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className="nosotros">
      <Header />
      <div className="container-nosotros">
        <div className="container-one-nosotros">
          <div>
            <h3>
              <img src={Logo} alt="" />
              <br />
              La
              {' '}
              <span className="title-bold-green">plataforma</span>
              {' '}
              que integra en un mismo lugar
              <span className="title-bold-green"> toda la oferta artística</span>
              {' '}
              y para el disfrute de Uruguay.
            </h3>
            <p>En VOY está todo. Espectáculos, conciertos, cine, teatro, danza y toda la actividad cultural. Desde lo ya conocido hasta lo más emergente.</p>
          </div>
          <div className="desktopElement">
            <img src={ImgNosotrosUno} alt="" />
            <img src={ImgNosotrosDos} alt="" />
          </div>
        </div>
        <div className="typesNosotros">
          <img src={MusicaNosotros} alt="" />
          <img src={CineNosotros} alt="" />
          <img src={GastronomiaNosotros} alt="" />
          <img src={OtrosEventosNosotros} alt="" />
          <img src={TeatroNosotros} alt="" />
        </div>
        <section className="section-two">
          <div className="container-cards">
            <div className="sub-container-card" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <img src={MusicBlack} alt="" className="icon-section-two" />
              <h5>
                ¿
                <span className="title-bold">Sos</span>
                {' '}
                artista, música/o, productor/a, director/a de sala o escenario?
              </h5>
            </div>
            <div className="sub-container-card" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
              <img src={UbicationBlack} alt="" className="icon-section-two" />
              <h5>
                ¿
                <span className="title-bold">Tenés</span>
                {' '}
                un bar, boliche o restaurante y ofrecés propuestas artísticas?
              </h5>
            </div>
          </div>
          <div className="container-section-two-information">
            <h3 data-aos="fade-up" data-aos-duration="2500" className="title-bold">
              En VOY podés
              <span className="title-bold-green"> cargar tu evento </span>
              y hacerlo visible a miles de personas de manera muy sencilla y gratuita.
            </h3>
          </div>
        </section>

        <div className="container-section-three">
          <section className="section-three">
            <div className="aboutUs" data-aos="fade-up" data-aos-duration="2500">
              <div className="aboutUs-information">
                <h5>VOY es creado por un equipo de profesionales que ha cambiado la mirada de la gestión cultural y el negocio del turismo en Uruguay, expertos en desarrollo y gestión de plataformas digitales, marketing y comunicación. Este equipo sueña con posicionar a Uruguay como el hub cultural de Latinoamérica.</h5>
                <p>En pro de ello estamos poniendo al servicio de VOY toda la experiencia, pasión y conocimiento para hacerlo realidad.</p>
              </div>
              <div className="img-information desktopElement">
                <img src={ImgAboutUs} alt="" />
              </div>
            </div>

            <div className="socios">
              <h3 data-aos="fade-up" data-aos-duration="2000">
                Equipo voy
                <span className="title-bold green-point">.</span>
              </h3>
              <div className="socios-container" data-aos="fade-up" data-aos-duration="2500">
                <p>Andrés Topolanski, Agustín Calvo, Agustín Ferreyra, Bautista Mascia, Diego Abreu, Emilia Iturralde, Erika Hoffmann, Federico Torres, Felipe Zubirí, Gerardo Grieco, Ignacio Gutiérrez, Jean Paul Beer, Kevin Martínez, Lucía Franco, Mariana Fandiño, Mariel Miranda, Matías Burgos, Nicolás Muscio, Nicolás Santos, Natán Yudka, Pietro Calace, Sofía de Lisa, Sebastián del Campo, Santiago Borges, Valentina Cheker, Valeria Alvariño.</p>
              </div>
              <div className="socios-container" data-aos="fade-up" data-aos-duration="2500">
                <a href="https://moonideas.com/" target="_blank" rel="noreferrer">
                  <img src={SocioUno} alt="" />
                </a>
                <a href=" https://tinker.uy/" target="_blank" rel="noreferrer">
                  <img src={SocioDos} alt="" />
                </a>
                <a href="https://wannaclap.com/" target="_blank" rel="noreferrer">
                  <img src={SocioTres} alt="" />
                </a>
                <a href="https://5mtravelgroup.com/es/" target="_blank" rel="noreferrer">
                  <img src={SocioCuatro} alt="" />
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Nosotros;
