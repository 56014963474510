/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/destructuring-assignment */

import React, { useEffect } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setFilters } from '../../state/AppState';
import { Localities, Locations } from '../../img';
import { normalizeString } from '../../service';

const { Option } = Select;

function LocationsSelect(props) {
  const location = useLocation();
  const navigate = useNavigate();
  // const className = "locationSearch " + props.className;
  const [className, setClassName] = React.useState(
    `locationSearch ${props.className}`,
  );
  const [focused, setFocused] = React.useState(false);
  const [selectValue, setSelectValue] = React.useState(null);
  useEffect(() => {
    if (
      ((props.filters.location && props.filters.location.length > 0)
        || (props.filters.neighbourhood
          && props.filters.neighbourhood.length > 0))
      && !focused
    ) {
      setClassName(`locationSearch ${props.className} selected`);
    } else {
      setClassName(`locationSearch ${props.className}`);
    }

    if (props.filters.location && props.filters.location.length > 0) {
      setSelectValue(props.filters.location[0]);
    } else if (props.filters.neighbourhood && props.filters.neighbourhood.length > 0) {
      setSelectValue(
        props.localities.find((l) => l.id === props.filters.neighbourhood[0])
          ?.name,
      );
    } else {
      setSelectValue(null);
    }
  }, [props.filters.location, focused]);

  return (
    <Select
      showSearch
      optionFilterProp="location"
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={(value) => {
        const newFilters = { ...props.filters };
        if (isNaN(value)) {
          newFilters.location = [];
          newFilters.distance = [];
          newFilters.city = [];
          const id = props.localities.find((l) => l.name === value)?.id;
          newFilters.neighbourhood = [id];
        } else {
          newFilters.neighbourhood = [];
          newFilters.distance = [];
          newFilters.city = [];
          if (value === 0) {
            newFilters.location = [];
          } else {
            newFilters.location = [value];
          }
        }
        props.setFiltersState(newFilters);
        document.activeElement.blur();
        const view = location.pathname.split('/')[1];
        if (view !== 'ver-eventos' && view !== 'view-map') {
          navigate('/ver-eventos');
        }
      }}
      value={selectValue}
      onSearch={() => {}}
      filterOption={(input, option) => normalizeString(option?.location)
        .includes(normalizeString(input))}
      name={props.name}
      placeholder={props.placeholder}
      className={className}
      onInputKeyDown={(e) => {
        if (e.key === 'Backspace' && e.target.value === '') {
          const newFilters = { ...props.filters };
          newFilters.location = [];
          newFilters.neighbourhood = [];
          props.setFiltersState(newFilters);
          // const view = location.pathname.split('/')[1];
          // if (view !== 'ver-eventos' && view !== 'view-map') {
          //   navigate('/ver-eventos');
          // }
        }
      }}
    >
      <Option key={0} value={0} location="Todos" className="optionsLocations">
        Todos
      </Option>

      {props.locations?.map((location) => (
        <Option
          className="optionsLocations locations"
          key={location.location.id}
          value={location.location.id}
          location={location.location.name}
        >
          {location.location.name}
          <img src={Locations} alt="locations" />
        </Option>
      ))}
      {props.localities?.map((locality) => (
        <Option
          key={locality.name}
          value={locality.name}
          location={locality.name}
          className="optionsLocations localities"
        >
          {locality.name}
          <img src={Localities} alt="localities" />
        </Option>
      ))}
    </Select>
  );
}

const mapStateToProps = (state) => ({
  filters: state.app.filters,
});

export default connect(mapStateToProps, {
  setFiltersState: setFilters,
})(LocationsSelect);
