/* eslint-disable linebreak-style */
import { React, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import '../../style/Site/mapView.css';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import Clients from '../Advertisments/Clients';
import { setFilters } from '../../state/AppState';
import Map from '../Map/Map';
import { applyTypeFilter, getLocationsForMarkers, getFilteredEvents } from '../../service';
import { ArrowMap } from '../../img';

import LocationsSelect from '../Filters/LocationsSelect';

function MapView(props) {
  const [events, setFilteredEvents] = useState([]);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });

    const filtrar = searchParams.get('filtrar');

    if (filtrar !== null) {
      if (filtrar === 'carnaval') {
        applyTypeFilter(5, props.setFiltersState);
      }
    }
  }, [props.locations]);

  useEffect(() => {
    const asyncGet = async () => {
      const filteredEvents = await getFilteredEvents(
        props.filters,
        props.events,
        props.locations,
        props.localities,
        '',
        false,
        true,
      );
      setFilteredEvents(filteredEvents);
    };
    asyncGet();

    if (props.filters.type.length === 1) {
      if (props.filters.type.includes(5) && props.filters.genre.length !== 1) {
        setSearchParams({ filtrar: 'carnaval' });
      }
    } else {
      setSearchParams(false);
    }
  }, [props.filters]);

  const locations = getLocationsForMarkers(events, props.locations);
  // if mobile dont show footer or clients
  return (
    <div className="container-map">
      <Header />
      <div className="map">
        <div className="container-search-arrow">
          <button type="button" onClick={() => navigate(-1)}>
            <img src={ArrowMap} alt="" />
          </button>
          <LocationsSelect
            locations={locations}
            localities={props.localities}
            className="search-map"
            name="busquedamusica"
            placeholder="Buscá un lugar..."
          />
        </div>
        <Map
          centerOnMe
          currentLocations={locations}
        />
      </div>
      {!isMobile && <Clients />}
      {!isMobile && <Footer />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  locations: state.app.locations,
  localities: state.app.localities,
  filters: state.app.filters,
  events: state.app.events,
});

export default connect(mapStateToProps, { setFiltersState: setFilters })(MapView);
