/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import '../../../style/Login/login.css';
import { connect } from 'react-redux';
import { FacebookIcon, GoogleIcon } from '../../../img';
import ButtonSelected from '../../Buttons/ButtonSelected';

function Soy({ handleContinue }) {
  const [soyArtista, setSoyArtista] = useState(false);
  const [hagoLoQuePuedo, sethagoLoQuePuedo] = useState(false);
  const [soyDueño, setSoyDueño] = useState(false);
  return (
    <div className="soy">
      <ButtonSelected idButton="loginIsFan" nameButton="Soy fan" />
      <button
        type="button"
        onClick={() => {
          setSoyArtista(!soyArtista);
        }}
        id="loginIsArtist"
        className={`soyArtista ${soyArtista ? 'openInputSoyArtista' : ''}`}
      >
        Soy artista profesional
      </button>
      <input type="text" className={soyArtista ? 'show' : ''} id="loginBandName" placeholder="Nombre de la banda / solista / agrupación / equipo" />
      <button
        type="button"
        onClick={() => {
          sethagoLoQuePuedo(!hagoLoQuePuedo);
        }}
        id="loginWhatICan"
        className={`hagoLoQuePuedo ${hagoLoQuePuedo ? 'openInputHagoLoQuePuedo' : ''}`}
      >
        Hago lo que puedo
      </button>
      <input type="text" className={hagoLoQuePuedo ? 'show' : ''} id="loginBandName" placeholder="Nombre de la banda / solista / agrupación / equipo" />
      <button
        type="button"
        onClick={() => {
          setSoyDueño(!soyDueño);
        }}
        id="loginHasVenue"
        className={`soyDueño ${soyDueño ? 'openInputSoyDueño' : ''}`}
      >
        Soy dueño de una sala / bar / resto / venue
      </button>
      <input type="text" id="loginVenueName" placeholder="Soy dueño de una sala / bar / resto / venue" />
      <button onClick={handleContinue} type="button" className="continueLoginButton">
        Continuar
      </button>
    </div>
  );
}

export default Soy;
