/* eslint-disable linebreak-style */

const domain = 'https://voy.com.uy';
const endpointApi = 'https://api.voy.com.uy';
const endpointFrontData = `${endpointApi}/events/get-front-data`;
const endpointSearchData = `${endpointApi}/events/get-front-search-data`;
const endpointCloudfront = 'https://d1b58frn0ydubf.cloudfront.net/';
const endpointEventData = `${endpointApi}/events/get-event-data-front`;
const endpointLocationData = `${endpointApi}/events/get-location-data-front`;
const endpointBandData = `${endpointApi}/events/get-band-data-front`;
const endpointLogin1 = `${endpointApi}/users/popup-register`;
const endpointLogin2 = `${endpointApi}/users/popup-expand-info`;
const endpointLogin3 = `${endpointApi}/users/popup-set-user-type`;
const endpointLogin4 = `${endpointApi}/users/popup-set-interests`;
const endpointContactForm = `${endpointApi}/contacts/add`;
const endpointGiveaway = `${endpointApi}/giveaway-users/front-register`;
const endpointGiveaways = `${endpointApi}/giveaway-users/front-register-multiple`;
const endpointSurveyAnswer = `${endpointApi}/surveys/add-answer`;
const endpointSurveyEmail = `${endpointApi}/surveys/add-email`;
const endpointEventClicks = `${endpointApi}/event-clicks/add`;

export {
  domain,
  endpointApi,
  endpointFrontData,
  endpointSearchData,
  endpointCloudfront,
  endpointEventData,
  endpointLocationData,
  endpointBandData,
  endpointLogin1,
  endpointLogin2,
  endpointLogin3,
  endpointLogin4,
  endpointContactForm,
  endpointGiveaway,
  endpointGiveaways,
  endpointSurveyAnswer,
  endpointSurveyEmail,
  endpointEventClicks,
};
