/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import '../../style/Site/giveaway.css';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { getIdFromLink, getGiveaway } from '../../service';
import Clients from '../Advertisments/Clients';
import { endpointCloudfront, endpointGiveaway } from '../../endpoints';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import { setLoading } from '../../state/AppState';
import Message from '../Popups/Message';
import { VosVasYoVoy } from '../../img';
import 'react-datepicker/dist/react-datepicker.css';

function Giveaway({ setLoadingState, giveaways }) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  const [giveaway, setGiveaway] = useState(false);

  const params = useParams();
  const { link } = params;

  useEffect(() => {
    const linkId = getIdFromLink(link);
    setGiveaway(getGiveaway(linkId, giveaways));
  }, [link, giveaways]);

  const [response, setResponse] = useState([]);

  const [birthdate, setBirthdate] = useState(false);

  async function onSubmitHandler(data) {
    const date = new Date(birthdate).toISOString().substring(0, 10);
    const contactData = {
      contact: {
        ...data,
        birthdate: date,
        giveaway_id: getIdFromLink(link),
      },
    };
    try {
      // setLoadingState(true);
      await axios.post(endpointGiveaway, contactData).then((res) => {
        setLoadingState(false);
        setResponse(res.data);
      }, (err) => err);
    } catch (error) {
      setLoadingState(false);
      return error;
    }
  }

  return (
    <div className="contact">
      <Header />
      <div className="container-form">
        <img className="giveawayImage desktopElement" src={endpointCloudfront + giveaway?.desktopFile?.webp_url} alt="sorteo" />
        <img className="giveawayImage mobileElement" src={endpointCloudfront + giveaway?.mobileFile?.webp_url} alt="sorteo" />
        <h1>Registrate para participar,</h1>
        <h2>todo está en voy.</h2>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="sub-container-inputs">
            <div className="container-inputs-draw">
              <div>
                <input {...register('first_name', { required: '*El campo de nombre es obligatorio' })} placeholder="Nombre" />
                <span>{errors.first_name?.message}</span>
              </div>
              <div>
                <input {...register('last_name', { required: '*El campo de apellido es obligatorio' })} placeholder="Apellido" />
                <span>{errors.last_name?.message}</span>
              </div>
              <div>
                <input {...register('phone', { required: '*El campo de celular es obligatorio' })} placeholder="Celular" />
                <span>{errors.phone?.message}</span>
              </div>
              <div>
                <input {...register('email', { required: '*El campo de email es obligatorio' })} type="email" placeholder="Email" />
                <span>{errors.email?.message}</span>
              </div>
              <div>
                <DatePicker
                  format="yyyy-MM-dd"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Fecha de Nacimiento"
                  selected={birthdate}
                  onChange={(date) => setBirthdate(date)}
                />
                <span>{errors.birthdate?.message}</span>
              </div>
              <div>
                <input {...register('document', { required: '*El campo de cédula es obligatorio' })} placeholder="Cédula" />
                <span>{errors.document?.message}</span>
              </div>
            </div>
          </div>
          <Message value="Registrarme" response={response} />
        </form>
        <img className="vosvasyovoy" src={VosVasYoVoy} alt="vosvasyovoy" />
      </div>
      <div className="clients">
        <Clients />
      </div>
      <Footer />
    </div>
  );
}
const mapStateToProps = (state) => ({
  loginStage: state.app.loginStage,
  giveaways: state.app.giveaways,
});
export default connect(mapStateToProps, {
  setLoadingState: setLoading,
})(Giveaway);
