/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../Layouts/Header';
import SliderHome from '../Slider/SliderHome';
import { setFilters } from '../../state/AppState';
import { endpointCloudfront } from '../../endpoints';
import Events from '../Events/Events';
import Banner from '../Advertisments/Banner';
import SearchHeader from '../Popups/SearchHeader';
import Footer from '../Layouts/Footer';
import Clients from '../Advertisments/Clients';
import BannersHomeTop from '../Slider/BannersHomeTop';
import BannersHomeBottom from '../Slider/BannersHomeBottom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../index.css';
import {
  getEvent,
  getLocation,
  getDate,
  getEventTypeName,
  getEventTypeIcon,
  getFilteredEvents,
  getFilteredLocations,
  filtersWipe,
  getRestaurantsWithoutBranches,
} from '../../service';
import {
  RestaurantsIcon,
  ArrowSliderRight,
  ArrowSliderLeft,
  BannerCarnaval,
  BannerCarnavalMobile,
} from '../../img';

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="sliderHomeRight" type="button">
      <img src={ArrowSliderRight} alt="" />
    </button>
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="sliderHomeLeft" type="button">
      <img src={ArrowSliderLeft} alt="" />
    </button>
  );
}

function Index({
  events,
  locations,
  localities,
  highlights,
  restaurants,
  sponsors,
  eventTypes,
  setFiltersState,
}) {
  const [smallerHighlights, setSmallerHighlights] = useState(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
    filtersWipe(setFiltersState);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    slidesToShow: 1,
    centerPadding: '60px',
    centerMode: true,
    lazyLoad: 'progressive',
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    initialSlide: smallerHighlights ? -0.25 : 0,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          dots: false,
          arrows: false,
          infinite: true,
          speed: 1000,
          centerPadding: smallerHighlights ? '7.5px' : '30px',
          slidesToShow: smallerHighlights ? 1.25 : 1,
          lazyLoad: smallerHighlights ? undefined : 'progressive',
          autoplay: true,
          autoplaySpeed: 6000,
          slidesToScroll: smallerHighlights ? 0.25 : 1,
        },
      },
    ],
  };

  const [filteredEventsMusic, setFilteredEventsMusic] = useState([]);
  const [filteredEventsCinema, setFilteredEventsCinema] = useState([]);
  const [filteredEventsTheatre, setFilteredEventsTheatre] = useState([]);
  const [filteredEventsOtherEvents, setFilteredEventsOtherEvents] = useState([]);
  const [filteredEventsCarnaval, setFilteredEventsCarnaval] = useState([]);
  const [filteredEventsFiestas, setFilteredEventsFiestas] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [sponsorsForRestaurants, setSponsorsForRestaurants] = useState([]);

  function asyncGetEvents(filters) {
    const result = getFilteredEvents(
      filters,
      events,
      locations,
      localities,
    );
    return result;
  }

  const sliders = [
    { type: 1, events: filteredEventsMusic, subtitle: 'Este mes' },
    { type: 3, events: filteredEventsTheatre, subtitle: 'Próximas funciones' },
    { type: 2, events: filteredEventsCinema, subtitle: 'Últimos estrenos' },
    { type: 5, events: filteredEventsCarnaval, subtitle: 'Próximos espectáculos' },
    { type: 6, events: filteredEventsFiestas, subtitle: 'Este mes' },
    { type: 4, events: filteredEventsOtherEvents, subtitle: '' },
  ];

  useEffect(() => {
    if (events) {
      const asyncGet = async () => {
        const filteredEventsMusic = await asyncGetEvents({
          date: ['Este mes'],
          type: [1],
          quantity: 20,
        });
        setFilteredEventsMusic(filteredEventsMusic);
        const filteredEventsCinema = await asyncGetEvents({
          date: ['Este mes'],
          type: [2],
          quantity: 20,
        });
        setFilteredEventsCinema(filteredEventsCinema);
        const filteredEventsTheatre = await asyncGetEvents({
          date: ['Este mes'],
          type: [3],
          quantity: 20,
        });
        setFilteredEventsTheatre(filteredEventsTheatre);
        const filteredEventsOtherEvents = await asyncGetEvents({
          date: ['Este mes'],
          type: [4],
          quantity: 20,
        });
        setFilteredEventsOtherEvents(filteredEventsOtherEvents);
        const filteredEventsCarnaval = await asyncGetEvents({
          date: ['Este mes'],
          type: [5],
          quantity: 20,
        });
        setFilteredEventsCarnaval(filteredEventsCarnaval);
        const filteredEventsFiestas = await asyncGetEvents({
          date: ['Este mes'],
          type: [6],
          quantity: 20,
        });
        setFilteredEventsFiestas(filteredEventsFiestas);
      };
      asyncGet();
    }
  }, [events]);

  useEffect(() => {
    const asyncGet = async () => {
      const filteredRestaurantsAux = await getFilteredLocations(
        {
          sponsor: sponsorsForRestaurants,
        },
        getRestaurantsWithoutBranches(restaurants),
        localities,
      );
      setFilteredRestaurants(filteredRestaurantsAux);
    };
    asyncGet();
  }, [sponsors, restaurants, sponsorsForRestaurants]);

  return (
    <div className="Index">
      <div className="background-img-home">
        <Header />
        <SearchHeader className="mobileElement" eventTypes={eventTypes} />
        <div className="title-mobile-index mobileElement">
          <h3>
            ¡Encontrá todo lo que te gusta!
          </h3>
        </div>
        <Slider {...settings}>
          {highlights?.map((highlight) => {
            let event = null;
            let eventFunction = null;
            let location = null;
            if (highlight?.event_id && highlight?.event_id !== '0') {
              event = getEvent(highlight.event_id, events);
              if (event) {
                eventFunction = event?.eventFunctions[0];
                location = getLocation(eventFunction?.location_id, locations);
              }
            }
            return (
              <SliderHome
                key={`highlight${event?.id}`}
                bandImgDesktop={
                  endpointCloudfront + highlight.desktopFile?.webp_url
                }
                bandImgMobile={
                  endpointCloudfront + highlight.mobileFile?.webp_url
                }
                iconTitleTypeEvent={getEventTypeIcon(event?.event_type_id || 4, eventTypes)}
                theme={getEventTypeName(event?.event_type_id || 4, eventTypes)}
                title={highlight.name}
                information={eventFunction && location ? (`${location?.name} | ${getDate(
                  eventFunction.date,
                )}`) : ''}
                titleButton="QUIERO IR"
                eventId={event?.id}
                linkOut={!event}
                link={event?.link || highlight.link}
                eventPromotions={
                  sponsors
                    ? highlight.eventPromotions
                    : ''
                }
              />
            );
          })}
        </Slider>
      </div>

      <BannersHomeTop />

      {/* <div className="secondaryBanners">
        <div className="bannerIntendencia desktopElement">
          <Link to="/ver-eventos?filtrar=carnaval">
            <Banner banner={BannerCarnaval} />
          </Link>
        </div>
        <div className="bannerIntendencia mobileElement">
          <Link to="/ver-eventos?filtrar=carnaval">
            <Banner banner={BannerCarnavalMobile} />
          </Link>
        </div>
      </div> */}

      {/* Sliders de Tipos de Eventos */}

      <main className="main">
        {sliders.map((item) => {
          if (item?.events?.length >= 4) {
            return (
              <Events
                key={`eventType${item.type}`}
                type={item.type}
                iconTitleTypeEvent={getEventTypeIcon(item.type, eventTypes)}
                titleTypeEvent={getEventTypeName(item.type, eventTypes)}
                subTitleTypeEvent={item.subtitle}
                numberShow={4}
                numberScroll={4}
                modeCenter
                filteredEvents={item.events}
              />
            );
          }
        })}

        {/* Slider de Restaurantes */}

        <div className="events restaurantsSlider">
          <Events
            locationsSlider="1"
            multipleSponsors="1"
            iconTitleTypeEvent={RestaurantsIcon}
            titleTypeEvent="Oferta Gastronómica"
            numberShow={4}
            modeCenter
            filteredEvents={filteredRestaurants}
            sponsorsForRestaurants={sponsorsForRestaurants}
            setSponsorsForRestaurants={setSponsorsForRestaurants}
          />
        </div>

        {/* Sliders de Sponsors */}

        {sponsors?.map((sponsor) => {
          if (sponsor.has_slide === '1') {
            if (events) {
              let sponsorEvents = JSON.parse(JSON.stringify(events));
              sponsorEvents = sponsorEvents.filter((event) => {
                let found = false;
                event.eventPromotions.forEach((eventPromotion) => {
                  if (eventPromotion.sponsor_id === sponsor.id) {
                    found = true;
                  }
                });
                return found;
              });
              if (sponsorEvents.length >= 4) {
                return (
                  <Events
                    key={`sponsor${sponsor.id}`}
                    iconTitleTypeEvent={getEventTypeIcon(1, eventTypes)}
                    titleTypeEvent={sponsor.name}
                    subTitleTypeEvent="Recomendados"
                    numberShow={4}
                    modeCenter
                    filteredEvents={sponsorEvents}
                    linkToLanding={sponsor.id}
                  />
                );
              }
            }
          }
        })}

        <BannersHomeBottom />

        <div className="clients">
          <Clients />
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  localities: state.app.localities,
  highlights: state.app.highlights,
  restaurants: state.app.restaurants,
  sponsors: state.app.sponsors,
  eventTypes: state.app.eventTypes,
});
export default connect(mapStateToProps, {
  setFiltersState: setFilters,
})(Index);
