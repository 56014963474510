/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import '../../../style/Login/login.css';
import { connect } from 'react-redux';
import {
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { setOpenLogin } from '../../../state/AppState';
import {
  WpCompartirLogin,
  CopiarLogin,
} from '../../../img';
import ButtonSelected from '../../Buttons/ButtonSelected';

function Welcome({ setOpenLoginState, referralLink }) {
  const shareUrl = { referralLink };
  const [copy, setCopy] = useState(false);
  return (
    <div className="welcome">
      <div className="welcomeContainer">
        <div className="urlLogin">
          <Link to="/">{referralLink}</Link>
          <div>
            <div>
              <WhatsappShareButton url={referralLink}>
                <img src={WpCompartirLogin} alt="" />
                <span>Compartir</span>
              </WhatsappShareButton>
            </div>
            <div>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(referralLink); setCopy(!copy);
                }}
                className={
                  copy === true ? 'copy' : 'noCopy'
        }
                type="button"
              >
                <img src={CopiarLogin} alt="" />
                <span>Copiar</span>
                <p>Link Copiado</p>
              </button>
            </div>
          </div>
        </div>
        <div className="buttonsWelcome">
          <button
            onClick={() => {
              setOpenLoginState(false);
            }}
            type="button"
            className="continueLoginButton"
          >
            Volver a web de eventos
          </button>
          {/* <button type="button" className="continueLoginButton">
            Ir a mi panel de administración
          </button> */}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  referralLink: state.app.referralLink,
});
export default connect(mapStateToProps, {
  setOpenLoginState: setOpenLogin,
})(Welcome);
