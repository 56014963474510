/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable import/extensions */

import React from 'react';
import '../../style/Map/location.css';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { endpointCloudfront } from '../../endpoints';
import GoTo from '../../img/go-to.svg';
import { getDate, getTime } from '../../service.js';

import {
  MovieIcon,
  MusicIcon,
  TheatreIcon,
  CarnavalIcon,
  FiestasIcon,
  ArrowSliderLeft,
  CloseToggle,
} from '../../img';

function Location({ location, close }) {
  const firstThreeEvents = location.events.slice(0, 3);

  const [openMore, setOpenMore] = React.useState(false);

  return (
    <motion.div
      initial={{ y: 1000 }}
      animate={{ y: 0 }}
      exit={{ y: 1000 }}
      transition={{ type: 'spring', duration: 0.1 }}
      className="location-info-container"
    >
      <div className="location-info">
        <div className="close-location-info" onClick={close}>
          <img src={CloseToggle} alt="close" />
        </div>

        {/* <div className="">
          <img
            src={endpointCloudfront + location.location.desktopFile?.webp_thumbnail_url}
            alt="event-img"
          />
        </div> */}

        <div className="location-name">{location.location.name}</div>

        {location.location.description ? (
          <>
            <div className="location-description desktopElement">
              {openMore
                ? location.location.description
                : `${location.location.description?.substring(0, 285)}...`}
            </div>
            <div
              className="show-more  desktopElement"
              onClick={() => {
                setOpenMore(!openMore);
              }}
            >
              {openMore ? 'Mostrar menos' : 'Mostrar más'}
            </div>
          </>
        ) : ''}

        <div className="next-events-title  desktopElement">
          Próximos eventos
        </div>

        <div className="next-events">
          {firstThreeEvents.map((e) => (
            <Link to={`/evento/${e.event.link}`}>
              <div className="event-info">
                <div className="event-info-img">
                  <img
                    src={endpointCloudfront + e.event.desktopFile?.webp_thumbnail_url}
                    alt="event-img"
                  />
                </div>

                <div className="event-info-info">
                  <div className="event-info-name">{e.event.name}</div>

                  <div className="event-info-date">
                    {getDate(e.eventFunction.date)}
                    {' '}
                    |
                    {' '}
                    {getTime(e.eventFunction.start_time)}
                  </div>
                </div>

                <img className="go-to" src={GoTo} alt="" />
              </div>
            </Link>
          ))}
        </div>

        <Link to="/ver-eventos">
          <div className="view-all-events">
            Ver todos (
            {location.events.length}
            )
          </div>
        </Link>
      </div>
    </motion.div>
  );
}

export default Location;
