/* eslint-disable linebreak-style */
/* eslint-disable react/button-has-type */

import React, { useState } from 'react';
import Div100vh from 'react-div-100vh';
import { connect } from 'react-redux';
import '../../style/Popups/toggleMenu.css';
import { Link } from 'react-router-dom';
import { endpointCloudfront } from '../../endpoints';
import {
  Cruz,
  Gift,
  CloseToggle,
  CloseToggleMenu,
} from '../../img';

function ToggleButton({ sponsors }) {
  const [open, setOpen] = useState(false);

  const closeAll = () => {
    setOpen(false);
  };

  return (
    <div>
      <nav className="giftMenu">
        <button
          onClick={() => {
            closeAll();
            setOpen(!open);
          }}
          className={open === true ? 'navbar-open navbar_icon' : 'navbar-icon'}
        >
          <div className="promotion">
            <img className="gift" src={Gift} alt="Gift" />
          </div>
        </button>
      </nav>

      <div className={open === true ? 'navbar_link open' : 'navbar_link close-up'}>
        <button
          onClick={() => {
            closeAll();
            setOpen(!open);
          }}
          className={open === true ? 'desktopElement buttonCloseArrow close-up' : 'desktopElement buttonCloseArrow close'}
        >
          <img src={CloseToggleMenu} alt="" />
        </button>
        <div className="containerPromotions desktopElement">
          <button
            onClick={() => {
              closeAll();
              setOpen(!open);
            }}
            className={open === true ? 'mobileElement close-up' : 'mobileElement close'}
          >
            <img src={Cruz} alt="Icon Close Toggle" />
          </button>

          <span className="logoPromotions">
            Beneficios
            <p>Clickeá para ver los eventos con beneficios</p>
          </span>

          <div className="div-banks-logo">
            {sponsors?.map((sponsor) => {
              if (sponsor?.has_landing === '1') {
                return (
                  <Link
                    key={`sponsor${sponsor.id}`}
                    onClick={() => setOpen(false)}
                    to={`/ver-eventos/${sponsor.id}`}
                    className="sponsors"
                  >
                    <img src={endpointCloudfront + sponsor.file?.webp_thumbnail_url} alt="Itau Logo" />
                    <p>Ver todos</p>
                  </Link>
                );
              }
            })}
          </div>
        </div>
        <Div100vh className="containerPromotionsMobile mobileElement">
          <button
            onClick={() => {
              closeAll();
              setOpen(!open);
            }}
            className={open === true ? 'mobileElement close-up' : 'mobileElement close'}
          >
            <img src={Cruz} alt="Icon Close Toggle" />
          </button>

          <span className="logoPromotions">
            Beneficios
            <p>Clickeá para ver los eventos con beneficios</p>
          </span>

          <div className="div-banks-logo">
            {sponsors?.map((sponsor) => {
              if (sponsor?.has_landing === '1') {
                return (
                  <Link
                    key={`sponsor${sponsor.id}`}
                    onClick={() => setOpen(false)}
                    to={`/ver-eventos/${sponsor.id}`}
                  >
                    <img src={endpointCloudfront + sponsor.file?.webp_thumbnail_url} alt="Itau Logo" />
                    <p>Ver todos</p>
                  </Link>
                );
              }
            })}
          </div>
        </Div100vh>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sponsors: state.app.sponsors,
});

export default connect(mapStateToProps, null)(
  ToggleButton,
);
