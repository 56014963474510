/* eslint-disable linebreak-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { React, useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setFilters } from '../../state/AppState';
import '../../style/Filters/filterSwitch.css';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
// const [openSwitch, setOpenSwitch] = useState(false);

function FilterSwitch(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [openButton, setOpenButton] = useState(false);

  const checkState = () => {
    switch (props.category) {
      case 'price':
        if (props.filters.price?.includes(props.value)) {
          setOpenButton(true);
        }
        break;
      case 'sponsor':
        if (props.filters.sponsor?.includes(props.value)) {
          setOpenButton(true);
        }
        break;
      default:
        return null;
    }
    return null;
  };

  useEffect(() => {
    checkState();
  }, []);

  const applyFilters = () => {
    const newFilters = { ...props.filters };
    if (openButton) {
      let index = '';
      switch (props.category) {
        case 'price':
          index = newFilters.price?.indexOf(props.value);
          newFilters.price?.splice(index, 1);
          break;
        case 'sponsor':
          index = newFilters.sponsor?.indexOf(props.value);
          newFilters.sponsor?.splice(index, 1);
          break;
        default:
          return null;
      }
    } else {
      switch (props.category) {
        case 'price':
          newFilters.price?.push(props.value);
          break;
        case 'sponsor':
          newFilters.sponsor?.push(props.value);
          break;
        default:
          return null;
      }
    }
    props.setFiltersState(newFilters);
    if (!openButton) {
      const view = location.pathname.split('/')[1];
      if (view !== 'ver-eventos' && view !== 'view-map') {
        navigate('/ver-eventos');
      }
    }
  };

  return (
    <div className="checkboxFrom">
      <Switch
        onClick={() => {
          setOpenButton(!openButton);
          applyFilters();
        }}
        checked={openButton === true}
        sx={{
          '& .MuiSwitch-thumb': {
            background: '#FFFFFF',
            height: '12px',
            width: '12px',
            margin: '4px 4px',
          },
          '& .MuiSwitch-root': {
            height: '39px',
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#47FFBD',
          },
          '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            background: '#47FFBD',
          },
          '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
            background: '#47FFBD',
          },
          '& .MuiTouchRipple-root.Mui-cheked': {
            color: '#47FFBD',
          },
        }}
      />
      <span className="titleCheckbox">{props.titleCheckbox}</span>
    </div>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  eventTypes: state.app.eventTypes,
  filters: state.app.filters,
});

export default connect(mapStateToProps, {
  setFiltersState: setFilters,
})(
  FilterSwitch,
);
