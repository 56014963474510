/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  React, useState, useRef, useEffect,
} from 'react';
import '../../style/Events/event.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { endpointCloudfront } from '../../endpoints';
import { Hour, Pin, ArrowVoyButton } from '../../img';
import Star from '../Buttons/Star';

function Location(props) {
  const [height, setHeight] = useState(0);
  const ref = useRef < HTMLDivElement > null;

  useEffect(() => {
    if (ref.current) {
      setHeight((ref.current.clientWidth * 16) / 9);
    }
  }, [ref]);

  return (
    <Link
      to={`/locacion/${props.link}`}
      className={props.className ? props.className : ''}
    >
      <div className="event">
        <div className="imgEvent">
          <Star typeEvent={props.typeEvent} />
          {props.cocardaBank ? (
            <img
              src={endpointCloudfront + props.cocardaBank}
              alt={props.infoCocardaBank}
              className="cocardaBank"
            />
          ) : (
            ''
          )}
          {props.lazyLoad ? (
            <>
              <LazyLoadImage
                src={props.ImgEventDesktop}
                alt="Imagen evento"
                className="imagenEvent desktopElement"
                threshold={300}
              />
              <LazyLoadImage
                src={props.ImgEventDesktop}
                alt="Imagen evento"
                className="imagenEvent mobileElement"
                threshold={300}
              />
            </>
          ) : (
            <>
              <img
                src={props.ImgEventDesktop}
                alt="Imagen evento"
                className="imagenEvent desktopElement"
              />
              <img
                src={props.ImgEventDesktop}
                alt="Imagen evento"
                className="imagenEvent mobileElement"
              />
            </>
          )}
        </div>
        <div className="infoEvent">
          <span className={`typeEvent ${props.typeEvent}`}>
            <img
              src={props.iconEvent}
              alt={props.iconEventInfo}
              className="iconEvent"
            />
            <p>
              {props.typeEvent}
            </p>
          </span>
          <div className="containerTitleEvent">
            <h3 className="titleEvent">{props.titleEvent}</h3>
          </div>
          <span className="locationEvent">
            <img src={Pin} alt="location" />
            <div>{props.locationEvent}</div>
          </span>
          <Link
            to={`/locacion/${props.link}`}
            className={`voyEventButton ${props.typeEvent}`}
          >
            <span> voy</span>
            <img src={ArrowVoyButton} alt="arow" />
          </Link>
        </div>
      </div>
    </Link>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  eventTypes: state.app.eventTypes,
});
export default connect(mapStateToProps, null)(Location);
