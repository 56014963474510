/* eslint-disable linebreak-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { React, useState } from 'react';
import { ArrowFilters } from '../../img';
import '../../style/Question/question.css';

function Question(props) {
  const [openQuestion, setOpenQuestion] = useState(false);
  return (
    <div className="question">
      <button
        type="button"
        onClick={() => {
          setOpenQuestion(!openQuestion);
        }}
        className={
              openQuestion === true ? 'buttonQuestionOpen' : 'buttonQuestionNoOpen'
            }
      >
        <div className="header-question">
          <div>
            <span className="titleQuestion">{props.titleQuestion}</span>
          </div>
          <button type="button">
            <img src={ArrowFilters} alt="" />
          </button>
        </div>
        <div className="body-question">
          <p>{props.textQuestion}</p>
        </div>
      </button>
    </div>
  );
}

export default Question;
