/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import '../../style/Popups/searchHeader.css';
import { Link, useLocation } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';
import {
  ArrowMap, Ubication, NearLocation, Cruz,
} from '../../img';

import Calendar from './Calendar';
import ButtonSelected from '../Buttons/ButtonSelected';
import {
  normalizeString,
  getLocation,
  getShortDate,
  getEventTypeName,
  getLocationsForMarkers,
  getFilteredEvents,
} from '../../service';

import LocationsSelect from '../Filters/LocationsSelect';

function searchHeader(props) {
  const [openWhere, setOpenWhere] = useState(false);
  const [openWhen, setOpenWhen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [whenText, setWhenText] = useState([]);
  const [whatText, setWhatText] = useState([]);
  const [whereText, setWhereText] = useState([]);

  const settings = {
    infinite: true,
    autoplay: false,
    arrows: false,
    dots: false,
    slidesToShow: 2.65,
    initialSlide: -0.70,
    slidesToScroll: 1,
  };

  useEffect(() => {
    setWhenText(
      props.filters.date?.length > 0
        ? props.filters.date.includes('range')
          ? `${getShortDate(
            props.filters.date[1][0].substring(0, 10),
          )} - ${getShortDate(props.filters.date[1][1].substring(0, 10))}`
          : props.filters.date.map((date, n) => {
            if (
              props.filters.date.length > 1
              && props.filters.date.length - 1 > n
            ) {
              return `${date}, `;
            }
            return date;
          })
        : null,
    );

    setWhatText(
      props.filters.type?.length > 0
        ? props.filters.type.map((type, n) => {
          if (
            props.filters.type.length > 1
            && props.filters.type.length - 1 > n
          ) {
            return `${getEventTypeName(type, props.eventTypes)}, `;
          }
          return getEventTypeName(type, props.eventTypes);
        })
        : null,
    );

    setWhereText(() => {
      if (props.filters.location?.length > 0) {
        const location = getLocation(
          props.filters.location[0],
          props.locations,
        );
        return location?.name;
      }

      if (props.filters.neighbourhood?.length > 0) {
        const neighbourhood = props.localities.find(
          (l) => l.id === props.filters.neighbourhood[0],
        );
        return neighbourhood?.name;
      }

      if (props.filters.distance?.length > 0) {
        return `Menos de ${props.filters.distance[0]}km`;
      }

      return null;
    });
  }, [props.filters]);

  const closeAll = () => {
    setOpenWhen(false);
    setOpenType(false);
    setOpenWhere(false);
  };

  const [events, setFilteredEvents] = useState([]);
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    const asyncGet = async () => {
      const filteredEvents = await getFilteredEvents(
        props.filters,
        props.events,
        props.locations,
        props.localities,
        '',
        false,
        true,
      );
      setFilteredEvents(filteredEvents);
    };
    asyncGet();
  }, [props.filters, props.locations]);

  useEffect(() => {
    setLocations(getLocationsForMarkers(events, props.locations));
  }, [events]);

  const location = useLocation();

  useEffect(() => {
    /*if (location.pathname === '/' && !isMobile) {
      setOpenWhere(!isMobile);
    }*/
  }, []);

  return (
    // eslint-disable-next-line react/self-closing-comp
    <div className={`container-searchHeader ${props.className}`}>
      <div className="sub-container-searchHeader">
        <div className="searchHeader">
          <button
            onClick={() => {
              closeAll();
              setOpenWhere(!openWhere);
            }}
            id="boton-donde"
            className={
              openWhere === true
                ? 'searchButtonWhere searchButton'
                : 'searchButton'
            }
            type="button"
          >
            <h4>{!whereText ? '¿Dónde?' : ''}</h4>
            <div className="container-filters-selected">
              <div key={Math.random()} className="filterSelected">
                {whereText}
              </div>
            </div>
          </button>
          <button
            onClick={() => {
              closeAll();
              setOpenWhen(!openWhen);
            }}
            id="boton-cuando"
            className={
              openWhen === true
                ? 'searchButtonWhen searchButton'
                : 'searchButton'
            }
            type="button"
          >
            <h4>{!whenText ? '¿Cuándo?' : ''}</h4>
            <div className="container-filters-selected">
              <div key={Math.random()} className="filterSelected">
                {whenText}
              </div>
            </div>
          </button>
          <button
            onClick={() => {
              closeAll();
              setOpenType(!openType);
            }}
            id="boton-que"
            className={
              openType === true
                ? 'searchButtonType searchButton'
                : 'searchButton'
            }
            type="button"
          >
            <h4>{!whatText ? '¿Qué?' : ''}</h4>

            <div className="container-filters-selected">
              <div key={Math.random()} className="filterSelected">
                {whatText}
              </div>
            </div>
          </button>

          <Link
            className="searchButton voyButton"
            type="button"
            to="/ver-eventos"
          >
            voy
          </Link>
        </div>
      </div>
      <div className="container-search">
        <nav className={openWhen === true ? 'searchMenu' : 'when'}>
          <button className="close-button mobileElement" type="button" onClick={() => { setOpenWhen(false); }}><img src={Cruz} alt="Cerrar" /></button>
          <div className="searchMenuOption">
            <ButtonSelected category="date" idButton="filtro-cuando-hoy" nameButton="Hoy" />
            <ButtonSelected category="date" idButton="filtro-cuando-este-finde" nameButton="Este finde" />
            <ButtonSelected category="date" idButton="filtro-cuando-esta-semana" nameButton="Esta semana" />
            <ButtonSelected category="date" idButton="filtro-cuando-este-mes" nameButton="Este mes" />
            <Calendar label="FECHAS" />
          </div>
          <button
            onClick={() => {
              closeAll();
            }}
            className="mobileElement apply"
            type="button"
          >
            APLICAR
          </button>
        </nav>
      </div>

      <div className="container-search">
        <nav
          className={
            openWhere === true
              ? 'searchMenu desktopElement'
              : 'type desktopElement'
          }
        >
          <div className="searchMenuOption">
            <LocationsSelect
              locations={locations}
              localities={props.localities}
              placeholder="Buscá un lugar..."
              className="searchSite"
              name="busquedamusica"
            />
            <Link to="/view-map" className="viewMap">
              <img src={Ubication} alt="icon" />
              VER EN EL MAPA
            </Link>

            {props.cities?.map((city) => (
              <ButtonSelected
                key={`city${city.id}`}
                category="city"
                value={city.id}
                idButton={normalizeString(`filtro-ciudad-${city.name}`).replaceAll(' ', '-')}
                nameButton={city.name}
              />
            ))}
          </div>
        </nav>
        <Div100vh
          className={
            openWhere === true
              ? 'searchMenu type-mobile mobileElement'
              : 'type mobileElement'
          }
        >
          <button className="close-button mobileElement" type="button" onClick={() => { setOpenWhere(false); }}><img src={Cruz} alt="Cerrar" /></button>
          <div className="searchMenuOption">
            <div className="search-menu-map-arrow">
              <button type="button" onClick={() => { closeAll(); }}>
                <img src={ArrowMap} alt="" />
              </button>
              <LocationsSelect
                locations={locations}
                localities={props.localities}
                className="searchSite"
                name="busquedamusica"
                placeholder="Buscá un lugar..."
              />
            </div>

            <Link to="/view-map" className="viewMap">
              <img src={Ubication} alt="icon" />
              VER EN EL MAPA
            </Link>

            {props.cities?.map((city) => (
              <ButtonSelected
                key={`city${city.id}`}
                category="city"
                value={city.id}
                idButton={normalizeString(`filtro-ciudad-${city.name}`).replaceAll(' ', '-')}
                nameButton={city.name}
              />
            ))}

            <div className="near-location mobileElement">
              <button type="button" className="distanciaCerca">
                <img src={NearLocation} alt="ubication" />
                Distancias desde tu ubicación
              </button>
              <span>Distancias desde tu ubicación</span>
              <div>
                <ButtonSelected
                  category="distance"
                  idButton="filtro-donde-1km"
                  value={1}
                  nameButton="Menos de 1km"
                />
                <ButtonSelected
                  category="distance"
                  idButton="filtro-donde-3km"
                  value={3}
                  nameButton="Menos de 3km"
                />
                <ButtonSelected
                  category="distance"
                  idButton="filtro-donde-5km"
                  value={5}
                  nameButton="Menos de 5km"
                />
                <ButtonSelected
                  category="distance"
                  idButton="filtro-donde-10km"
                  value={10}
                  nameButton="Menos de 10km"
                />
              </div>
              <button
                onClick={() => {
                  closeAll();
                }}
                className="mobileElement apply"
                type="button"
              >
                APLICAR
              </button>
            </div>
          </div>
        </Div100vh>
      </div>
      <div className="container-search">
        <nav className={openType === true ? 'searchMenu' : 'where'}>
          <button className="close-button mobileElement" type="button" onClick={() => { setOpenType(false); }}><img src={Cruz} alt="Cerrar" /></button>
          <div className="searchMenuOption">
            {props.eventTypes?.map((type) => (
              <ButtonSelected
                key={`eventType${type.id}`}
                category="type"
                value={type.id}
                idButton={normalizeString(`filtro-que-${type.name}`).replaceAll(' ', '-')}
                nameButton={type.name}
              />
            ))}
          </div>
          <button
            onClick={() => {
              closeAll();
            }}
            className="mobileElement apply"
            type="button"
          >
            APLICAR
          </button>
        </nav>
      </div>
      <div className="container-cities">
        <Slider {...settings} className="mobileElement">
          {props.cities?.map((city) => (
            <ButtonSelected
              key={`city${city.id}`}
                  // image={city.name}
              category="city"
              value={city.id}
              idButton={normalizeString(`filtro-ciudad-${city.name}`).replaceAll(' ', '-')}
              nameButton={city.name}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  locations: state.app.locations,
  filters: state.app.filters,
  events: state.app.events,
  eventTypes: state.app.eventTypes,
  cities: state.app.cities,
  localities: state.app.localities,
});

export default connect(mapStateToProps, null)(searchHeader);
