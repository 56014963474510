/* eslint-disable linebreak-style */
/* eslint-disable react/no-unescaped-entities */
import { React, useEffect } from 'react';
import '../../style/Site/privacyPolicy.css';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import Clients from '../Advertisments/Clients';
import { BlurPruple, BlurGreen } from '../../img';

function PrivacyPolicy() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className="privacy-policy">
      <Header />
      <img src={BlurPruple} alt="" className="blur-purple blur" />
      <img src={BlurGreen} alt="" className="blur-green blur" />
      <div className="container-privacy-policy">
        <h1>Política de Privacidad</h1>
        <p>Última actualización: 07 de febrero de 2024</p>
        <p>Esta Política de Privacidad describe nuestras políticas y procedimientos sobre la recopilación, uso y divulgación de su información cuando utiliza el Servicio y le informa sobre sus derechos de privacidad y cómo la ley lo protege.</p>
        <p>Utilizamos sus datos personales para proporcionar y mejorar el Servicio. Al utilizar el Servicio, usted acepta la recopilación y el uso de información de acuerdo con esta Política de Privacidad. Esta Política de Privacidad ha sido creada con la ayuda del <a href="https://www.termsfeed.com/privacy-policy-generator/" target="_blank">Generador de Política de Privacidad</a>.</p>
        <h2>Interpretación y Definiciones</h2>
        <h3>Interpretación</h3>
        <p>Las palabras cuya inicial esté en mayúscula tienen significados definidos en las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de si aparecen en singular o en plural.</p>
        <h3>Definiciones</h3>
        <p>Para los fines de esta Política de Privacidad:</p>
        <ul>
          <li>
            <p><strong>Cuenta</strong> significa una cuenta única creada para que usted acceda a nuestro Servicio o partes de nuestro Servicio.</p>
          </li>
          <li>
            <p><strong>Afiliado</strong> significa una entidad que controla, es controlada por o está bajo control común con una parte, donde "control" significa propiedad del 50% o más de las acciones, participación patrimonial u otros valores con derecho a voto para la elección de directores u otra autoridad de gestión.</p>
          </li>
          <li>
            <p><strong>Empresa</strong> (referida como "la Empresa", "Nosotros", "Nuestro" o "Nuestra" en este Acuerdo) se refiere a VOY.</p>
          </li>
          <li>
            <p><strong>Cookies</strong> son archivos pequeños que se colocan en su computadora, dispositivo móvil o cualquier otro dispositivo por un sitio web, que contiene los detalles de su historial de navegación en ese sitio web entre sus muchos usos.</p>
          </li>
          <li>
            <p><strong>País</strong> se refiere a: Uruguay</p>
          </li>
          <li>
            <p><strong>Dispositivo</strong> significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital.</p>
          </li>
          <li>
            <p><strong>Datos personales</strong> son cualquier información que se relacione con una persona identificada o identificable.</p>
          </li>
          <li>
            <p><strong>Servicio</strong> se refiere al Sitio web.</p>
          </li>
          <li>
            <p><strong>Proveedor de servicios</strong> significa cualquier persona natural o jurídica que procese los datos en nombre de la Empresa. Se refiere a empresas o individuos de terceros empleados por la Empresa para facilitar el Servicio, para proporcionar el Servicio en nombre de la Empresa, para realizar servicios relacionados con el Servicio o para ayudar a la Empresa a analizar cómo se utiliza el Servicio.</p>
          </li>
          <li>
            <p><strong>Datos de uso</strong> se refiere a datos recopilados automáticamente, ya sea generados por el uso del Servicio o desde la infraestructura del Servicio en sí misma (por ejemplo, la duración de una visita a la página).</p>
          </li>
          <li>
            <p><strong>Sitio web</strong> se refiere a VOY, accesible desde <a href="https://voy.com.uy" rel="external nofollow noopener" target="_blank">https://voy.com.uy</a></p>
          </li>
          <li>
            <p><strong>Usted</strong> significa el individuo que accede o utiliza el Servicio, o la empresa u otra entidad legal en nombre de la cual dicho individuo accede o utiliza el Servicio, según corresponda.</p>
          </li>
        </ul>
        <h2>Recopilación y Uso de sus Datos Personales</h2>
        <h3>Tipos de Datos Recopilados</h3>
        <h4>Datos Personales</h4>
        <p>Mientras utiliza Nuestro Servicio, podemos solicitarle que nos proporcione cierta información de identificación personal que se puede utilizar para contactarlo o identificarlo. La información de identificación personal puede incluir, entre otras cosas:</p>
        <ul>
          <li>
            <p>Dirección de correo electrónico</p>
          </li>
          <li>
            <p>Nombre y apellido</p>
          </li>
          <li>
            <p>Datos de uso</p>
          </li>
        </ul>
        <h4>Datos de uso</h4>
        <p>Los datos de uso se recopilan automáticamente al utilizar el Servicio.</p>
        <p>Los datos de uso pueden incluir información como la dirección de protocolo de Internet de su dispositivo (por ejemplo, dirección IP), tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo que pasa en esas páginas, identificadores de dispositivo únicos y otros datos de diagnóstico.</p>
        <p>Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información automáticamente, incluido, entre otros, el tipo de dispositivo móvil que utiliza, su ID único de dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo móvil, el tipo de navegador de Internet móvil que utiliza, identificadores de dispositivo únicos y otros datos de diagnóstico.</p>
        <p>También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de un dispositivo móvil.</p>
        <h4>Tecnologías de seguimiento y Cookies</h4>
        <p>Utilizamos Cookies y tecnologías de seguimiento similares para rastrear la actividad en Nuestro Servicio y almacenar cierta información. Las tecnologías de seguimiento utilizadas son balizas, etiquetas y scripts para recopilar y rastrear información y para mejorar y analizar Nuestro Servicio. Las tecnologías que utilizamos pueden incluir:</p>
        <ul>
          <li><strong>Cookies o Cookies del navegador.</strong> Una cookie es un pequeño archivo colocado en su dispositivo. Puede instruir a su navegador para que rechace todas las Cookies o para que indique cuándo se está enviando una Cookie. Sin embargo, si no acepta Cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio. A menos que haya ajustado la configuración de su navegador para que rechace Cookies, nuestro Servicio puede utilizar Cookies.</li>
          <li><strong>Web Beacons.</strong> Ciertas secciones de nuestro Servicio y nuestros correos electrónicos pueden contener pequeños archivos electrónicos conocidos como web beacons (también llamados gifs transparentes, etiquetas de píxeles y gifs de píxeles únicos) que permiten a la Empresa, por ejemplo, contar usuarios que han visitado esas páginas o abierto un correo electrónico y para otras estadísticas relacionadas con el sitio web (por ejemplo, registrar la popularidad de una cierta sección y verificar la integridad del sistema y del servidor).</li>
        </ul>
        <p>Las Cookies pueden ser "Persistentes" o "de Sesión". Las Cookies Persistentes permanecen en su computadora personal o dispositivo móvil cuando se desconecta, mientras que las Cookies de Sesión se eliminan tan pronto como cierra su navegador web. Puede obtener más información sobre las cookies en el artículo del sitio web de <a href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies" target="_blank">TermsFeed</a>.</p>
        <p>Utilizamos tanto Cookies de Sesión como Cookies Persistentes para los fines establecidos a continuación:</p>
        <ul>
          <li>
            <p><strong>Necesarias / Cookies Esenciales</strong></p>
            <p>Tipo: Cookies de Sesión</p>
            <p>Administradas por: Nosotros</p>
            <p>Propósito: Estas Cookies son esenciales para proporcionarle los servicios disponibles a través del Sitio web y para permitirle utilizar algunas de sus funciones. Ayudan a autenticar a los usuarios y a prevenir el uso fraudulento de las cuentas de usuario. Sin estas Cookies, los servicios que ha solicitado no pueden ser proporcionados, y solo utilizamos estas Cookies para proporcionarle esos servicios.</p>
          </li>
          <li>
            <p><strong>Cookies de Política / Aceptación de Aviso</strong></p>
            <p>Tipo: Cookies Persistentes</p>
            <p>Administradas por: Nosotros</p>
            <p>Propósito: Estas Cookies identifican si los usuarios han aceptado el uso de cookies en el Sitio web.</p>
          </li>
          <li>
            <p><strong>Cookies de Funcionalidad</strong></p>
            <p>Tipo: Cookies Persistentes</p>
            <p>Administradas por: Nosotros</p>
            <p>Propósito: Estas Cookies nos permiten recordar las opciones que hace cuando utiliza el Sitio web, como recordar sus detalles de inicio de sesión o preferencia de idioma. El propósito de estas Cookies es proporcionarle una experiencia más personal y evitar que tenga que volver a ingresar sus preferencias cada vez que utilice el Sitio web.</p>
          </li>
        </ul>
        <p>Para obtener más información sobre las cookies que utilizamos y sus opciones con respecto a las cookies, visite nuestra Política de Cookies o la sección de Cookies de nuestra Política de Privacidad.</p>
        <h3>Uso de sus Datos Personales</h3>
        <p>La Empresa puede utilizar Datos Personales para los siguientes fines:</p>
        <ul>
          <li>
            <p><strong>Para proporcionar y mantener nuestro Servicio</strong>, incluido para monitorear el uso de nuestro Servicio.</p>
          </li>
          <li>
            <p><strong>Para administrar su Cuenta:</strong> para administrar su registro como usuario del Servicio. Los Datos Personales que proporciona pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para usted como usuario registrado.</p>
          </li>
          <li>
            <p><strong>Para el cumplimiento de un contrato:</strong> el desarrollo, cumplimiento y realización del contrato de compra de los productos, artículos o servicios que haya adquirido o de cualquier otro contrato con Nosotros a través del Servicio.</p>
          </li>
          <li>
            <p><strong>Para contactarlo:</strong> Para contactarlo por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como notificaciones push de aplicaciones móviles sobre actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación.</p>
          </li>
          <li>
            <p><strong>Para proporcionarle</strong> noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos y que son similares a los que ya ha adquirido o preguntado, a menos que haya optado por no recibir esa información.</p>
          </li>
          <li>
            <p><strong>Para administrar sus solicitudes:</strong> para atender y administrar sus solicitudes hacia Nosotros.</p>
          </li>
          <li>
            <p><strong>Para transferencias comerciales:</strong> Podemos utilizar su información para evaluar o llevar a cabo una fusión, disolución, reestructuración, reorganización, disolución u otra venta o transferencia de la totalidad o parte de nuestros activos, ya sea como empresa en funcionamiento o como parte de un procedimiento de quiebra, liquidación o procedimiento similar, en el que los Datos Personales mantenidos por Nosotros sobre los usuarios de nuestro Servicio estén entre los activos transferidos.</p>
          </li>
          <li>
            <p><strong>Para otros fines</strong>: Podemos utilizar su información para otros fines, como análisis de datos, identificación de tendencias de uso, determinación de la efectividad de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su experiencia.</p>
          </li>
        </ul>
        <p>Podemos compartir su información personal en las siguientes situaciones:</p>
        <ul>
          <li><strong>Con Proveedores de Servicios:</strong> Podemos compartir su información personal con Proveedores de Servicios para monitorear y analizar el uso de nuestro Servicio, para contactarlo.</li>
          <li><strong>Para transferencias comerciales:</strong> Podemos compartir o transferir su información personal en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la Empresa, financiamiento o adquisición de la totalidad o una parte de nuestro negocio a otra empresa.</li>
          <li><strong>Con Afiliados:</strong> Podemos compartir su información con Nuestros afiliados, en cuyo caso requeriremos que esos afiliados respeten esta Política de Privacidad. Los afiliados incluyen nuestra empresa matriz y cualquier otra subsidiaria, socio de empresas conjuntas u otras empresas que controlamos o que están bajo control común con Nosotros.</li>
          <li><strong>Con socios comerciales:</strong> Podemos compartir su información con Nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones.</li>
          <li><strong>Con otros usuarios:</strong> cuando comparta información personal o interactúe de otra manera en las áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede ser distribuida públicamente fuera.</li>
          <li><strong>Con su consentimiento</strong>: Podemos divulgar su información personal para cualquier otro propósito con su consentimiento.</li>
        </ul>
        <h3>Retención de sus Datos Personales</h3>
        <p>La Empresa retendrá sus Datos Personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de Privacidad. Retendremos y utilizaremos sus Datos Personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a retener sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.</p>
        <p>La Empresa también retendrá Datos de Uso con fines de análisis interno. Los Datos de Uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o mejorar la funcionalidad de Nuestro Servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.</p>
        <h3>Transferencia de sus Datos Personales</h3>
        <p>Su información, incluidos los Datos Personales, se procesa en las oficinas operativas de la Empresa y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Significa que esta información puede ser transferida y mantenida en computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.</p>
        <p>Su consentimiento a esta Política de Privacidad seguido de su envío de dicha información representa su acuerdo con esa transferencia.</p>
        <p>La Empresa tomará todas las medidas razonablemente necesarias para garantizar que sus datos sean tratados de manera segura y de acuerdo con esta Política de Privacidad y ninguna transferencia de sus Datos Personales tendrá lugar a una organización o un país a menos que haya controles adecuados en lugar, incluida la seguridad de sus datos y otra información personal.</p>
        <h3>Borrar sus Datos Personales</h3>
        <p>Usted tiene derecho a eliminar o solicitar que le ayudemos a eliminar los Datos Personales que hemos recopilado sobre usted.</p>
        <p>Nuestro Servicio puede brindarle la capacidad de eliminar cierta información sobre usted desde dentro del Servicio.</p>
        <p>Puede actualizar, modificar o eliminar su información en cualquier momento iniciando sesión en su Cuenta, si la tiene, y visitando la sección de configuración de la cuenta que le permite administrar su información personal. También puede contactarnos para solicitar acceso, corrección o eliminación de cualquier información personal que nos haya proporcionado.</p>
        <p>Sin embargo, tenga en cuenta que es posible que necesitemos retener cierta información cuando tengamos una obligación legal o base legal para hacerlo.</p>
        <h3>Divulgación de sus Datos Personales</h3>
        <h4>Transacciones comerciales</h4>
        <p>Si la Empresa está involucrada en una fusión, adquisición o venta de activos, sus Datos Personales pueden ser transferidos. Le proporcionaremos aviso antes de que sus Datos Personales sean transferidos y estén sujetos a una Política de Privacidad diferente.</p>
        <h4>Aplicación de la ley</h4>
        <p>Bajo ciertas circunstancias, la Empresa puede estar obligada a divulgar sus Datos Personales si así lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).</p>
        <h4>Otros requisitos legales</h4>
        <p>La Empresa puede divulgar sus Datos Personales de buena fe creyendo que tal acción es necesaria para:</p>
        <ul>
          <li>Cumplir con una obligación legal</li>
          <li>Proteger y defender los derechos o propiedades de la Empresa</li>
          <li>Prevenir o investigar posibles irregularidades en relación con el Servicio</li>
          <li>Proteger la seguridad personal de los usuarios del Servicio o del público</li>
          <li>Protegerse contra la responsabilidad legal</li>
        </ul>
        <h3>Seguridad de sus Datos Personales</h3>
        <p>La seguridad de sus Datos Personales es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos Personales, no podemos garantizar su seguridad absoluta.</p>
        <h2>Privacidad de los Niños</h2>
        <p>Nuestro Servicio no se dirige a nadie menor de 13 años. No recopilamos de manera consciente información de identificación personal de nadie menor de 13 años. Si usted es padre/madre o tutor y sabe que su hijo nos ha proporcionado Datos Personales, contáctenos. Si nos damos cuenta de que hemos recopilado Datos Personales de cualquier persona menor de 13 años sin verificación del consentimiento parental, tomamos medidas para eliminar esa información de nuestros servidores.</p>
        <p>Si necesitamos confiar en el consentimiento como base legal para procesar su información y su país requiere el consentimiento de un padre, podemos requerir el consentimiento de su padre antes de recopilar y usar esa información.</p>
        <h2>Enlaces a Otros Sitios Web</h2>
        <p>Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por nosotros. Si hace clic en un enlace de un tercero, será dirigido al sitio web de ese tercero. Le recomendamos encarecidamente que revise la Política de Privacidad de cada sitio que visite.</p>
        <p>No tenemos control sobre y no asumimos responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios web o servicios de terceros.</p>
        <h2>Cambios a esta Política de Privacidad</h2>
        <p>Podemos actualizar Nuestra Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página.</p>
        <p>Le informaremos por correo electrónico y/o un aviso destacado sobre nuestro Servicio antes de que el cambio entre en vigencia y actualizaremos la "fecha efectiva" en la parte superior de esta Política de Privacidad.</p>
        <p>Se recomienda que revise esta Política de Privacidad periódicamente para cualquier cambio. Los cambios a esta Política de Privacidad son efectivos cuando se publican en esta página.</p>
        <h2>Contacte con Nosotros</h2>
        <p>Si tiene alguna pregunta sobre esta Política de Privacidad, puede contactarnos:</p>
        <ul>
          <li>Por correo electrónico: info@voy.com.uy</li>
        </ul><script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
      </div>
      <Clients />
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
