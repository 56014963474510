/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Star from '../Buttons/Star';
import { endpointCloudfront } from '../../endpoints';
import '../../style/Slider/sliderHome.css';
import { getSponsor } from '../../service';

function SliderHome(props) {
  let sponsorIds = null;
  if (props.eventPromotions) {
    sponsorIds = [...new Set(props.eventPromotions.map((x) => x.sponsor_id))];
  }

  const [imgLoading, setImgLoading] = useState(true);

  function onLoad() {
    setImgLoading(false);
  }

  function sliderContent() {
    return (
      <div className="container-information">
        <img
          src={props.bandImgDesktop}
          style={{ display: imgLoading ? 'none' : 'block' }}
          onLoad={onLoad}
          alt=""
          className="band desktopElement"
        />
        <div className="containerBandMobile">
          <img
            src={props.bandImgMobile}
            style={{ display: imgLoading ? 'none' : 'block' }}
            onLoad={onLoad}
            alt=""
            className="bandMobile mobileElement"
          />
        </div>
        <div className="cocardaBankSliderHome">
          {sponsorIds?.map((id) => {
            const cocarda = getSponsor(id, props.sponsors).file
              ?.webp_thumbnail_url;
            return (
              <img
                key={`cocarda${id}`}
                src={endpointCloudfront + cocarda}
                alt={props.infoCocardaBank}
              />
            );
          })}
        </div>
        <div className="information">
          <div className="container-information-slider">
            <div>
              <div>
                <div className="containerSliderIconAndType">
                  <img
                    src={props.iconTitleTypeEvent}
                    style={{ display: imgLoading ? 'none' : 'block' }}
                    onLoad={onLoad}
                    alt="Icon Event"
                  />
                  <h5 className={`theme ${props.theme}`} id="mobileElement">{props.theme}</h5>
                </div>
                <h2 className="title">{props.title}</h2>
              </div>
              <span className="informationTheme">{props.information}</span>
            </div>
            <div>
              <Star typeEvent={props.theme} />
              {!props.linkOut ? (
                <Link
                  to={`/evento/${props.link}`}
                  className={`wantToGo ${props.theme}`}
                >
                  {props.titleButton}
                </Link>

              ) : (
                <a
                  href={props.link}
                  className={`wantToGo ${props.theme}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.titleButton}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    props.link ? (
      !props.linkOut ? (
        <Link to={`/evento/${props.link}`}>
          {sliderContent()}
        </Link>
      ) : (
        <a href={props.link} target="_blank" rel="noreferrer">
          {sliderContent()}
        </a>
      )
    ) : (
      <>
        {sliderContent()}
      </>
    )
  );
}

const mapStateToProps = (state) => ({
  sponsors: state.app.sponsors,
});
export default connect(mapStateToProps, null)(SliderHome);
