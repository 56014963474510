/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import '../../style/Site/eventInformation.css';
import { hotjar } from 'react-hotjar';
import Slider from 'react-slick';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';

import axios from 'axios';
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
  WhatsappShareButton,
} from 'react-share';
import {
  getIdFromLink,
  getSponsor,
  getPromotion,
  getEventTypeName,
  getGenreName,
  getDate,
  getTime,
  getEventTypeIcon,
  getFilteredEvents,
  getFilteredLocations,
} from '../../service';
import Banner from '../Advertisments/Banner';
import {
  BannerVoyRegistro,
  IconCalendar,
  IconUbication,
  IconPrice,
  InstagramIcon,
  ArrowSliderLeft,
  ArrowSliderRight,
  IconInfo,
  CruzFechas,
  RestaurantsIcon,
  youtubeIcon,
  ShareIcon,
  CocardaBank,
  SpotifyIcon,
  FacebookIcon,
} from '../../img';
import {
  setLoading, setShowedPopup, setOpenLogin,
} from '../../state/AppState';
import { domain, endpointEventData, endpointEventClicks, endpointCloudfront } from '../../endpoints';
import Footer from '../Layouts/Footer';
import Clients from '../Advertisments/Clients';
import Events from '../Events/Events';
import Header from '../Layouts/Header';
import SliderHome from '../Slider/SliderHome';
import Star from '../Buttons/Star';
import EventInfoMap from '../Map/EventInfoMap';
import Event from '../Events/Event';

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="arrowSliderRight" type="button">
      <img src={ArrowSliderRight} alt="" />
    </button>
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="arrowSliderLeft" type="button">
      <img src={ArrowSliderLeft} alt="" />
    </button>
  );
}

function EventInformation({
  setLoadingState,
  events,
  locations,
  bands,
  eventTypes,
  genres,
  restaurants,
  sponsors,
  localities,
  openLogin,
  setOpenLoginState,
  setShowedPopupState,
}) {
  const [event, setEvent] = useState();
  const [eventBands, setEventBands] = useState();
  const [eventFunctions, setEventFunctions] = useState();
  const [location, setLocation] = useState();
  const params = useParams();
  const { link } = params;
  const [id, setId] = useState();
  const maxRestaurantDistance = 2;
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const [openInfoEvent, setOpenInfoEvent] = useState(true);
  const [openAboutEvent, setOpenAboutEvent] = useState(false);
  const [openLocationEvent, setOpenLocationEvent] = useState(false);
  const [openFechas, setOpenFechas] = useState(false);
  const [closeFechas, setCloseFechas] = useState(false);
  const [shareRedes, setShareRedes] = useState(false);
  const [showPromotionDetails, setShowPromotionDetails] = useState(false);

  useEffect(() => {
    const linkId = getIdFromLink(link);
    setId(linkId);
    if (hotjar.initialized()) {
      hotjar.stateChange(link);
    }
  }, [link]);

  async function getEventData(id) {
    const data = {
      id,
      userId: 0,
      userAction: 'ingresó',
    };
    setLoadingState(true);
    try {
      const response = await axios.post(endpointEventData, data);
      setLoadingState(false);
      const eventData = response.data.events;
      setEvent(eventData);
      setEventBands(eventData.eventBandsForEvent.map((band) => (band.bands)));
      setLocation(eventData.eventFunctionsForEvent[0].locations);
      setEventFunctions(eventData.eventFunctionsForEvent?.reduce((array, item) => {
        array[item.date] = array[item.date] || [];
        array[item.date].push(item);
        return array;
      }, Object.create(null)));
      const asyncGet = async () => {
        let filteredEvents = await getFilteredEvents(
          {
            type: [parseFloat(eventData.event_type_id)],
            genre: JSON.parse(eventData.genres),
          },
          events,
          locations,
          localities,
        );
        filteredEvents = filteredEvents.filter((event) => {
          if (event.id !== id) {
            return true;
          }
          return false;
        });
        setFilteredEvents(filteredEvents);
      };
      asyncGet();
      return response;
    } catch (error) {
      setLoadingState(false);
      return error;
    }
  }

  async function addEventClick() {
    const data = {
      eventClick: {
        user_id: 0,
        event_id: event?.id,
      }
    };
    try {
      const response = await axios.post(endpointEventClicks, data);
      if (response.data.status != "ok")
        console.log("No se pudo guardar el click: " + response.errorMessage);
    } catch (error) {
      console.log("Error detectando click: " + error);
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    if (id) {
      getEventData(id);
      setOpenInfoEvent(true);
      setOpenAboutEvent(false);
      setOpenLocationEvent(false);
    }
  }, [id]);

  useEffect(() => {
    const asyncGet = async () => {
      setFilteredRestaurants(await getFilteredLocations(
        false,
        restaurants,
        localities,
        { latitude: location?.latitude, longitude: location?.longitude },
        maxRestaurantDistance,
      ));
    };

    if (location && location.latitude && location.longitude) {
      asyncGet();
    } else {
      setFilteredRestaurants([]);
    }
  }, [location]);

  const singleImgEvent = {
    dots: false,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const doubleImgEvent = {
    dots: false,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const manyImgEvent = {
    dots: false,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const imgEventDetail = {
    dots: false,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  const imgLocationDetail = {
    dots: false,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: false,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const closeAll = () => {
    setOpenInfoEvent(false);
    setOpenAboutEvent(false);
    setOpenLocationEvent(false);
    setCloseFechas(false);
    setShowPromotionDetails(false);
  };

  const handlePromotionDetails = (index) => {
    setShowPromotionDetails((prev) => (prev === index ? null : index));
  };

  const shareUrl = `${domain}/evento/${link}`;

  let imgSliderDetails = {};

  if (event?.files.length > 0) {
    imgSliderDetails = event?.files.length > 2 ? { ...manyImgEvent } :
      (event?.files.length > 1 ? { ...doubleImgEvent } : { ...singleImgEvent });
  }
  return (
    <>
      {event ? (
        <Helmet>
          <title>{`${eventBands?.map((band, index) => ((index > 0 ? ', ' : '') + band.name)).join('') || event?.name} en ${location?.name}`}</title>
          <meta name="title" content={`${eventBands?.map((band, index) => ((index > 0 ? ', ' : '') + band.name)).join('') || event?.name} en ${location?.name}`} />
          <meta name="description" content={`${eventBands?.map((band, index) => ((index > 0 ? ', ' : '') + band.name)).join('') || event?.name} en ${location?.name} Fechas y beneficios para entradas, descuentos.`} />
          <meta property="og:title" content={`${eventBands?.map((band, index) => ((index > 0 ? ', ' : '') + band.name)).join('') || event?.name} en ${location?.name}`} />
          <meta property="og:description" content={`${eventBands?.map((band, index) => ((index > 0 ? ', ' : '') + band.name)).join('') || event?.name} en ${location?.name} Fechas y beneficios para entradas, descuentos.`} />
          <meta property="twitter:title" content={`${eventBands?.map((band, index) => ((index > 0 ? ', ' : '') + band.name)).join('') || event?.name} en ${location?.name}`} />
          <meta property="twitter:description" content={`${eventBands?.map((band, index) => ((index > 0 ? ', ' : '') + band.name)).join('') || event?.name} en ${location?.name} Fechas y beneficios para entradas, descuentos.`} />
        </Helmet>
      ) : ''}
      <div className="container-event-information">
        <Header />
        <div className="container-start">
          {' '}
          <SliderHome
            iconTitleTypeEvent={getEventTypeIcon(event?.event_type_id, eventTypes)}
            bandImgDesktop={endpointCloudfront + event?.desktopFileForEvent?.webp_url}
            bandImgMobile={endpointCloudfront + event?.mobileFileForEvent?.webp_url}
            theme={getEventTypeName(event?.event_type_id, eventTypes)}
            title={event?.name}
          />
          <button
            onClick={() => {
              closeAll();
              setShareRedes(!shareRedes);
            }}
            className={
              shareRedes === true
                ? 'redes-share redes-share-open'
                : 'redes-share'
            }
            type="button"
          >
            <p className="desktopElement">Compartí en </p>
            <img className="mobileElement" src={ShareIcon} alt="" />

            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <FacebookMessengerShareButton url={shareUrl}>
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </button>
        </div>

        {/* event?.facebook_link ? (
            <a
            href={event?.facebook_link}
              target="_blank"
              className="desktopElement"
              rel="noreferrer"
              >
              <img src={FacebookIcon} alt="" />
              </a>
              ) : (
                ''
          )}
          {event?.instagram_link ? (
            <a
            href={event?.instagram_link}
            target="_blank"
            className="desktopElement"
            rel="noreferrer"
            >
              <img src={InstagramIcon} alt="" />
            </a>
          ) : (
            ''
          )}
          {event?.twitter_link ? (
            <a
              href={event?.twitter_link}
              target="_blank"
              className="desktopElement"
              rel="noreferrer"
              >
              <img src={TwitterIcon} alt="" />
              </a>
              ) : (
                ''
          ) */}
        {/* {event?.phone
            ? (
              <a href={event?.phone} target="_blank" className="desktopElement" rel="noreferrer">
              <img src={WhatsappIcon} alt="" />
              </a>
            )
            : ''} */}

        <div className="information-event-select">
          <div className="event-data">
            {event?.eventFunctionsForEvent?.length > 0 ? (
              <>
                <div type="button" className="container-fechas-event">
                  <img src={IconCalendar} alt="" />
                  <div>
                    <h2>{`${getDate(event?.eventFunctionsForEvent[0]?.date)} - ${getTime(event?.eventFunctionsForEvent[0]?.start_time)}`}</h2>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setOpenFechas(!openFechas);
                  }}
                  className={
                    openFechas === true ? 'buttonOpenFechas' : 'buttonFechas'
                  }
                >
                  Ver todas las funciones
                  <img src={IconInfo} alt="" />
                </button>
                <div
                  className={
                    openFechas === true ? 'data-fecha-event' : 'event-data-date'
                  }
                >
                  <div className="triangulo-top" />
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setOpenFechas(!openFechas);
                      }}
                      className={
                        closeFechas === true ? 'cruz-fecha close-fecha' : 'cruz-fecha'
                      }
                    >
                      <img src={CruzFechas} alt="" />
                    </button>
                    <h3>Funciones</h3>
                    {Object.entries(eventFunctions)?.map((eventFunction) => {
                      const locations = eventFunction[1]?.reduce((array, item) => {
                        array[item.locations?.name] = array[item.locations?.name] || [];
                        array[item.locations?.name].push(item);
                        return array;
                      }, Object.create(null));
                      return (
                        <>
                          <h2>{getDate(eventFunction[0])}</h2>
                          {Object.entries(locations)?.map((location) => (
                            <Link to={`/locacion/${location[1][0].locations.link}`}>
                              <p><b>{location[0]}</b></p>

                              {location[1]?.map((item) => {
                                let language = item.language && item.language !== 'NULL' ? ` - ${item.language}` : '';
                                const dimension = item.dimension && item.dimension !== 'NULL' ? ` - ${item.dimension}` : '';
                                language = language.replace('<span class="bold">', '').replace('</span>', '');
                                return (
                                  <p>{`${getTime(item.start_time)}${language}${dimension}`}</p>
                                );
                              })}
                            </Link>
                          ))}
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : ''}
          </div>

          <div className="event-data">
            {location ? (
              <>
                <img src={IconUbication} alt="" />
                <div className="event-data-date">
                  <h2 className="next-function-title">Próxima función:</h2>
                  <Link to={`/locacion/${location?.link}`}>
                    <h2 className="next-function">{location?.name}</h2>
                    <p>{location?.address}</p>
                  </Link>
                </div>
              </>
            ) : ''}
          </div>

          <div className="event-data">
            {event?.price || event?.price_from ? (
              <img src={IconPrice} alt="" />
            ) : ''}
            <div className="event-data-date">
              {!event?.price && event?.price_from && event?.price_to ? (
                <h2>
                  Desde
                  {' '}
                  {event?.currency_id === '2' ? 'U$S ' : '$'}
                  {event?.price_from}
                  {' '}
                  hasta
                  {' '}
                  {event?.currency_id === '2' ? 'U$S ' : '$'}
                  {event?.price_to}
                </h2>
              ) : ''}
              {event?.price ? (
                <h2>
                  {event?.currency_id === '2' ? 'U$S ' : '$'}
                  {event?.price}
                </h2>
              ) : ''}
              {event?.purchase_link && event?.purchase_link !== '-' ? (
                <a href={event?.purchase_link} target="_blank" rel="noreferrer" onClick={() => {
                  addEventClick()
                }}>
                  <button type="button" className="buy-ticket-button">
                    Comprá tu entrada acá
                  </button>
                </a>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="container-detail-information">
          <div className="header-detail-information">
            <button
              onClick={() => {
                closeAll();
                setOpenInfoEvent(!openInfoEvent);
              }}
              className={openInfoEvent === true ? 'buttonOpen' : 'buttonInfo'}
              type="button"
            >
              Sobre el evento
            </button>
            {eventBands?.length > 0 ? (
              <button
                onClick={() => {
                  closeAll();
                  setOpenAboutEvent(!openAboutEvent);
                }}
                className={openAboutEvent === true ? 'buttonOpen' : 'buttonInfo'}
                type="button"
              >
                {eventBands?.length > 1 ? 'Artistas' : 'Artista'}
              </button>
            ) : ''}
            {location ? (
              <button
                onClick={() => {
                  closeAll();
                  setOpenLocationEvent(!openLocationEvent);
                }}
                className={openLocationEvent === true ? 'buttonOpen' : 'buttonInfo'}
                type="button"
              >
                Locación
              </button>
            ) : ''}
          </div>
          <div
            className={openInfoEvent === true ? 'openInfo' : 'about-the-event'}
          >
            <div className="detail-info-event">
              <p>
                <span>
                  {event
                    ? getEventTypeName(event?.event_type_id, eventTypes)
                    : ''}

                  {event && genres
                    ? JSON.parse(event?.genres).map(
                      (genre) => ` / ${getGenreName(genre, genres)}`,
                    )
                    : ''}
                </span>
              </p>
            </div>

            {event?.description
              ? <div className="description-block">
                <p>{parse(event.description)}</p></div>
              : ""}

            <div className="detail-info-event">
              {event?.age_id ? (
                <p>
                  <span>Política de ingreso:</span>
                  {' '}
                  {event?.age_id}
                </p>
              ) : (
                ''
              )}
              {event?.duration ? (
                <p>
                  <span>
                    Duración:
                  </span>
                  {' '}
                  {event?.duration}
                </p>
              ) : (
                ''
              )}
              {event?.email ? (
                <p>
                  <span>
                    Contacto:
                  </span>
                  {' '}
                  {event?.email}
                </p>
              ) : (
                ''
              )}
            </div>
          </div>

          <div
            className={openAboutEvent === true ? 'openInfo' : 'about-the-event'}
          >
            <div className="container-detail-info">
              <div className="detail-info-event">
                <p>
                  <span>
                    {eventBands?.map((band, index) => (
                      <>
                        {index > 0 ? ' / ' : ''}
                        <Link to={`/artista/${band?.link}`}>
                          {band.name}
                        </Link>
                      </>
                    ))}
                  </span>
                </p>
                {eventBands?.length > 0 ? eventBands?.map((band) => (
                  <>
                    {band.description ? (
                      <p>
                        {
                          eventBands?.length > 1 ? (
                            <span>
                              {band.name}
                              :
                              {' '}
                            </span>
                          ) : ''
                        }
                        {band?.description
                          ? <div className="description-block">
                            <p>{parse(band.description)}</p></div>
                          : ""}
                      </p>
                    ) : (
                      ''
                    )}
                    <div>
                      {band.facebook_link ? (
                        <a
                          href={band.facebook_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={FacebookIcon} alt="" />
                        </a>
                      ) : (
                        ''
                      )}
                      {band.instagram_link ? (
                        <a
                          href={band.instagram_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={InstagramIcon} alt="" />
                        </a>
                      ) : (
                        ''
                      )}
                      {band.spotify_link ? (
                        <a
                          href={band.spotify_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={SpotifyIcon} alt="" />
                        </a>
                      ) : (
                        ''
                      )}
                      {band.youtube_link ? (
                        <a
                          href={band.youtube_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={youtubeIcon} alt="" />
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                )) : ''}
              </div>
              {eventBands?.length > 0 ? (
                <div className="container-slider-event-detail">
                  <Slider {...imgEventDetail} className="slider-event-detail">
                    {eventBands.map((band) => (
                      band?.desktopFile ? (
                        <div>
                          <img
                            src={endpointCloudfront + band?.desktopFile.webp_url}
                            alt=""
                          />
                        </div>
                      ) : ''
                    ))}
                  </Slider>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <div
            className={
              openLocationEvent === true ? 'openInfo' : 'about-the-event'
            }
          >
            <div className="container-location-info">
              {location?.description
                ? <div className="description-block">
                  <p>{parse(location?.description)}</p></div>
                : ""}
            </div>
            <div className="container-slider-location-detail">
              <Slider {...imgLocationDetail} className="slider-location-detail">
                <div>
                  <img
                    src={endpointCloudfront + location?.desktopFileForLocation?.webp_url}
                    alt=""
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>

        {event?.eventPromotions?.length > 0 ? (
          <div className="information-promotions-event">
            <div className="sub-information-promotions-event">
              <div className="promotion-event">
                <h2>Beneficios</h2>

                <div className="container-promotions-event">
                  {event?.eventPromotions?.map((p, index) => {
                    const sponsor = getSponsor(p.sponsor_id, sponsors);
                    const promotion = getPromotion(p.sponsor_promotion_id, sponsors);
                    return (
                      <div className="img-promotion-event">
                        {sponsor.file?.webp_thumbnail_url ? (
                          <img
                            src={endpointCloudfront + sponsor.file?.webp_thumbnail_url}
                            alt="cocarda promotion"
                          />
                        ) : (
                          <h2>{sponsor.name}</h2>
                        )}
                        <div>
                          <h3>{promotion.name}</h3>
                          <p>{p.terms}</p>
                        </div>
                        <div className="img-promotion-event-open" style={{ display: showPromotionDetails === index ? 'flex' : 'none' }}>
                          <div>
                            <h3>{promotion.name}</h3>
                            <p>{p.terms}</p>
                          </div>
                        </div>
                        {p.terms ? (
                          <button type="button" onClick={() => { handlePromotionDetails(index); }}>Ver más</button>
                        ) : ''}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : ''}

        {event?.files.length > 0 ? (
          <div className="container-video-slider">
            {event?.files.map((file) => {
              if (file.file_type_id === '4') {
                return (
                  <iframe
                    src={`https://www.youtube.com/embed/${file.url}`}
                    width="100%"
                    height="80%"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen="100%"
                    title="video"
                  />
                );
              }
              return null;
            })}
          </div>
        ) : (
          ''
        )}

        {event?.files.length > 0 ? (
          <Slider {...imgSliderDetails} className="slider-img-event">
            {event?.files.map((file) => {
              if (file.file_type_id !== '4') {
                return (
                  <div>
                    <img
                      src={endpointCloudfront + file.webp_url}
                      alt=""
                    />
                  </div>
                );
              }
              return null;
            })}
          </Slider>
        ) : (
          ''
        )}

        <div className="map-where-go">
          {location ? (
            <>
              <span>¿Dónde voy?</span>
              <h2>{location?.name}</h2>
              <p>{location?.address}</p>
              {
                location?.longitude !== '""'
                  && location?.latitude !== '""'
                  && location?.longitude !== 'null'
                  && location?.latitude !== 'null' ? (
                  <div className="container-map-where-go">
                    <EventInfoMap
                      latitude={location?.latitude}
                      longitude={location?.longitude}
                      eventType={event?.event_type_id}
                      restaurants={filteredRestaurants}
                    />
                  </div>
                ) : (
                  ''
                )
              }
            </>
          ) : (
            ''
          )}
        </div>

        <div className="bannerIntendencia desktopElement">
          <button
            type="button"
            onClick={() => {
              setOpenLoginState(!openLogin);
              setShowedPopupState(true);
            }}
          >
            <Banner banner={BannerVoyRegistro} />
          </button>
        </div>

        {filteredEvents.length > 0 ? (
          <div className="container-events-recommended">
            <div className="title-recommended">También te recomendamos</div>
            <div className="sub-container-events-recommended">
              <Events
                type={parseFloat(event?.event_type_id)}
                subTitleTypeEvent={
                  event
                    ? JSON.parse(event?.genres).map(
                      (genre) => ` ${getGenreName(genre, genres)}`,
                    )
                    : ''
                }
                numberShow={4}
                modeCenter={false}
                filteredEvents={filteredEvents}
              />
            </div>
          </div>
        ) : ''}

        {filteredRestaurants.length > 0 ? (
          <div className="container-events-recommended">
            <div className="title-recommended">Restaurantes cercanos</div>
            <div className="sub-container-events-recommended">
              <Events
                locationsSlider="1"
                iconTitleTypeEvent={RestaurantsIcon}
                titleTypeEvent="Oferta Gastronómica"
                subTitleTypeEvent=""
                numberShow={4}
                modeCenter={false}
                filteredEvents={filteredRestaurants}
              />
            </div>
          </div>
        ) : ''}
        <Clients />
        <Footer />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  sponsors: state.app.sponsors,
  localities: state.app.localities,
  eventTypes: state.app.eventTypes,
  genres: state.app.genres,
  restaurants: state.app.restaurants,
  filters: state.app.filters,
  openLogin: state.app.openLogin,
});

export default connect(mapStateToProps, {
  setLoadingState: setLoading,
  setOpenLoginState: setOpenLogin,
  setShowedPopupState: setShowedPopup,
})(EventInformation);
