/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import {
  Routes, Route, Link, Outlet,
} from 'react-router-dom';
import '../../style/Login/login.css';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  setOpenLogin, setLoginToken, setLoginStage, setReferralLink, setShowedPopup, setLoading,
} from '../../state/AppState';
import Welcome from './components/Welcome';
import RegisterLogin from './components/registerLogin';
import RegisterDetail from './components/registerDetail';
import Soy from './components/soy';
import TypeEvent from './components/typeEvents';
import { showLoading } from '../../service';
import {
  CloseToggle, User,
  RegisterLoginImg,
  CopiarLogin,
  BienvenidoLoginImg,
} from '../../img';

import {
  endpointApi, endpointLogin1, endpointLogin2, endpointLogin3, endpointLogin4,
} from '../../endpoints';

function Login({
  openLogin, setOpenLoginState, loginStage, loginToken,
  setLoginStageState, setLoginTokenState, setLoadingState,
  setReferralLinkState, referredByCode, isLoading, showedPopup, setShowedPopupState,
}) {
  useEffect(() => {
    setLoginStageState(1);
  }, [openLogin]);

  const [isArtistProducer, setIsArtistProducer] = useState(false);

  useEffect(() => {
    // if (!isLoading) {
    //   setTimeout(() => {
    //     if (!showedPopup) {
    //       setOpenLoginState(true);
    //       setShowedPopupState(true);
    //     }
    //   }, 3000);
    // }
  }, [isLoading]);

  async function handleContinue(data = false) {
    // setLoadingState(true);
    // showLoading(15000, isLoading, setLoadingState);
    switch (loginStage) {
      case 1:
        try {
          const detailsData = {
            ...data,
            referral_code: referredByCode,
          };
          await axios.post(endpointLogin1, detailsData).then((res) => {
            setLoadingState(false);
            if (res.data.status === 'ok') {
              setLoginStageState(loginStage + 1);
              setLoginTokenState(res.data.token);
            } if (res.data.status === 'alreadyRegistered') {
              setLoginStageState(parseFloat(res.data.step) + 1);
              setReferralLinkState(res.data.link);
              setLoginTokenState(res.data.token);
              setIsArtistProducer(res.data.is_artist_producer);
            }
          }, (err) => err);
        } catch (error) {
          setLoadingState(false);
          return error;
        }
        break;
      case 2:
        try {
          const detailsData = {
            ...data,
            token: loginToken,
          };
          await axios.post(endpointLogin2, detailsData).then((res) => {
            setLoadingState(false);
            if (res.data.status === 'ok') {
              setLoginStageState(loginStage + 1);
            }
          }, (err) => err);
        } catch (error) {
          setLoadingState(false);
          return error;
        }
        break;
      case 3:
        try {
          const soyData = {
            is_fan: document.getElementById('loginIsFan').classList.contains('button-click') ? 1 : 0,
            is_artist: (document.getElementById('loginIsArtist').classList.contains('openInputSoyArtista') || document.getElementById('loginWhatICan').classList.contains('openInputHagoLoQuePuedo')) ? 1 : 0,
            band_name: document.getElementById('loginBandName').value,
            has_venue: document.getElementById('loginHasVenue').classList.contains('openInputSoyDueño') ? 1 : 0,
            venue_name: document.getElementById('loginVenueName').value,
            token: loginToken,
          };
          await axios.post(endpointLogin3, soyData).then((res) => {
            setLoadingState(false);
            if (res.data.status === 'ok') {
              setLoginStageState(loginStage + 1);
            }
          }, (err) => err);
        } catch (error) {
          setLoadingState(false);
          return error;
        }
        break;
      case 4:
        try {
          const interests = [];
          const types = document.querySelectorAll('.TypeEventLogin .TypeEventLogin .button-click');
          types.forEach((type, index) => {
            interests.push(type.getAttribute('data-id').toString());
          });
          const genres = document.querySelectorAll('.DetailTypeEventLogin .button-click');
          genres.forEach((genre, index) => {
            interests.push(genre.getAttribute('data-id').toString());
          });
          const newsletterData = {
            newsletter: document.getElementsByClassName('buttonNewsLetter')[0].classList.contains('buttonNewsLetter-click') ? 1 : 0,
            interests,
            token: loginToken,
          };
          await axios.post(endpointLogin4, newsletterData).then((res) => {
            setLoadingState(false);
            if (res.data.status === 'ok') {
              setLoginStageState(loginStage + 1);
              setReferralLinkState(res.data.link);
              setIsArtistProducer(res.data.is_artist_producer);
            }
          }, (err) => err);
        } catch (error) {
          setLoadingState(false);
          return error;
        }
        break;
      default:
        return null;
    }
    return true;
  }

  function renderSwitch() {
    switch (loginStage) {
      case 1:
        return <RegisterLogin handleContinue={handleContinue} />;
      case 2:
        return <RegisterDetail handleContinue={handleContinue} />;
      case 3:
        return <Soy handleContinue={handleContinue} />;
      case 4:
        return <TypeEvent handleContinue={handleContinue} />;
      case 5:
        return <Welcome />;
      default:
        return null;
    }
  }

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setOpenLoginState(!openLogin);
          setShowedPopupState(true);
        }}
        className={
          openLogin === true ? 'loginButton openLogin user' : 'loginButton user'
        }
      >
        <div className="desktopElement">
          Iniciar Sesión | Registrarme
        </div>
        <div className="mobileElement">
          <img src={User} alt="user icon" />
        </div>
      </button>
      <div className="loginBase">
        <div className="containerLoginBase">
          <div className="subContainerLoginBase">
            {loginStage !== 5 ? (<img src={RegisterLoginImg} alt="etiqueta" className="etiquetaLogin" />) : (<img src={BienvenidoLoginImg} alt="etiqueta" className="etiquetaLogin" />)}
            <button
              type="button"
              onClick={() => {
                setOpenLoginState(false);
              }}
              className="closeLogin"
            >
              <img src={CloseToggle} alt="" />
            </button>
            <div className="titleLoginRegister">
              {loginStage !== 5 ? (
                <>
                  <div>
                    <h1>
                      <span className="bold">Registrate</span>
                      , contanos sobre tus gustos e intereses y enterate de todos los espectáculos y eventos que
                      {' '}
                      <span className="bold">SON PARA VOS.</span>
                    </h1>
                  </div>
                  <br />
                  <div>
                    <h1>
                      Si sos
                      {' '}
                      <span className="bold">artista, productor o dueño de un local</span>
                      , difundí tus eventos a
                      {' '}
                      <span className="bold">MILES</span>
                      {' '}
                      de personas.
                    </h1>
                  </div>
                </>
              ) : (
                <div>
                  <h1>
                    Bienvenido a voy.
                  </h1>
                  <h4>
                    {!isArtistProducer ? 'Te damos la bienvenida a Voy' : 'Te enviamos un mail para que completes tu registro.'}
                    <br />
                    {!isArtistProducer ? 'Tu registro se completó exitosamente.' : 'Ayudanos a crecer compartiendo el siguiente link con tu familia y amigos.'}
                  </h4>
                </div>
              )}
              <div className="containerInformationLogin">
                {renderSwitch()}
              </div>
              { loginStage === 1 ? (
                <a className="apiLoginButton" href={endpointApi} target="_blank" rel="noreferrer">
                  Ya tengo cuenta
                </a>
              ) : ''}
              { loginStage === 5 && isArtistProducer ? (
                <a className="apiLoginButton" href={endpointApi} target="_blank" rel="noreferrer">
                  Iniciar sesión
                </a>
              ) : ''}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  openLogin: state.app.openLogin,
  loginStage: state.app.loginStage,
  loginToken: state.app.loginToken,
  referredByCode: state.app.referredByCode,
  showedPopup: state.app.showedPopup,
});
export default connect(mapStateToProps, {
  setOpenLoginState: setOpenLogin,
  setLoginTokenState: setLoginToken,
  setLoginStageState: setLoginStage,
  setReferralLinkState: setReferralLink,
  setShowedPopupState: setShowedPopup,
  setLoadingState: setLoading,
})(Login);
