/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react';
import '../../style/Filters/filters.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Select } from 'antd';
import ButtonSelected from '../Buttons/ButtonSelected';
import { setOpenFilters, setFilters } from '../../state/AppState';
import Calendar from '../Popups/Calendar';
import FilterSwitch from './FilterSwtich';
import { UbicationFilterWhere, IconFiltersMobile } from '../../img';
import {
  getEventTypeName,
  getLocationsForMarkers,
  getFilteredEvents,
  getSponsorsWithEvents,
  filtersWipe,
  normalizeString,
} from '../../service';

import LocationsSelect from './LocationsSelect';

const { Option } = Select;

function Filters({
  eventTypes,
  genres,
  sponsors,
  events,
  filters,
  locations,
  forRestaurants,
  sponsor,
  cities,
  localities,
  setFiltersState,
  openFilters,
  setOpenFiltersState,
}) {
  const [changeExpandedWhen, setChangeExpandedWhen] = useState(true);
  const [changeExpandedThat, setChangeExpandedThat] = useState(true);
  const [changeExpandedWhere, setChangeExpandedWhere] = useState(true);
  const [changeExpandedPrice, setChangeExpandedPrice] = useState(true);
  const [changeExpandedPromotions, setChangeExpandedPromotions] = useState(true);
  const [changeExpandedFeatured, setChangeExpandedFeatured] = useState(true);
  const width = window.innerWidth;
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [sponsorsWithEvents, setSponsorsWithEvents] = useState([]);
  const [currentLocations, setCurrentLocations] = useState([]);
  const closeAll = () => {
    setOpenFiltersState(false);
  };
  useEffect(() => {
    const asyncGet = async () => {
      const filteredEvents = await getFilteredEvents(
        filters,
        events,
        locations,
        localities,
        '',
        false,
        true,
      );
      setFilteredEvents(filteredEvents);
    };
    asyncGet();
  }, [filters]);

  useEffect(() => {
    setCurrentLocations(getLocationsForMarkers(filteredEvents, locations));
  }, [filteredEvents]);

  useEffect(() => {
    if (forRestaurants) {
      const newFilters = { ...filters };
      newFilters.location = [];
      setFiltersState(newFilters);
    }
  }, [forRestaurants]);

  useEffect(() => {
    if (sponsors) {
      setSponsorsWithEvents(getSponsorsWithEvents(sponsors));
    }
  }, [sponsors]);

  return (
    <>
      <div
        className={
          width < 600 && openFilters === true ? 'filters-mobile' : 'filters'
        }
      >
        <button
          onClick={() => {
            setOpenFiltersState(!openFilters);
          }}
          className={
            width < 600 && openFilters === true
              ? 'background-filters-open-mobile filter'
              : 'background-filters filter'
          }
          type="button"
        >
          <p className="title-filters">Filtrar</p>
          {' '}
          <img
            src={IconFiltersMobile}
            alt="icon filtros"
            className="mobileElement"
          />
        </button>

        <div className="clean-add-filters mobileElement">
          <button
            onClick={() => {
              filtersWipe(setFiltersState);
              closeAll();
            }}
            className="button-limpiar"
            type="button"
          >
            Quitar filtros
          </button>
          <button
            onClick={() => {
              closeAll();
            }}
            className="button-aplicar"
            type="button"
          >
            Aplicar filtros
          </button>
        </div>
        {!forRestaurants && (
          <Accordion
            expanded={width > 0 ? changeExpandedWhen : !changeExpandedWhen}
            className={
              width < 600 && openFilters === true
                ? 'background-filters-open-mobile when-filters'
                : 'background-filters when-filters'
            }
          >
            <AccordionSummary
              onClick={() => {
                setChangeExpandedWhen(!changeExpandedWhen);
              }}
              expandIcon={<ExpandMoreIcon />}
              id="panel1a-header"
            >
              <Typography className="category-title">¿Cuándo?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="container-type-event">
                  <ButtonSelected category="date" idButton="filtro-cuando-hoy" nameButton="Hoy" />
                  <ButtonSelected category="date" idButton="filtro-cuando-este-finde" nameButton="Este finde" />
                  <ButtonSelected category="date" idButton="filtro-cuando-esta-semana" nameButton="Esta semana" />
                  <ButtonSelected category="date" idButton="filtro-cuando-este-mes" nameButton="Este mes" />
                </div>
                <div className="container-type-event">
                  <Calendar label="Fechas" />
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}

        {!forRestaurants && (
          <Accordion
            expanded={width > 0 ? changeExpandedThat : !changeExpandedThat}
            className={
              width < 600 && openFilters === true
                ? 'background-filters-open-mobile what-filters'
                : 'background-filters what-filters'
            }
          >
            <AccordionSummary
              onClick={() => {
                setChangeExpandedThat(!changeExpandedThat);
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="category-title">¿Que?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="container-type-event">
                  {eventTypes?.map((type) => (
                    <ButtonSelected
                      key={`eventType${type.id}`}
                      category="type"
                      value={type.id}
                      idButton={normalizeString(`filtro-que-${type.name}`).replaceAll(' ', '-')}
                      nameButton={type.name}
                    />
                  ))}
                </div>
                <span className="category-title">
                  {filters.type?.length === 1
                    ? getEventTypeName(filters.type[0], eventTypes)
                      === 'Otros Eventos'
                      ? 'Tipo de Evento'
                      : 'Categorías'
                    : ''}
                </span>
                <div className="container-type-event type-genero">
                  { 
                  genres?.map((genre) => {
                    if (
                      filters.type?.length === 1
                      && parseFloat(genre.type_id) === filters.type[0]
                    ) {
                      const hasEventsForGenre = events.some((event) =>
                      event.genres.includes(genre.id.toString())
                      );
                      if (!hasEventsForGenre) {
                      return null;
                      }
                      const idButton = normalizeString(`filtro-genero-${getEventTypeName(genre.type_id, eventTypes)}-${genre.name}`).replaceAll(' ', '-');
                      return (
                        <ButtonSelected
                          key={`genre${genre.id}`}
                          category="genre"
                          value={genre.id}
                          idButton={idButton}
                          nameButton={genre.name}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion
          expanded={width > 0 ? changeExpandedWhere : !changeExpandedWhere}
          className={
            width < 600 && openFilters === true
              ? 'background-filters-open-mobile where-filters'
              : 'background-filters where-filters'
          }
        >
          <AccordionSummary
            onClick={() => {
              setChangeExpandedWhere(!changeExpandedWhere);
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="category-title">¿Dónde?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="container-type-event">
                <LocationsSelect
                  locations={forRestaurants ? [] : currentLocations}
                  localities={localities}
                  name="busquedamusica"
                  placeholder="Buscá un lugar..."
                  className="search-filters"
                />
                {!forRestaurants && (
                  <Link className="button-where" to="/view-map">
                    <img src={UbicationFilterWhere} alt="icon" />
                    <button className="viewMap-filters" type="button">
                      Ver en el mapa
                    </button>
                  </Link>
                )}
                {cities?.map((city) => (
                  <ButtonSelected
                    key={`city${city.id}`}
                    category="city"
                    value={city.id}
                    idButton={normalizeString(`filtro-ciudad-${city.name}`).replaceAll(' ', '-')}
                    nameButton={city.name}
                  />
                ))}
                <span className="mobileElement textDistance">
                  Distancias desde tu ubicación
                </span>
                <div className="mobileElement">
                  <ButtonSelected
                    category="distance"
                    value={1}
                    idButton="filtro-donde-1km"
                    nameButton="Menos de 1km"
                  />
                  <ButtonSelected
                    category="distance"
                    value={3}
                    idButton="filtro-donde-3km"
                    nameButton="Menos de 3km"
                  />
                  <ButtonSelected
                    category="distance"
                    value={5}
                    idButton="filtro-donde-5km"
                    nameButton="Menos de 5km"
                  />
                  <ButtonSelected
                    category="distance"
                    value={10}
                    idButton="filtro-donde-10km"
                    nameButton="Menos de 10km"
                  />
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {!forRestaurants && (
          <Accordion
            expanded={width > 0 ? changeExpandedPrice : !changeExpandedPrice}
            className={
              width < 600 && openFilters === true
                ? 'background-filters-open-mobile price-filters'
                : 'background-filters price-filters'
            }
          >
            <AccordionSummary
              onClick={() => {
                setChangeExpandedPrice(!changeExpandedPrice);
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="category-title">Precio</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="container-type-event">
                  <div className="checkboxFrom">
                    <FilterSwitch
                      category="price"
                      value="50 - 500"
                      titleCheckbox="De $50 a $500"
                    />
                    <FilterSwitch
                      category="price"
                      value="500 - 1000"
                      titleCheckbox="De $500 a $1.000"
                    />
                    <FilterSwitch
                      category="price"
                      value="> 1000"
                      titleCheckbox="Más de $1.000"
                    />
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}

        {/* {!forRestaurants && !sponsor && (
          <Accordion
            expanded={width > 0 ? changeExpandedPromotions : !changeExpandedPromotions}
            className={
              width < 600 && openFilters === true
                ? 'background-filters-open-mobile promotions-filters'
                : 'background-filters promotions-filters'
            }
          >
            <AccordionSummary
              onClick={() => {
                setChangeExpandedPromotions(!changeExpandedPromotions);
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="category-title">Promociones</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="container-type-event">
                  <div className="checkboxFrom">
                    {sponsorsWithEvents?.map((sponsor) => (
                      <FilterSwitch
                        category="sponsor"
                        value={sponsor.id}
                        titleCheckbox={sponsor.name}
                      />
                    ))}
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )} */}
      </div>
      <div className="mobileElement background-hide-mobile" />
    </>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  eventTypes: state.app.eventTypes,
  genres: state.app.genres,
  sponsors: state.app.sponsors,
  cities: state.app.cities,
  localities: state.app.localities,
  filters: state.app.filters,
  openFilters: state.app.openFilters,
});

export default connect(mapStateToProps, {
  setFiltersState: setFilters,
  setOpenFiltersState: setOpenFilters,
})(
  Filters,
);
