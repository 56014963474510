/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState, useEffect } from 'react';
import '../../style/Layouts/header.css';
import { useSearchParams, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTransition, animated, config as configs } from 'react-spring';
import axios from 'axios';

import { endpointSearchData } from '../../endpoints';
import Login from '../Login/Login';
import DailyPopup from '../Popups/DailyPopup';
import Survey from '../Popups/Survey';
import Events from '../Events/Events';
import {
  Logo, Start, Search, CloseToggle,
} from '../../img';
import {
  normalizeString,
  getLocation,
  getGenreName,
} from '../../service';

import ToggleMenu from '../Popups/ToggleMenu';
import SearchHeader from '../Popups/SearchHeader';

  <meta
    name="viewport"
    content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
  />;

function Header({
  events, locations, restaurants, bands, sponsors, eventTypes, bandTypes, genres,
}) {
  const [openSearch, setOpenSearch] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const minCharacters = 1;

  const location = useLocation();

  let eventsTitle = false;
  let locationsTitle = false;
  let bandsTitle = false;

  const ref = useRef(null);

  async function getResults() {
    const search = normalizeString(ref.current.value).replace(/(['"])/g, '');
    let resultEvents = [];
    let resultLocations = [];
    let resultBands = [];
    if (search.length > minCharacters) {
      const response = await axios.post(endpointSearchData, {
        searchString: search,
      });
      setSearchParams(`s=${search}`);
      resultEvents = [...events];
      resultEvents = resultEvents.filter((event) => {
        let found = false;
        const eventName = normalizeString(event.name).replace(/(['"])/g, '');
        const eventDescription = normalizeString(event.description).replace(/(['"])/g, '');
        if (eventName?.includes(search) || eventDescription?.includes(search)) {
          found = true;
        }
        if (search.length > 3) {
          const eventGenres = JSON.parse(event.genres);
          eventGenres.forEach((g) => {
            const genre = normalizeString(getGenreName(g, genres));
            if (genre?.includes(search)) {
              found = true;
            }
          });
        }
        if (event.eventBands.length > 0) {
          event.eventBands?.forEach((eventBand) => {
            const bandName = normalizeString(eventBand?.bandsName?.name).replace(/(['"])/g, '');
            if (bandName?.includes(search)) {
              found = true;
            }
          });
        }
        if (event.eventFunctions.length > 0) {
          event.eventFunctions.forEach((eventFunction) => {
            const location = getLocation(eventFunction.location_id, locations);
            const locationName = normalizeString(location?.name).replace(/(['"])/g, '');
            if (locationName?.includes(search)) {
              found = true;
            }
          });
        }
        return found;
      });
      resultEvents = resultEvents.sort((a, b) => {
        var eventNameA = normalizeString(a.name).replace(/(['"])/g, '');
        var eventNameB = normalizeString(b.name).replace(/(['"])/g, '');
        var searchInNameA = eventNameA?.includes(search);
        var searchInNameB = eventNameB?.includes(search);
        
        if (searchInNameA && !searchInNameB)
          return -1;
        else if (!searchInNameA && searchInNameB)
          return 1;
        else
          return b.relevance - a.relevance
      });
      resultBands = response.data.bands;
      resultLocations = response.data.locations;
    } else {
      document.getElementsByClassName('search')[0].classList.remove('showingResults');
      document.getElementsByClassName('closeSearchButton')[0].classList.remove('showingResults');
      resultEvents = [];
      resultLocations = [];
      resultBands = [];
    }

    if (search.length <= minCharacters) {
      resultEvents = [];
      resultLocations = [];
      resultBands = [];
    }

    const result = [];
    result.push(resultEvents);
    result.push(resultBands);
    result.push(resultLocations);
    if (ref?.current?.value.length > minCharacters
      && result[0]?.length === 0
      && result[1]?.length === 0
      && result[2]?.length === 0) {
      setSearchResults(null);
    } else {
      setSearchResults([resultEvents, resultBands, resultLocations]);
    }
  }

  /* Intento de optimización buscador por principio de cada palabra */

  /* async function getResults() {
    const search = normalizeString(ref.current.value).replace(/(['"])/g, '');
    let resultEvents = [];
    let resultLocations = [];
    let resultBands = [];
    if (search.length > minCharacters) {
      const response = await axios.post(endpointSearchData, {
        searchString: search,
      });
      setSearchParams(`s=${search}`);
      resultEvents = [...events];
      resultEvents = resultEvents.filter((event) => {
        let found = false;
        let relevance2 = 0;
        const eventName = normalizeString(event.name).replace(/(['"])/g, '');
        const words = eventName.split(' ');

        if (eventName.toLowerCase().startsWith(search.toLowerCase())) {
          found = true;
          relevance2 += 3;
        } else if (words.length > 1 && !found) {
          words.forEach((word) => {
            if (word.toLowerCase().startsWith(search.toLowerCase())) {
              found = true;
              relevance2 += 2;
            }
          });
        }
        if (search.length > 3) {
          const eventGenres = JSON.parse(event.genres);
          eventGenres.forEach((g) => {
            const genre = normalizeString(getGenreName(g, genres));
            if (genre.toLowerCase().startsWith(search.toLowerCase())) {
              found = true;
              relevance2++;
            }
          });
        }
        if (event.eventBands.length > 0) {
          event.eventBands?.forEach((eventBand) => {
            const bandName = normalizeString(eventBand?.bandsName?.name).replace(/(['"])/g, '');
            const words = bandName.split(' ');

            if (bandName.toLowerCase().startsWith(search.toLowerCase())) {
              found = true;
              relevance2 += 2;
            } else if (words.length > 1 && !found) {
              words.forEach((word) => {
                if (word.toLowerCase().startsWith(search.toLowerCase())) {
                  found = true;
                  relevance2 += 1;
                }
              });
            }
          });
        }
        if (event.eventFunctions.length > 0) {
          event.eventFunctions.forEach((eventFunction) => {
            const location = getLocation(eventFunction.location_id, locations);
            const locationName = normalizeString(location?.name).replace(/(['"])/g, '');
            const words = locationName.split(' ');

            if (locationName.toLowerCase().startsWith(search.toLowerCase())) {
              found = true;
              relevance2 += 2;
            } else if (words.length > 1 && !found) {
              words.forEach((word) => {
                if (word.toLowerCase().startsWith(search.toLowerCase())) {
                  found = true;
                  relevance2 += 1;
                }
              });
            }
          });
        }
        return found;
      });

      resultEvents = resultEvents.sort((a, b) => {
        if (a.relevance !== b.relevance) {
          return b.relevance - a.relevance; // Ordenamos por relevance de forma descendente
        }
        return b.relevance2 - a.relevance2; // Ordenamos por relevance2 de forma descendente
      });

      resultBands = response.data.bands.filter((band) => {
        let found = false;

        const bandName = normalizeString(band.name).replace(/(['"])/g, '');
        const bandNameWords = bandName.split(' ');

        if (bandName.toLowerCase().startsWith(search.toLowerCase())) {
          found = true;
        } else if (bandNameWords.length > 1) {
          bandNameWords.forEach((word) => {
            if (word.toLowerCase().startsWith(search.toLowerCase())) {
              found = true;
            }
          });
        }
        return found;
      });

      // Ordenar las bandas, dándole más prioridad a las que tienen coincidencia en la primera palabra
      resultBands.sort((a, b) => {
        const aFirstWord = normalizeString(a.name).replace(/(['"])/g, '').split(' ')[0];
        const bFirstWord = normalizeString(b.name).replace(/(['"])/g, '').split(' ')[0];

        if (aFirstWord.startsWith(search) && !bFirstWord.startsWith(search)) {
          return -1; // Si a tiene coincidencia en la primera palabra y b no, a va antes que b
        } if (!aFirstWord.startsWith(search) && bFirstWord.startsWith(search)) {
          return 1; // Si b tiene coincidencia en la primera palabra y a no, b va antes que a
        }
        return 0; // Ambos tienen coincidencia en la primera palabra o ninguno tiene coincidencia en la primera palabra
      });

      resultLocations = response.data.locations.filter((location) => {
        let found = false;

        const locationName = normalizeString(location.name).replace(/(['"])/g, '');
        const locationNameWords = locationName.split(' ');

        if (locationName.toLowerCase().startsWith(search.toLowerCase())) {
          found = true;
        } else if (locationNameWords.length > 1) {
          locationNameWords.forEach((word) => {
            if (word.toLowerCase().startsWith(search.toLowerCase())) {
              found = true;
            }
          });
        }
        return found;
      });

      resultLocations.sort((a, b) => {
        const aFirstWord = normalizeString(a.name).replace(/(['"])/g, '').split(' ')[0];
        const bFirstWord = normalizeString(b.name).replace(/(['"])/g, '').split(' ')[0];

        if (aFirstWord.startsWith(search) && !bFirstWord.startsWith(search)) {
          return -1; // Si a tiene coincidencia en la primera palabra y b no, a va antes que b
        } if (!aFirstWord.startsWith(search) && bFirstWord.startsWith(search)) {
          return 1; // Si b tiene coincidencia en la primera palabra y a no, b va antes que a
        }
        return 0; // Ambos tienen coincidencia en la primera palabra o ninguno tiene coincidencia en la primera palabra
      });
    } else {
      document.getElementsByClassName('search')[0].classList.remove('showingResults');
      document.getElementsByClassName('closeSearchButton')[0].classList.remove('showingResults');
      resultEvents = [];
      resultLocations = [];
      resultBands = [];
    }

    if (search.length <= minCharacters) {
      resultEvents = [];
      resultLocations = [];
      resultBands = [];
    }

    const result = [];
    result.push(resultEvents);
    result.push(resultBands);
    result.push(resultLocations);
    if (ref?.current?.value.length > minCharacters
      && result[0]?.length === 0
      && result[1]?.length === 0
      && result[2]?.length === 0) {
      setSearchResults(null);
    } else {
      setSearchResults([resultEvents, resultBands, resultLocations]);
    }
  } */

  useEffect(() => {
    if (searchString.length > minCharacters) {
      document.getElementsByClassName('search')[0].classList.add('showingResults');
      document.getElementsByClassName('closeSearchButton')[0].classList.add('showingResults');
    }
    document.querySelector('body').scrollIntoView({ behavior: 'smooth' });
    const delayDebounceFn = setTimeout(() => {
      getResults();
    }, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [searchString]);

  function closeSearch() {
    setTimeout(() => {
      ref.current.blur();
      ref.current.value = '';
      getResults();
    }, 600);
    document.getElementsByTagName('body')[0].classList.remove('noScroll');
    setOpenSearch(false);
  }

  useEffect(() => {
    closeSearch();
  }, [location.pathname]);

  const slidersTrail = useTransition(searchResults, {
    from: { opacity: 0, x: 100 },
    enter: { opacity: 1, x: 0 },
    // leave: { opacity: 0, scale: 0.5 },
    trail: 325,
    config: configs.slow,
  });

  function handleClick(e) {
    // console.log(e.target);
    if (!openSearch) {
      document.getElementsByTagName('body')[0].classList.add('noScroll');
      setTimeout(() => {
        ref?.current?.focus();
      }, 250);
      setOpenSearch(!openSearch);
    } else if (e.target.classList.contains('container-input-search') || e.target.classList.contains('event') || e.target.classList.contains('eventSlider')) {
      closeSearch();
    }
  }

  return (
    <>
      <header>
        <div className="background-escondido" />
        <div className="container-header">
          <Link to="/">
            <img className="logo" src={Logo} alt="Logo de voy." />
          </Link>
          <SearchHeader className="desktopElement" eventTypes={eventTypes} />
          <div className="sub-container-header">
            <ToggleMenu />
            <button
              onClick={() => {
                handleClick();
              }}
              id="search-button"
              className={
                openSearch === true
                  ? 'searchMobileHeader searchHeaderIcon searchMobileOpen'
                  : 'searchMobileHeader searchHeaderIcon'
              }
              type="button"
            >
              <img src={Search} alt="icon search" />
            </button>
            <a href="." className="start desktopElement">
              {' '}
              <img src={Start} />
              {' '}
            </a>
            <Login />
            <DailyPopup />
            <Survey />
          </div>
        </div>
      </header>
      <div className="headerDummy" />
      <div className={`container-input-search${openSearch ? ' open' : ''}`} onClick={handleClick}>
        {/* <button
                onClick={() => {
                  handleClick();
                }}
                className={
                  openSearch === true
                    ? 'searchMobileHeader'
                    : 'searchMobileHeader'
                }
                type="button"
              >
                <img src={CloseToggle} alt="Icon Close Toggle" />
              </button> */}
        <input
          ref={ref}
          onKeyUp={() => setSearchString(ref?.current?.value)}
          type="search"
          placeholder="Artistas, eventos, escenarios..."
          className="search"
        />
        <button className="closeSearchButton" type="button" onClick={() => closeSearch()}>
          <img src={CloseToggle} />
        </button>
        <div className="searchResults container-event-type-view">
          <div className="subContainer-searchResults container-event-type-view">
            {/* { console.log(searchResults) } */}

            {searchResults ? slidersTrail((style, item, key, index) => {
              let element = '';

              let title = '';

              if (item[0]?.event_type_id) {
                if (!eventsTitle) {
                  title = <p>Eventos</p>;
                  eventsTitle = true;
                }
                element = (
                  <Events
                    noLazyLoad
                    titleTypeEvent="Eventos"
                    searchSlider
                    modeCenter
                    filteredEvents={item}
                  />
                );
              } else if (item[0]?.address) {
                if (!locationsTitle) {
                  title = <p>Locaciones</p>;
                  locationsTitle = true;
                }
                element = (
                  <Events
                    noLazyLoad
                    titleTypeEvent="Locaciones"
                    locationsSlider="1"
                    searchSlider
                    modeCenter
                    filteredEvents={item}
                  />
                );
              } else if (item[0]?.band_types) {
                if (!bandsTitle) {
                  title = <p>Artistas</p>;
                  bandsTitle = true;
                }
                element = (
                  <Events
                    noLazyLoad
                    titleTypeEvent="Artistas"
                    bandsSlider="1"
                    searchSlider
                    modeCenter
                    filteredEvents={item}
                  />
                );
              }

              return (
                <>
                  {/* {title} */}
                  <animated.div
                    key={item.id}
                    style={index < 9 ? { ...style } : null}
                  >
                    {element}
                  </animated.div>
                </>
              );
            }) : (<div className="noResults"><p>No se encontraron resultados</p></div>)}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  restaurants: state.app.restaurants,
  eventTypes: state.app.eventTypes,
  bandTypes: state.app.bandTypes,
  sponsors: state.app.sponsors,
  filters: state.app.filters,
  genres: state.app.genres,
});

export default connect(mapStateToProps, null)(Header);
