/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import '../../../style/Login/login.css';
import { connect } from 'react-redux';
import { FacebookIcon, GoogleIcon, ClickLoginNewsLetter } from '../../../img';
import ButtonSelected from '../../Buttons/ButtonSelected';

function TypeEvent({ handleContinue, interests }) {
  const [newsLetter, setNewsLetter] = useState(false);

  return (
    <div className="typeEventLogin">
      <div className="newsLetter">
        <button
          type="button"
          onClick={() => {
            setNewsLetter(!newsLetter);
          }}
          className={
            newsLetter === true ? 'buttonNewsLetter' : 'buttonNewsLetter-click buttonNewsLetter'
        }
        >
          Avísenme de eventos que me puedan interesar
          {' '}
          <img src={ClickLoginNewsLetter} alt="" />
        </button>
        <span>*No abusaremos del envío de novedades, la desuscripción estará a un solo click en tu casilla.</span>
      </div>
      <div className="selectTypeEventLogin">
        <h3>Seleccioná sobre qué tipo de evento te gustaría recibir novedades.</h3>
      </div>
      <div className="TypeEventLogin">
        {interests?.topInterests?.map((interest) => (
          <ButtonSelected
            dataId={interest.id}
            nameButton={interest.name}
          />
        ))}
      </div>
      <div className="DetailTypeEventLogin">
        {interests?.bottomInterests?.map((interest) => (
          <ButtonSelected
            dataId={interest.id}
            nameButton={interest.name}
          />
        ))}
      </div>
      <button onClick={handleContinue} type="button" className="continueLoginButton">
        Finalizar
      </button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  interests: state.app.interests,
});
export default connect(mapStateToProps, null)(TypeEvent);
