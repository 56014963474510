/* eslint-disable linebreak-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { React, useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getBandTypesName,
  normalizeString,
  getLocation,
  getSponsor,
  getDate,
  getTime,
  getSponsorsWithRestaurants,
  applyTypeFilter,
  applyGenreFilter,
  getEventTypeName,
  getGenreName,
  getFirstGenreName,
  getEventTypeIcon,
} from '../../service';
import {
  BandIcon,
  LocationIcon,
  ArrowSliderLeft,
  ArrowSliderRight,
  RestaurantsIcon,
} from '../../img';
import Band from './Band';

import { setFilters } from '../../state/AppState';
import Event from './Event';
import Location from './Location';
import '../../style/Events/events.css';
import { endpointCloudfront } from '../../endpoints';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="arrowSliderRight" type="button">
      <img src={ArrowSliderRight} alt="" />
    </button>
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="arrowSliderLeft" type="button">
      <img src={ArrowSliderLeft} alt="" />
    </button>
  );
}

function Events({
  filters,
  locationsSlider,
  bandsSlider,
  multipleSponsors,
  filteredEvents,
  linkToLanding,
  locations,
  sponsors,
  sponsor,
  type,
  genre,
  numberShow,
  iconTitleTypeEvent,
  titleTypeEvent,
  subTitleTypeEvent,
  setFiltersState,
  numberScroll,
  sponsorsForRestaurants,
  setSponsorsForRestaurants,
  carnaval,
  genres,
  eventTypes,
  bandTypes,
  noLazyLoad,
  searchSlider,
}) {
  const settings = {
    infinite: true,
    slidesToShow: filteredEvents?.length < 4 ? filteredEvents?.length : numberShow,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    slidesToScroll: filteredEvents?.length < 4 ? filteredEvents?.length : numberScroll,
    centerPadding: '60px',
    centerMode: false,
    focusOnSelect: true,
    swipeToSlide: true,
    lazyLoad: !noLazyLoad ? 'anticipated' : 'anticipated',
    dots: false,
    autoplay: false,
    useTransform: false,
    speed: 1000,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: searchSlider ? (filteredEvents?.length < 6 ? filteredEvents?.length : 6) : (filteredEvents?.length < 4 ? filteredEvents?.length : 4),
          slidesToScroll: searchSlider ? (filteredEvents?.length < 6 ? filteredEvents?.length : 6) : (filteredEvents?.length < 4 ? filteredEvents?.length : 4),
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: searchSlider ? (filteredEvents?.length < 5 ? filteredEvents?.length : 5) : (filteredEvents?.length < 3 ? filteredEvents?.length : 3),
          slidesToScroll: searchSlider ? (filteredEvents?.length < 5 ? filteredEvents?.length : 5) : (filteredEvents?.length < 3 ? filteredEvents?.length : 3),
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 825,
        settings: {
          slidesToShow: 2,
          // slidesToScroll: 1,
          centerMode: true,
          centerPadding: '30px',
          infinite: true,
          nextArrow: false,
          prevArrow: false,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          className: 'center',
          centerMode: true,
          infinite: true,
          centerPadding: '30px',
          slidesToShow: 1,
          speed: 500,
          autoplay: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  };

  function handleSponsorClick(id) {
    if (sponsorsForRestaurants.includes(id)) {
      setSponsorsForRestaurants([]);
    } else {
      setSponsorsForRestaurants([id]);
    }
  }

  const [imgLoading, setImgLoading] = useState(true);

  function onLoad() {
    setImgLoading(false);
  }

  const idLink = !multipleSponsors && !linkToLanding ? (!carnaval ? normalizeString(`filtro-que-${getEventTypeName(type, eventTypes)}`).replaceAll(' ', '-') : normalizeString(`filtro-genero-${getEventTypeName(type, eventTypes)}-${getGenreName(genre, genres)}`).replaceAll(' ', '-')) : '';

  let element = '';

  if (locationsSlider !== '1' && bandsSlider !== '1') {
    element = (
      filteredEvents?.map((item) => {
        const location = getLocation(
          item.eventFunctions[0].location_id,
          locations,
        );
        const eventPromotions = sponsor
          ? item.eventPromotions?.filter((promo) => {
            if (promo.sponsor_id === sponsor.id) {
              return (
                promo
              );
            }
            return false;
          }) : (item.eventPromotions);
        return (
          <Event
            className="eventSlider"
            key={`event${item.id}`}
            ImgEventDesktop={endpointCloudfront + item.desktopFile?.webp_thumbnail_url}
            ImgEventMobile={endpointCloudfront + item.desktopFile?.webp_thumbnail_url}
            iconEvent={getEventTypeIcon(item.event_type_id, eventTypes)}
            iconEventInfo="Icon Music"
            typeEvent={getEventTypeName(item.event_type_id, eventTypes)}
            otherEventsTypeEvent={item.event_type_id === '4' ? getFirstGenreName(item.genres, genres) : ''}
            idEvent={item.id}
            link={item.link}
            titleEvent={item.name}
            hourEvent={`${getDate(item.eventFunctions[0].date)}, ${getTime(item.eventFunctions[0].start_time)}
            ${item.eventFunctions.length > 1 ? ` - ${getDate(item.eventFunctions[item.eventFunctions.length - 1].date)}, 
            ${getTime(item.eventFunctions[item.eventFunctions.length - 1].start_time)}` : ''}`}
            locationEvent={location?.name}
            cocardaBank={
              sponsors
                ? getSponsor(
                  eventPromotions[0]?.sponsor_id,
                  sponsors,
                ).file?.webp_thumbnail_url
                : ''
            }
          />
        );
      }));
  } else if (locationsSlider === '1') {
    element = (
      filteredEvents?.map((item) => (
        <Location
          className="eventSlider"
          key={`location${item.id}`}
          ImgEventDesktop={endpointCloudfront + item.desktopFile?.webp_thumbnail_url}
          ImgEventMobile={endpointCloudfront + item.desktopFile?.webp_thumbnail_url}
          iconEvent={item.is_restaurant === '1' ? RestaurantsIcon : LocationIcon}
          iconEventInfo="Icon Music"
          typeEvent={item.is_restaurant === '1' ? 'Oferta Gastronómica' : 'Locación'}
          idEvent={item.id}
          link={item.link}
          titleEvent={item.name}
          locationEvent={item.address}
          cocardaBank={
            sponsors
              ? getSponsor(
                item.locationPromotions[0]?.sponsor_id,
                sponsors,
              ).file?.webp_thumbnail_url
              : ''
          }
        />
      ))
    );
  } else {
    element = (
      filteredEvents?.map((item) => (
        <Band
          key={item.id}
          ImgEventDesktop={endpointCloudfront + item.desktopFileForCard?.webp_thumbnail_url}
          ImgEventMobile={endpointCloudfront + item.desktopFileForCard?.webp_thumbnail_url}
          iconEvent={BandIcon}
          iconEventInfo="Icon Music"
          typeEvent="Artista"
          bandTypes={JSON.parse(item?.band_types).map((bandType, index) => `${(index > 0 ? ' / ' : '') + getBandTypesName(bandType, bandTypes)}`)}
          idEvent={item.id}
          link={item.link}
          titleEvent={item.name}
          className="event eventButton"
        />
      ))
    );
  }

  return (
    filteredEvents?.length > 0 ? (
      <div className="containerEvents">
        <div className="background-hide desktopElement" />
        <div className="containerTitleTypeEvent">
          <Link
            id={idLink}
            className="SubContainerTitleTypeEvent"
            onClick={() => {
              if (!linkToLanding && !multipleSponsors) {
                if (!carnaval) {
                  applyTypeFilter(type, setFiltersState);
                }
                if (carnaval) {
                  applyGenreFilter(genre, type, setFiltersState);
                }
              }
            }}
            to={!multipleSponsors && !bandsSlider && !locationsSlider && !searchSlider ? `/ver-eventos${linkToLanding ? `/${linkToLanding}` : ''}` : ''}
          >
            <img
              src={iconTitleTypeEvent}
              style={{ display: imgLoading ? 'none' : 'block' }}
              onLoad={onLoad}
              alt="Icon Event"
            />
            <div className="titleTypeEvent">{titleTypeEvent}</div>
            {subTitleTypeEvent ? (<div className="lineTypeEvent" />) : ''}
            <div className="subTitleTypeEvent">{subTitleTypeEvent}</div>
          </Link>
          {multipleSponsors && sponsors ? (
            <div className="sliderSponsors">
              {getSponsorsWithRestaurants(sponsors).map((sponsor) => {
                if (sponsor.has_landing === '1') {
                  return (
                    <button
                      key={`sponsor${sponsor.id}`}
                      type="button"
                      onClick={() => handleSponsorClick(parseFloat(sponsor.id))}
                      className={sponsorsForRestaurants.length === 0 || sponsorsForRestaurants.includes(parseFloat(sponsor.id)) ? `sponsorButton sponsor${sponsor.name.replace( / /g,"")} selected` : `sponsorButton sponsor${sponsor.name}`}
                      data-id={sponsor.id}
                    >
                      {sponsor.name}
                      </button>
                  );
                }
                return false;
              })}
            </div>
          ) : ''}
          {!multipleSponsors && !bandsSlider && !locationsSlider && !searchSlider ? (
            <Link
              id={idLink}
              className="viewMore"
              type="button"
              onClick={() => {
                if (!linkToLanding && !multipleSponsors) {
                  if (!carnaval) {
                    applyTypeFilter(type, setFiltersState);
                  }
                  if (carnaval) {
                    applyGenreFilter(genre, type, setFiltersState);
                  }
                }
              }}
              to={!multipleSponsors ? `/ver-eventos${linkToLanding ? `/${linkToLanding}` : ''}` : ''}
            >
              {' '}
              ver todo
            </Link>
          ) : ''}
        </div>
        <div
          className={
            filteredEvents?.length > 0 ? 'sliderEvents' : 'sliderEvents noSlider'
          }
        >
          {filteredEvents?.length > 0 ? (
            <Slider {...settings}>
              {element}
            </Slider>
          ) : (
            { element }
          )}
        </div>
      </div >
    ) : ''
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  eventTypes: state.app.eventTypes,
  bandTypes: state.app.bandTypes,
  sponsors: state.app.sponsors,
  filters: state.app.filters,
  genres: state.app.genres,
});
export default connect(mapStateToProps, {
  setFiltersState: setFilters,
})(Events);
