/* eslint-disable linebreak-style */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setFilters } from '../../state/AppState';
import {
  Maldonado, Rocha, Canelones, Colonia,
} from '../../img';
import '../../style/Buttons/buttonSelected.css';

function ButtonSelected(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [openButton, setOpenButton] = useState(false);

  const checkState = () => {
    switch (props.category) {
      case 'date':
        if (props.filters.date?.includes(props.nameButton)) {
          setOpenButton(true);
        } else {
          setOpenButton(false);
        }
        break;
      case 'type':
        if (props.filters.type?.includes(props.value)) {
          setOpenButton(true);
        } else {
          setOpenButton(false);
        }
        break;
      case 'genre':
        if (props.filters.genre?.includes(props.value)) {
          setOpenButton(true);
        } else {
          setOpenButton(false);
        }
        break;
      case 'distance':
        if (props.filters.distance?.includes(props.value)) {
          setOpenButton(true);
        } else {
          setOpenButton(false);
        }
        break;
      case 'city':
        if (props.filters.city?.includes(props.value)) {
          setOpenButton(true);
        } else {
          setOpenButton(false);
        }
        break;
      default:
        return null;
    }
    return null;
  };

  useEffect(() => {
    checkState();
  }, [props.filters]);

  const applyFilters = () => {
    const newFilters = { ...props.filters };
    if (openButton) {
      let index = '';
      switch (props.category) {
        case 'date':
          index = newFilters.date?.indexOf(props.nameButton);
          newFilters.date?.splice(index, 1);
          break;
        case 'type':
          index = newFilters.type?.indexOf(props.value);
          newFilters.type?.splice(index, 1);
          newFilters.genre = [];
          break;
        case 'genre':
          index = newFilters.genre?.indexOf(props.value);
          newFilters.genre?.splice(index, 1);
          break;
        case 'distance':
          index = newFilters.distance?.indexOf(props.value);
          newFilters.distance?.splice(index, 1);
          newFilters.location = [];
          newFilters.city = [];
          newFilters.neighbourhood = [];
          break;
        case 'city':
          newFilters.city = [];
          newFilters.location = [];
          newFilters.distance = [];
          newFilters.neighbourhood = [];
          break;
        default:
          return null;
      }
    } else {
      switch (props.category) {
        case 'date':
          if (props.filters.date?.includes('range')) {
            newFilters.date = [];
          }
          newFilters.date?.push(props.nameButton);
          break;
        case 'type':
          newFilters.type?.push(props.value);
          newFilters.genre = [];
          break;
        case 'genre':
          newFilters.genre?.push(props.value);
          break;
        case 'distance':
          newFilters.distance?.push(props.value);
          newFilters.location = [];
          newFilters.city = [];
          newFilters.neighbourhood = [];
          break;
        case 'city':
          newFilters.city = [];
          newFilters.city?.push(props.value);
          newFilters.location = [];
          newFilters.distance = [];
          newFilters.neighbourhood = [];
          break;
        default:
          return null;
      }
    }
    props.setFiltersState(newFilters);
    if (!openButton) {
      const view = location.pathname.split('/')[1];
      if (view !== 'ver-eventos' && view !== 'view-map') {
        navigate('/ver-eventos');
      }
    }
  };

  const cityImages = {
    Maldonado,
    Rocha,
    Canelones,
    Colonia,
  };

  return (
    <button
      onClick={() => {
        setOpenButton(!openButton);
        applyFilters();
      }}
      id={props.idButton || ''}
      data-id={props.dataId || ''}
      className={openButton === true ? 'button-click' : 'button'}
      type="button"
    >
      {props.image ? (<img src={cityImages[props.image]} alt={props.image} />) : ''}
      {props.nameButton}
    </button>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  eventTypes: state.app.eventTypes,
  filters: state.app.filters,
});

export default connect(mapStateToProps, {
  setFiltersState: setFilters,
})(ButtonSelected);
