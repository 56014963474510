/* eslint-disable linebreak-style */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import '../../style/Map/marker.css';
import Pin from '../../img/pin.svg';
import TheatrePin from '../../img/theatre-pin.svg';
import CinemaPin from '../../img/cinema-pin.svg';
import FiestasPin from '../../img/fiestas-pin.svg';
import MusicPin from '../../img/music-pin.svg';
import OtherPin from '../../img/other-pin.svg';
import CarnavalPin from '../../img/carnaval-pin.svg';
import LocationPin from '../../img/location-pin.svg';
import MyLocationPin from '../../img/my-location.svg';

function LocationMarker({
  selectedLocation, location, onClick, eventTypes,
}) {
  let eventPin = null;

  // todo: por ahora me quedo con el primero, esto deberia cambiar

  switch (parseInt(eventTypes[0])) {
    case 0:
      eventPin = MyLocationPin;
      break;
    case 1:
      eventPin = MusicPin;
      break;
    case 2:
      eventPin = CinemaPin;
      break;
    case 3:
      eventPin = TheatrePin;
      break;
    case 4:
      eventPin = OtherPin;
      break;
    case 5:
      eventPin = CarnavalPin;
      break;
    case 6:
      eventPin = FiestasPin;
      break;
    default:
      eventPin = OtherPin;
      break;
  }

  return (
    <div
      className={
        selectedLocation != null
        && selectedLocation.location.id == location.location?.id
          ? 'location-marker selected'
          : 'location-marker'
      }
    >
      <img src={eventPin} alt="pin" onClick={() => onClick(location)} />
    </div>
  );
}

export default LocationMarker;
