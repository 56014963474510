/* eslint-disable linebreak-style */
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import PageContainer from './PageContainer';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <PageContainer />
      </PersistGate>
    </Provider>
  );
}
export default App;
