/* eslint-disable linebreak-style */
/* eslint-disable react/no-unescaped-entities */
import { React, useEffect } from 'react';
import '../../style/Site/terms.css';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import Clients from '../Advertisments/Clients';
import { BlurPruple, BlurGreen } from '../../img';

function TermsDraw() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className="terms">
      <Header />
      <img src={BlurPruple} alt="" className="blur-purple blur" />
      <img src={BlurGreen} alt="" className="blur-green blur" />
      <div className="container-terms">
        <h1>
          Bases y condiciones para el sorteo "Registrate en VOY"
        </h1>
        <h2>I. EL SORTEO:</h2>
        <p>
          Pepe Paquetes S.A. (en adelante “VOY”) persona jurídica regularmente constituida inscripta en el R.U.T. con el número 218037010013, realizará un sorteo de acuerdo a los términos y condiciones establecidos en este documento.
        </p>
        <p>
          El sorteo denominado “Regístrate en VOY” (en adelante “el sorteo”), es de carácter nacional.  El plazo para registrarse a efectos de participar en el sorteo será hasta el 15 de octubre de 2022 a las 23:59 hs. 	La participación en el sorteo es libre y sin obligación de compra.
        </p>
        <h2>II. ¿QUIÉNES PARTICIPAN?:</h2>
        <p>
          Podrán participar en el sorteo todas aquellas personas uruguayas, mayores de edad y residentes en el Uruguay.
        </p>
        <p>
          No podrán participar en el sorteo los empleados, proveedores, asesores y colaboradores eventuales y permanentes de 5M Travel Group, ni VOY ni de la agencia de publicidad contratada, ni sus parientes por consanguinidad o afinidad hasta el segundo grado inclusive.
        </p>
        <p>
          En caso que el ganador no cumpla con alguno de los requisitos establecidos en estas bases y condiciones, perderá el derecho a recibir el premio y se procederá a seleccionar un nuevo ganador en las mismas condiciones, sin derecho a reclamación de especie alguna.
        </p>
        <h2>III. ¿CÓMO PARTICIPAR?:</h2>
        <p>
          Las personas que quieran participar en el sorteo deberán registrarse a tales efectos en la página web voy.com.uy ingresando su mail, y deberán aceptar las presentes bases y condiciones.
        </p>
        <h2>IV. El PREMIO:</h2>
        <p>
          El ganador tendrá derecho al siguiente premio: 2 boletos aéreos en clase turista (no incluyendo equipaje en bodega),
          {' '}
          <b style={{ color: '#47FFBD' }}>más una estadía de mínimo 5 días y máximo 8 días</b>
          {' '}
          en crucero para dos personas en cabina de lujo, con destino a definir por la empresa VOY, de acuerdo al momento de viaje y de emisión del premio,
          {' '}
          <b style={{ color: '#47FFBD' }}>dependiendo también de la disponibilidad en los itinerarios realizados por la Naviera</b>
          . La fecha de viaje será de común acuerdo entre las partes, excluyendo siempre temporada alta, feriados en Uruguay y en el destino seleccionado y temporadas de vacaciones. El premio podrá utilizarse únicamente el plazo acordado por las partes en el momento de la adquisición (no antes ni después) y una vez definido el mismo, no se podrá modificar, sin derecho a reclamo alguno. El premio, no es canjeable por dinero, no incluyen otros bienes, prestaciones ni gastos de ninguna clase como traslados del ganador y/u otra/s persona/s, ni comidas, compras, propinas, cargos por servicios, excursiones, llamadas telefónicas, cualquier importe o gasto que el ganador y/u otra/s persona/s realice/n en ocasión de la utilización de los servicios turísticos, así como ningún otro producto y/o servicio que decida/n contratar. Los servicios turísticos seleccionados estarán sujetos a la disponibilidad de los prestadores de los mismos. Toda la documentación para el viaje y visas correspondientes serán de cuenta, cargo y responsabilidad exclusivos del ganador y/u otra/s persona/s que viaje/n. En caso que por un motivo ajeno a “VOY” el ganador y/u otra/s persona/s no pudieran utilizar la totalidad o parte de los servicios turísticos adquiridos, no tendrán derecho a reclamo de ninguna clase ni a recibir una suma de dinero por ningún concepto. Este premio no es transferible a otras personas, únicamente el ganador podrá hacer uso del mismo sin derecho a reclamo alguno.
        </p>
        <h2>V. RETIRO DEL PREMIO:</h2>
        <p>
          El ganador tendrá un plazo máximo de 60 días, contados desde el día que le fue notificado el resultado del sorteo, para acordar en conjunto con VOY el destino y las características del mismo. A estos efectos, el ganador deberá exhibir su cédula de identidad.
        </p>
        <p>
          Si por cualquier motivo el premio no fuera adjudicado o retirado en dicho plazo, se procederá al resorteo en las mismas condiciones, y así sucesivamente, hasta que se le adjudique a un ganador.
        </p>
        <h2>VI. OTROS:</h2>
        <p>
          La sola participación en el sorteo implica: a) el conocimiento y aceptación de las presentes bases y condiciones y de los términos y condiciones de VOY ; b) que VOY tendrá derecho a, en cualquier momento, difundir los datos personales del ganador y su imagen con fines de comunicación, en los medios y formas que determinen libremente, sin derecho a compensación o remuneración alguna, así como a citar al ganador a una premiación y publicar su identidad e imagen en cualquier medio publicitario y en los sitios web, sin límite de tiempo y sin derecho a compensación o remuneración alguna; c) la autorización a VOY, por parte de los participantes a utilizar los datos ingresados por estos para incorporarlos en las Bases de Datos de estas a efectos de seleccionar al ganador y para enviarles periódicamente boletines informativos.
        </p>
        <p>
          VOY no se hace responsable por pérdidas de información, interrupciones o incapacidades de la red, servidor, fallas en las trasmisiones de las líneas telefónicas o fallas técnicas, sean estas provenientes de un error de tipo humano, defecto mecánico o electrónico, o de cualquier otra clase, que pudieren afectar la participación de los interesados en el sorteo. Cualquier cambio o inexactitud de la dirección de correo electrónico o datos otorgados previamente por el participante, son responsabilidad de este y no darán derecho a reclamación de ninguna clase.
        </p>
        <p>
          VOY no será responsables por ningún daño o perjuicio, de cualquier tipo, que pudieren sufrir el ganador y/u otro/s, en sus personas o bienes, con motivo de o con relación a su participación en el sorteo o a la utilización del premio; ni por desperfectos técnicos, errores humanos o acciones deliberadas de terceros que pudieran interrumpir o alterar el normal desarrollo del sorteo.
        </p>
        <p>
          VOY, en su carácter de agencia de viajes, es un mero intermediario en la contratación de los servicios turísticos que el ganador adquiera haciendo uso del premio. Por lo tanto, en la medida que Jetmar Viajes no es el prestador de los referidos servicios, no otorga garantía de calidad, no asume responsabilidad al respecto ni será responsable de forma alguna con respecto a la prestación de los mismos.
        </p>
        <p>
          VOY se reservan el derecho de establecer y pronunciarse sobre aquellas situaciones o circunstancias que no estén expresamente previstas en estas bases y condiciones, reservándose asimismo el derecho de cancelar, suspender, o modificar las bases y condiciones del sorteo por circunstancias no previstas que sean ajenas a su voluntad, aclarándose que, cualquier modificación será ajustada a la legislación vigente en la materia y debidamente comunicada a los participantes, todo sin derecho a reclamación e indemnización alguna de parte de estos.
        </p>
        <p>
          La sola participación en el sorteo implica el conocimiento y aceptación de las presentes bases, términos y condiciones.
        </p>
      </div>
      <Clients />
      <Footer />
    </div>
  );
}

export default TermsDraw;
