import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { cardClasses } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import DarkStyle from './stylesMap';
import LocationPin from '../../img/location-pin.svg';
import MyLocationPin from '../../img/my-location.svg';
import RestaurantPin from '../../img/restaurant-pin.svg';
import TheatrePin from '../../img/theatre-pin.svg';
import CinemaPin from '../../img/cinema-pin.svg';
import FiestasPin from '../../img/fiestas-pin.svg';
import { endpointCloudfront } from '../../endpoints';
import MusicPin from '../../img/music-pin.svg';
import OtherPin from '../../img/other-pin.svg';
import '../../style/Map/marker.css';
import '../../style/Map/eventInfoMap.css';
import { calculateDistanceBetweenTwoCoordinates } from '../../service';
import CloseIcon from '../../img/close-icon.svg';

function EventInfoMap({
  latitude,
  longitude,
  isRestaurant = false,
  isLocation = false,
  restaurants,
  eventType,
}) {
  let eventPin = null;
  if (isRestaurant) {
    eventPin = RestaurantPin;
  } else if (isLocation) {
    eventPin = LocationPin;
  } else {
    switch (parseInt(eventType)) {
      case 0:
        eventPin = MyLocationPin;
        break;
      case 1:
        eventPin = MusicPin;
        break;
      case 2:
        eventPin = CinemaPin;
        break;
      case 3:
        eventPin = TheatrePin;
        break;
      case 6:
        eventPin = FiestasPin;
        break;
      default:
        eventPin = OtherPin;
        break;
    }
  }

  const [myLocation, setMyLocation] = useState(false);

  useEffect(() => {
    if (isMobile) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMyLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
        },
        (error) => {
          console.log(error);
        },
      );
    }
  }, []);

  const [selectedRestaurant, setSelectedRestaurant] = React.useState(null);

  return (
    <div className="map-container">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyAYXNdR0-Ve0SW03CBIgjM16KONtIBoHEw',
          language: 'es',
        }}
        style={{ height: '100%', width: '100%' }}
        options={{ styles: DarkStyle.darkStyle }}
        defaultZoom={15}
        center={{
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        }}
      >
        <div
          className="location-marker selected"
          lat={parseFloat(latitude)}
          lng={parseFloat(longitude)}
        >
          <img src={eventPin} type="event" />
        </div>

        {myLocation ? (
          <div
            className="location-marker my-location"
            lat={myLocation.lat}
            lng={myLocation.lng}
          >
            <img src={MyLocationPin} type="event" />
          </div>
        ) : ''}

        {restaurants?.map((restaurant) => (
          <div
            className="location-marker restaurant-pin"
            lat={parseFloat(restaurant.latitude)}
            lng={parseFloat(restaurant.longitude)}
            onClick={() => {
              setSelectedRestaurant(restaurant);
            }}
          >
            <img src={RestaurantPin} type="restaurant" />
          </div>
        ))}
      </GoogleMapReact>

      {selectedRestaurant && (
        <div className="map-restaurant-info">
          <div className="map-restaurant-image-container">
            <div
              className="map-restaurant-close"
              onClick={() => {
                setSelectedRestaurant(null);
              }}
            >
              <img src={CloseIcon} alt="close-icon" />
            </div>
            <img
              src={endpointCloudfront + selectedRestaurant?.desktopFile?.webp_thumbnail_url}
              className="desktopElement map-restaurant-image"
              alt="desktopImage"
            />

            <img
              src={endpointCloudfront + selectedRestaurant?.desktopFile?.webp_thumbnail_url}
              className="mobileElement map-restaurant-image"
              alt="mobileImage"
            />
          </div>
          <div className="map-restaurant-name">{selectedRestaurant?.name}</div>
          <div className="map-restaurant-address">
            {selectedRestaurant?.address}
          </div>
          <div className="map-restaurant-extra-info">
            <div className="map-restaurant-distance">
              A
              {' '}
              {calculateDistanceBetweenTwoCoordinates(
                latitude,

                longitude,

                selectedRestaurant?.latitude,
                selectedRestaurant?.longitude,
              ).toPrecision(2)}
              {' '}
              km de este evento
            </div>
            <Link
              to={`/locacion/${selectedRestaurant?.link}`}
              className="map-restaurant-more-info"
              target="_blank"
            >
              Conocer más
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default EventInfoMap;
