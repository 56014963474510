/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import '../../style/Site/eventInformation.css';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';

import {
  FacebookMessengerShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
  WhatsappShareButton,
} from 'react-share';
import { setOpenLogin, setShowedPopup, setLoading } from '../../state/AppState';
import Banner from '../Advertisments/Banner';
import {
  BannerVoyRegistro,
  MusicIcon,
  IconUbication,
  BandIcon,
  InstagramIcon,
  PhoneLocation,
  CheckDetailRestaurant,
  ArrowSliderLeft,
  ArrowSliderRight,
  ShareIcon,
  FacebookIcon,
  SpotifyIcon,
  youtubeIcon,
} from '../../img';
import {
  getFilteredEvents,
  getBandTypesName,
  getIdFromLink,
  getSponsor,
  getPromotion,
  getOtherDataName,
} from '../../service';

import Footer from '../Layouts/Footer';
import Clients from '../Advertisments/Clients';
import Events from '../Events/Events';
import Header from '../Layouts/Header';
import SliderHome from '../Slider/SliderHome';

import { domain, endpointBandData, endpointCloudfront } from '../../endpoints';

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="arrowSliderRight" type="button">
      <img src={ArrowSliderRight} alt="" />
    </button>
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="arrowSliderLeft" type="button">
      <img src={ArrowSliderLeft} alt="" />
    </button>
  );
}

function BandInformation({
  events,
  locations,
  bands,
  sponsors,
  eventTypes,
  bandTypes,
  genres,
  otherData,
  props,
  openLogin,
  setLoadingState,
  setOpenLoginState,
  setShowedPopupState,
}) {
  const [band, setBand] = useState();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { link } = params;
  const [id, setId] = useState();
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    const linkId = getIdFromLink(link);
    setId(linkId);
  }, [link]);

  async function getBandData(id) {
    const data = {
      id,
      userId: 0,
      userAction: 'ingresó',
    };
    setLoadingState(true);
    try {
      const response = await axios.post(endpointBandData, data);
      setLoadingState(false);
      const bandData = response.data.bands;
      setBand(bandData);
      const asyncGet = async () => {
        const filteredEvents = await getFilteredEvents(
          {
            band_id: bandData.id,
          },
          events,
        );
        setFilteredEvents(filteredEvents);
      };
      asyncGet();
      return response;
    } catch (error) {
      setLoadingState(false);
      return error;
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    if (id) {
      getBandData(id);
    }
  }, [id]);

  const imgEvent = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  const imgEventDetail = {
    dots: false,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: false,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  const imgLocationDetail = {
    dots: false,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: false,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  const [openFechas, setOpenFechas] = useState(false);
  const [closeFechas, setCloseFechas] = useState(false);
  const [shareRedes, setShareRedes] = useState(false);
  const [showPromotionDetails, setShowPromotionDetails] = useState(false);

  const handlePromotionDetails = (index) => {
    setShowPromotionDetails((prev) => (prev === index ? null : index));
  };

  const showBandTypes = false;
  const bandTypesToHide = [9, 10, 11];

  const shareUrl = `${domain}/locacion/${link}`;
  return (
    <div className="container-event-information">
      <Header />
      <div className="container-start">
        {' '}
        <SliderHome
          iconTitleTypeEvent={BandIcon}
          bandImgDesktop={endpointCloudfront + band?.desktopFileForBand?.webp_url}
          bandImgMobile={endpointCloudfront + band?.mobileFileForBand?.webp_url}
          theme="Artista"
          title={band?.name}
        />
        <button
          onClick={() => {
            setShareRedes(!shareRedes);
          }}
          className={
            shareRedes === true
              ? 'redes-share redes-share-open'
              : 'redes-share'
          }
          type="button"
        >
          <p className="desktopElement">Compartí en </p>
          <img className="mobileElement" src={ShareIcon} alt="" />

          <TwitterShareButton url={shareUrl}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <FacebookMessengerShareButton url={shareUrl}>
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
          <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </button>
      </div>

      <div className="information-event-select restaurants" />
      <div className="container-detail-information-restaurant">
        <div className="container-detail-information">
          <div className="header-detail-information">
            <div
              className="buttonInfo"
              type="button"
            >
              Sobre
              {' '}
              {band?.name}
            </div>
          </div>
          <div className="openInfo">
            {showBandTypes && band?.band_types ? (
              <p>
                {JSON.parse(band?.band_types).map((bandType, index) => (
                  (!bandTypesToHide.includes(parseFloat(bandType))
                    ? ((index > 0 ? ' / ' : '') + getBandTypesName(bandType, bandTypes))
                    : '')
                ))}
              </p>
            ) : ''}

            {band?.description
              ? <p className="openInfoDescription">{parse(band.description)}</p>
              : ""}

          </div>
        </div>
        {band?.facebook_link || band?.instagram_link || band?.spotify_link || band?.youtube_link ? (
          <div className="detail-information-restaurant">

            <div className="event-data redes-restaurant bands">
              <div>
                {band?.facebook_link ? (
                  <a href={band?.facebook_link} target="_blank" rel="noreferrer">
                    <img src={FacebookIcon} alt="" />
                    {' '}
                    {/* {band?.facebook_link?.split('.com/')[1]?.split('/')[0]} */}
                    Perfil de Facebook
                  </a>
                ) : (
                  ''
                )}
                {band?.instagram_link ? (
                  <a href={band?.instagram_link} target="_blank" rel="noreferrer">
                    <img src={InstagramIcon} alt="" />
                    {' '}
                    {/* {band?.instagram_link?.split('.com/')[1]?.split('/')[0]} */}
                    Perfil de Instagram
                  </a>
                ) : (
                  ''
                )}
                {band?.spotify_link ? (
                  <a href={band?.spotify_link} target="_blank" rel="noreferrer">
                    <img src={SpotifyIcon} alt="" />
                    {' '}
                    Perfil de Spotify
                  </a>
                ) : (
                  ''
                )}
                {band?.youtube_link ? (
                  <a href={band?.youtube_link} target="_blank" rel="noreferrer">
                    <img src={youtubeIcon} alt="" />
                    {' '}
                    Perfil de Youtube
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        ) : ''}
      </div>

      <div className="bannerIntendencia desktopElement">
        <button
          type="button"
          onClick={() => {
            setOpenLoginState(!openLogin);
            setShowedPopupState(true);
          }}
        >
          <Banner banner={BannerVoyRegistro} />
        </button>
      </div>

      {filteredEvents.length > 0 ? (
        <div className="container-events-recommended">
          <div className="title-recommended">
            Próximos eventos de
            {' '}
            {band?.name}
          </div>
          <div className="sub-container-events-recommended">
            <Events
              subTitleTypeEvent=""
              numberShow={4}
              modeCenter={false}
              filteredEvents={filteredEvents}
            />
          </div>
        </div>
      ) : ''}

      {band?.files.length > 0 ? (
        <div className="container-video-slider">
          {band?.files.map((file) => {
            if (file.file_type_id === '4') {
              return (
                <iframe
                  src={`https://www.youtube.com/embed/${file.url}`}
                  width="100%"
                  height="80%"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen="100%"
                  title="video"
                />
              );
            }
            return null;
          })}
        </div>
      ) : (
        ''
      )}
      {band?.files.length > 0 ? (
        <Slider {...imgEvent} className="slider-img-event">
          {band?.files.map((file) => {
            if (file.file_type_id !== '4') {
              return (
                <div>
                  <img
                    src={endpointCloudfront + file.webp_url}
                    alt=""
                  />
                </div>
              );
            }
            return null;
          })}
        </Slider>
      ) : (
        ''
      )}

      {band?.pastEvents?.length > 0 ? (
        <div className="container-events-recommended">
          <div className="title-recommended">
            Eventos anteriores de
            {' '}
            {band?.name}
          </div>
          <div className="sub-container-events-recommended">
            <Events
              subTitleTypeEvent=""
              numberShow={4}
              modeCenter={false}
              filteredEvents={band?.pastEvents}
            />
          </div>
        </div>
      ) : ''}

      <Clients />
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  sponsors: state.app.sponsors,
  eventTypes: state.app.eventTypes,
  bandTypes: state.app.bandTypes,
  genres: state.app.genres,
  otherData: state.app.otherData,
  openLogin: state.app.openLogin,
});

export default connect(mapStateToProps, {
  setLoadingState: setLoading,
  setOpenLoginState: setOpenLogin,
  setShowedPopupState: setShowedPopup,
})(BandInformation);
