/* eslint-disable linebreak-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import '../../style/Advertisments/banner.css';

function Banner(props) {
  return (
    <div className="banner">
      <div className="sub-container-banner">
        <img src={props.banner} alt={props.bannerInformation} />
      </div>
    </div>
  );
}

export default Banner;
