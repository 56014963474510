/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import '../../../style/Login/login.css';
import { useForm } from 'react-hook-form';
import { FacebookIcon, GoogleIcon } from '../../../img';
import { endpointLogin, endpointApi } from '../../../endpoints';

function RegisterLogin({ handleContinue }) {
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();
  return (
    <div className="RegisterLogin">
      <form onSubmit={handleSubmit(handleContinue)}>
        <div className="containerEmailLogin">
          <input {...register('email', { required: '*El campo de email es obligatorio' })} type="email" className="login-email" placeholder="Email" />
          <button type="submit" className="continueLoginButton">Registrarme</button>
        </div>
      </form>
      {/* <button type="button" className="registerLoginButton">o registrate</button>
      <div className="containerRedesLogin">
      <button type="button" className="buttonFacebookLogin">
      <img src={FacebookIcon} alt="Facebook" />
      con Facebook
      </button>
        <button type="button" className="buttonGoogleLogin">
        <img src={GoogleIcon} alt="Google" />
        con Google
        </button>
      </div> */}
    </div>
  );
}

export default RegisterLogin;
