/* eslint-disable linebreak-style */
/* eslint-disable react/no-unescaped-entities */
import { React, useEffect } from 'react';
import '../../style/Site/terms.css';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import Clients from '../Advertisments/Clients';
import { BlurPruple, BlurGreen } from '../../img';

function Terms() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className="terms">
      <Header />
      <img src={BlurPruple} alt="" className="blur-purple blur" />
      <img src={BlurGreen} alt="" className="blur-green blur" />
      <div className="container-terms">
        <p>
          Voy es un Portal digital que busca reunir a toda la oferta cultural,
          artística y gastronómica del país, desde lo más popular a los más
          emergente, con el su público objetivo y potencial. Para poder acceder
          a la plataforma todas las Personas Usuarias deberán aceptar estos
          Términos y Condiciones. Cada Persona Usuaria es responsable de los
          datos personales que brinda al momento de registrarse y se obliga a
          mantenerlos actualizados. Además, es el único responsable del uso y
          resguardo de su contraseña.
        </p>
        <h1>Términos y condiciones</h1>
        <p>
          Estos términos y condiciones y los anexos que explican los servicios
          del Portal Voy (de ahora en más: “Términos y Condiciones”) regulan la
          relación entre Voy y las personas que usan sus servicios (“Personas
          Usuarias”).
        </p>
        <p>
          Las Personas Usuarias aceptan estos Términos y Condiciones desde el
          momento en que se registran en el Sitio y usan el Portal Voy.
        </p>
        <p>
          Las Personas Usuarias podrán finalizar la relación con Voy cancelando
          su cuenta y desuscribiéndose de las novedades y anuncios.
        </p>
        <h2>Registro y Cuenta</h2>
        <p>
          Todo aquel que quiera acceder al Portal, podrá hacerlo sin necesidad
          de registro y acceder a toda la información y contenido sin
          restricciones.
        </p>
        <p>
          Para participar de las promociones, sorteos y material exclusivo para
          usuarios registrados, se deberá completar el formulario de registro
          con los datos que le sean requeridos.
        </p>
        <p>
          Para registrarse como artista o locación, se deberá completar un
          formulario de registro. Al completarlo, se compromete a hacerlo de
          manera exacta, precisa y verdadera y a mantener sus datos siempre
          actualizados. La Persona Usuaria será la única responsable de la
          certeza de sus datos de registro. Sin perjuicio de la información
          brindada en el formulario, podremos solicitar y/o consultar
          información adicional para corroborar la identidad de la Persona
          Usuaria.
        </p>
        <p>
          La cuenta es personal, única e intransferible. Se accede a ella con la
          clave personal de seguridad que haya elegido y que deberá mantener
          bajo estricta confidencialidad.
          {' '}
        </p>
        <p>
          Podremos rechazar una solicitud de registro o bien cancelar un
          registro ya aceptado, sin que esto genere derecho a un resarcimiento.
          No podrán registrarse nuevamente en el Sitio las Personas Usuarias que
          hayan sido inhabilitadas previamente.
          {' '}
        </p>
        <h2>Privacidad de datos</h2>
        <p>
          En Voy hacemos un uso responsable de la información personal,
          protegiendo la privacidad de las Personas Usuarias que nos confiaron
          sus datos y tomando las medidas necesarias para garantizar la
          seguridad.
        </p>
        <h2>Sanciones</h2>
        <p>
          En caso que la Persona Usuaria incumpliera una ley o los Términos y
          Condiciones, podremos advertir, suspender, restringir o inhabilitar
          temporal o definitivamente su cuenta.
        </p>

        <h2>Indemnidad</h2>
        <p>
          La Persona Usuaria mantendrá indemne a Voy y a sus sociedades
          relacionadas, así como a quienes la dirigen, suceden, administran,
          representan y/o trabajan en ellas, por cualquier reclamo
          administrativo o judicial iniciado por otras Personas Usuarias,
          terceros o por cualquier Organismo, relacionado con sus actividades en
          el Portal Voy. En virtud de esa responsabilidad, podrán realizar
          compensaciones, retenciones u otras medidas necesarias para la
          reparación de pérdidas, daños y perjuicios, cualquiera sea su
          naturaleza.
        </p>

        <h2>Jurisdicción y Ley Aplicable</h2>
        <p>
          Estos Términos y Condiciones se rigen por la ley local. Toda
          controversia derivada de su aplicación, interpretación, ejecución o
          validez será resuelta por los tribunales nacionales ordinarios
          competentes.. Para todos los efectos relacionados con estos Términos y
          Condiciones y con el uso del sitio, Pepepaquetes S.A. con RUT
          218037010013, establece como domicilio Sarandí 425, Montevideo,
          Uruguay.
        </p>

        <h1>Política de privacidad</h1>

        <h2>Finalidad</h2>
        <p>
          Los datos personales que se recaben serán utilizados con la finalidad
          de brindar un mejor servicio y facilitar el uso del sitio web a través
          del análisis del número y frecuencia de visitas. Respecto de la
          recolección y tratamiento de datos realizado mediante mecanismos
          automatizados con el objeto de generar registros de actividad de los
          visitantes, Voy en ningún caso realizará operaciones que impliquen
          asociar dicha información a algún usuario identificado o
          identificable.
        </p>

        <h2>Contacto y formularios web</h2>
        <p>
          El formulario “Contacto” del sitio web está concebido como un buzón de
          atención al cliente y tiene como objetivo facilitar las comunicaciones
          e intercambio de información entre los interesados y Voy, y los datos
          se mantendrán con la finalidad de recibir y responder consultas, así
          como obtener datos estadísticos de las mismas. Los datos personales de
          los usuarios que se registren en el sitio Web mediante formularios
          establecidos a esos efectos, serán utilizados para el cumplimiento de
          los fines indicados en cada uno de ellos.
        </p>

        <h2>Comunicación de datos personales</h2>
        <p>
          Los datos personales recabados podrán ser comunicados a Entidades
          Públicas o a terceros, única y exclusivamente, cuando la consecución
          de la finalidad perseguida por el usuario así lo requiera, o con su
          consentimiento.
        </p>

        <h2>Ejercicio de los derechos</h2>
        <p>
          El Usuario podrá en todo momento ejercer los derechos de acceso,
          rectificación, actualización, inclusión y supresión, otorgados por la
          Ley Nº 18.331, sus modificativas y concordantes, ante Voy a través del
          formulario de “Contacto”.
        </p>

        <h2>Cookies</h2>
        <p>
          Voy podrá utilizar cookies cuando el usuario navega por el sitio web,
          pero éste puede configurar su navegador para ser avisado de la
          recepción de las cookies e impedir en caso de considerarlo adecuado su
          instalación en el disco duro.
        </p>

        <h2>Enlaces</h2>
        <p>
          La presente Política de Privacidad es de aplicación exclusiva al sitio
          web de Voy y no se extiende a los casos de acceso a la misma a través
          de enlaces externos, ni tampoco en los enlaces de este sitio a otros
          sitios web.
        </p>
      </div>
      <Clients />
      <Footer />
    </div>
  );
}

export default Terms;
