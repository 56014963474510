/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import '../../../style/Login/login.css';
import { useForm } from 'react-hook-form';
import { FacebookIcon, GoogleIcon } from '../../../img';

function RegisterDetail({ handleContinue }) {
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();
  return (
    <form onSubmit={handleSubmit(handleContinue)}>
      <div className="registerDetail">
        <div className="inputContainerRegisterDetail">
          <input {...register('first_name', { required: '*El campo de nombre es obligatorio' })} type="name" placeholder="Nombre" />
          <input {...register('last_name', { required: '*El campo de apellido es obligatorio' })} type="lastName" placeholder="Apellido" />
        </div>
        <div className="inputContainerRegisterDetail">
          <input {...register('phone')} type="phone" placeholder="Teléfono" />
          <input {...register('age')} placeholder="Edad" />
        </div>
        {/* <div className="inputContainerRegisterDetail">
          <input {...register('password', { required: '*El campo de contraseña es obligatorio' })} type="password" placeholder="Contraseña" />
          <input {...register('repeat_password', { required: '*Por favor repite tu contraseña' })} type="password" placeholder="Confirmar Contraseña" />
        </div> */}
        <button type="submit" className="continueLoginButton">Registrarme</button>
      </div>
    </form>
  );
}

export default RegisterDetail;
