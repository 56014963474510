/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import '../../style/Site/giveaway.css';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import {
  MapIcon, ClickLoginNewsLetter, ClickLoginNewsLetterGris, VosVasYoVoy,
} from '../../img';
import { getIdFromLink, getGiveaway } from '../../service';
import Clients from '../Advertisments/Clients';
import { endpointCloudfront, endpointGiveaways } from '../../endpoints';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import { setLoading } from '../../state/AppState';

import Message from '../Popups/Message';

import 'react-datepicker/dist/react-datepicker.css';

function Giveaways({ setLoadingState, giveaways }) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const listenToScroll = () => {
    const heightToHideFrom = document.getElementById('registerTitle').offsetTop - document.getElementById('registerForm').offsetHeight;
    const winScroll = document.body.scrollTop
          || document.documentElement.scrollTop;
    const element = document.getElementById('continueTooltip');
    if (winScroll > heightToHideFrom) {
      element.classList.add('hidden');
    } else {
      element.classList.remove('hidden');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  const params = useParams();
  const { link } = params;

  const [response, setResponse] = useState([]);

  const [birthdate, setBirthdate] = useState(false);

  const [selectedQuantity, setSelectedQuantity] = useState(0);

  async function onSubmitHandler(data) {
    const giveawayIds = [];
    const selectedGiveaways = document.querySelectorAll('.selectable-giveaways .selectable-giveaway.selected');
    selectedGiveaways.forEach((giveaway, index) => {
      giveawayIds.push(giveaway.getAttribute('data-id').toString());
    });
    // const date = new Date(birthdate).toISOString().substring(0, 10);
    const date = '1970-01-01';
    if (giveawayIds.length > 0) {
      const contactData = {
        contact: {
          ...data,
          birthdate: date,
          giveaway_ids: giveawayIds,
        },
      };
      try {
        // setLoadingState(true);
        await axios.post(endpointGiveaways, contactData).then((res) => {
          setLoadingState(false);
          setResponse(res.data);
        }, (err) => err);
      } catch (error) {
        setLoadingState(false);
        return error;
      }
    } else {
      const responseNoOptionsSelected = {
        status: 'noOptionsSelected',
        errorMessage: 'Selecciona al menos un sorteo para inscribirte',
      };
      setResponse(responseNoOptionsSelected);
    }
  }

  const handleClick = (event) => {
    event.currentTarget.classList.toggle('selected');
    document.getElementById('continueTooltip').classList.remove('hidden');
    const quantity = document.querySelectorAll('.selectable-giveaways .selectable-giveaway.selected').length;
    setSelectedQuantity(quantity);
  };

  const handleContinue = (event) => {
    document.getElementById('registerForm').scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  };

  return (
    <>
      <Helmet>
        <title>VOY - Sorteo entradas verano 2023</title>
        <meta name="title" content="VOY - Sorteo entradas verano 2023" />
        <meta property="og:title" content="VOY - Sorteo entradas verano 2023" />
        <meta property="twitter:title" content="VOY - Sorteo entradas verano 2023" />
      </Helmet>
      <div className="contact">
        <Header />
        <div className="container-form">
          <h1>Selecciona los sorteos</h1>
          <h2>de los que te gustaría participar.</h2>
          <div className="selectable-giveaways">
            {giveaways?.map((giveaway) => (
              <button type="button" data-id={giveaway?.id} className="selectable-giveaway" onClick={handleClick}>
                <div className="selectable-giveaway-background" />
                <img className="giveawayImage desktopElement" src={endpointCloudfront + giveaway?.desktopFile?.webp_url} alt="sorteo" />
                <img className="giveawayImage mobileElement" src={endpointCloudfront + giveaway?.desktopFile?.webp_url} alt="sorteo" />
                <div className="selectable-giveaway-info">
                  <span>{giveaway?.subtitle}</span>
                  <h4>{giveaway?.name}</h4>
                  <p>
                    <img src={MapIcon} alt="Map" />
                    {giveaway?.detail}
                  </p>
                  <div
                    className="voyEventButton"
                  >
                    <span>Seleccionar</span>
                    <span className="selected">Seleccionado</span>
                    <img src={ClickLoginNewsLetterGris} alt="arrow" />
                    <img className="selected" src={ClickLoginNewsLetter} alt="arrow" />
                  </div>
                </div>
              </button>
            ))}
          </div>
          <h1 id="registerTitle">Registrate para participar,</h1>
          <h2>todo está en voy.</h2>
          <form id="registerForm" onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="sub-container-inputs">
              <div className="container-inputs-draw">
                <div>
                  <input {...register('first_name', { required: '*El campo de nombre es obligatorio' })} placeholder="Nombre" />
                  <span>{errors.first_name?.message}</span>
                </div>
                <div>
                  <input {...register('last_name', { required: '*El campo de apellido es obligatorio' })} placeholder="Apellido" />
                  <span>{errors.last_name?.message}</span>
                </div>
                <div>
                  <input {...register('phone', { required: '*El campo de celular es obligatorio' })} placeholder="Celular" />
                  <span>{errors.phone?.message}</span>
                </div>
                <div>
                  <input {...register('email', { required: '*El campo de email es obligatorio' })} type="email" placeholder="Email" />
                  <span>{errors.email?.message}</span>
                </div>
                {/* <div>
                  <DatePicker
                    format="yyyy-MM-dd"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Fecha de Nacimiento"
                    selected={birthdate}
                    onChange={(date) => setBirthdate(date)}
                  />
                  <span>{errors.birthdate?.message}</span>
                </div> */}
                <div>
                  <input {...register('document', { required: '*El campo de cédula es obligatorio' })} placeholder="Cédula" />
                  <span>{errors.document?.message}</span>
                </div>
              </div>
            </div>
            <Message value="Registrarme" response={response} />
          </form>
          <img className="vosvasyovoy" src={VosVasYoVoy} alt="vosvasyovoy" />
          <Link to="/terminos-y-condiciones-sorteo-verano">Términos y condiciones del sorteo</Link>
          <button onClick={handleContinue} type="button" id="continueTooltip" className="continueTooltip">{`Participar${selectedQuantity > 0 ? ` (${selectedQuantity})` : ''}`}</button>
        </div>
        <div className="clients">
          <Clients />
        </div>
        <Footer />
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  loginStage: state.app.loginStage,
  giveaways: state.app.giveaways,
});
export default connect(mapStateToProps, {
  setLoadingState: setLoading,
})(Giveaways);
