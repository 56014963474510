/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import '../../style/Site/eventInformation.css';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';

import {
  FacebookMessengerShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
  WhatsappShareButton,
} from 'react-share';
import {
  getFilteredLocations,
  getFilteredEvents,
  getIdFromLink,
  getSponsor,
  getPromotion,
  getOtherDataName,
} from '../../service';
import {
  MusicIcon,
  IconUbication,
  InstagramIcon,
  PhoneLocation,
  CheckDetailRestaurant,
  ArrowSliderLeft,
  ArrowSliderRight,
  ShareIcon,
  LocationIcon,
  RestaurantsIcon,
  FacebookIcon,
} from '../../img';

import Footer from '../Layouts/Footer';
import Clients from '../Advertisments/Clients';
import EventInfoMap from '../Map/EventInfoMap';
import Events from '../Events/Events';
import Header from '../Layouts/Header';
import SliderHome from '../Slider/SliderHome';

import { setLoading } from '../../state/AppState';
import { domain, endpointLocationData, endpointCloudfront } from '../../endpoints';

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="arrowSliderRight" type="button">
      <img src={ArrowSliderRight} alt="" />
    </button>
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="arrowSliderLeft" type="button">
      <img src={ArrowSliderLeft} alt="" />
    </button>
  );
}

function LocationInformation({
  events,
  locations,
  restaurants,
  bands,
  sponsors,
  eventTypes,
  genres,
  otherData,
  props,
  localities,
  setLoadingState,
}) {
  const [location, setLocation] = useState();
  const [mainLocation, setMainLocation] = useState();
  const [branches, setBranches] = useState([]);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { link } = params;
  const [id, setId] = useState();
  const [branchId, setBranchId] = useState();
  const maxRestaurantDistance = 2;
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [filteredRestaurantsForRestaurant, setFilteredRestaurantsForRestaurant] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    const linkId = getIdFromLink(link);
    setId(linkId);
    const sucursaId = searchParams.get('sucursal');
    if (sucursaId) {
      setBranchId(getIdFromLink(sucursaId));
    } else {
      setBranchId();
    }
  }, [link]);

  useEffect(() => {
    const asyncGet = async () => {
      setFilteredRestaurants(await getFilteredLocations(
        false,
        restaurants,
        localities,
        { latitude: location?.latitude, longitude: location?.longitude },
        maxRestaurantDistance,
      ));
    };

    if (location && location.is_restaurant === '0' && location.latitude && location.longitude) {
      asyncGet();
    } else {
      const filteredLocations = restaurants.filter((location) => {
        if (location.id !== id) {
          return true;
        }
      });
      setFilteredRestaurantsForRestaurant(filteredLocations);
      setFilteredRestaurants([]);
    }
  }, [location]);

  function selectBranch() {
    let branchData = null;
    if (branchId) {
      branchData = branches.filter((branch) => {
        if (branch.id === branchId) {
          return true;
        }
        return null;
      });
    }
    const locationData = branchId ? branchData[0] : mainLocation;
    setLocation(locationData);
  }

  async function getLocationData(id) {
    const data = {
      id,
      userId: 0,
      userAction: 'ingresó',
    };
    setLoadingState(true);
    try {
      const response = await axios.post(endpointLocationData, data);
      setLoadingState(false);
      const mainLocationData = response.data.locations;
      setMainLocation(mainLocationData);
      if (mainLocationData.is_main_location === '1') {
        const branchesData = response.data.locations.locationBranches;
        setBranches(branchesData);
      } else {
        setBranches([]);
      }
      selectBranch();
      const asyncGet = async () => {
        const filteredEvents = await getFilteredEvents(
          {
            location: [mainLocationData.id],
          },
          events,
        );
        setFilteredEvents(filteredEvents);
      };
      asyncGet();
      return response;
    } catch (error) {
      setLoadingState(false);
      return error;
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    if (id) {
      getLocationData(id);
    }
  }, [id]);

  useEffect(() => {
    selectBranch();
  }, [id, branchId, mainLocation]);

  const imgEvent = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  const imgEventDetail = {
    dots: false,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: false,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  const imgLocationDetail = {
    dots: false,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: false,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  const [openInfoEvent, setOpenInfoEvent] = useState(true);
  const [openAboutEvent, setOpenAboutEvent] = useState(false);
  const [openLocationEvent, setOpenLocationEvent] = useState(false);
  const [openFechas, setOpenFechas] = useState(false);
  const [closeFechas, setCloseFechas] = useState(false);
  const [shareRedes, setShareRedes] = useState(false);
  const [showPromotionDetails, setShowPromotionDetails] = useState(false);

  const closeAll = () => {
    setOpenInfoEvent(false);
    setOpenAboutEvent(false);
    setOpenLocationEvent(false);
    setCloseFechas(false);
  };

  const handlePromotionDetails = (index) => {
    setShowPromotionDetails((prev) => (prev === index ? null : index));
  };

  const shareUrl = `${domain}/locacion/${link}`;
  return (
    <div className="container-event-information">
      <Header />
      <div className="container-start">
        {' '}
        <SliderHome
          iconTitleTypeEvent={location?.is_restaurant === '1' ? RestaurantsIcon : LocationIcon}
          bandImgDesktop={endpointCloudfront + location?.desktopFileForLocation?.webp_url}
          bandImgMobile={endpointCloudfront + location?.mobileFileForLocation?.webp_url}
          theme={location?.is_restaurant === '1' ? 'Oferta Gastronómica' : 'Locación'}
          title={location?.name}
        />
        <button
          onClick={() => {
            closeAll();
            setShareRedes(!shareRedes);
          }}
          className={
            shareRedes === true
              ? 'redes-share redes-share-open'
              : 'redes-share'
          }
          type="button"
        >
          <p className="desktopElement">Compartí en </p>
          <img className="mobileElement" src={ShareIcon} alt="" />

          <TwitterShareButton url={shareUrl}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <FacebookMessengerShareButton url={shareUrl}>
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
          <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </button>
      </div>

      <div className="information-event-select restaurants">
        {branches?.length > 0 ? (
          <div className="event-data branches-restaurant">
            <div>
              <button onClick={() => { setBranchId(branchId === mainLocation.id || mainLocation.id === id ? '' : mainLocation.id); }} type="button" className={branchId === mainLocation.id || (mainLocation.id === id && !branchId) ? 'selected' : ''} data-id={mainLocation.id}>{mainLocation.branch_name}</button>
              {branches?.map((branch) => (
                <button onClick={() => { setBranchId(branchId === branch.id || branch.id === id ? '' : branch.id); }} type="button" className={branchId === branch.id || (branch.id === id && !branchId) ? 'selected' : ''} data-id={branch.id}>{branch.branch_name}</button>
              ))}
            </div>
          </div>
        ) : ''}
      </div>
      <div className="container-detail-information-restaurant">
        <div className="container-detail-information">
          <div className="header-detail-information">
            <button
              onClick={() => {
                closeAll();
                setOpenInfoEvent(!openInfoEvent);
              }}
              className={openInfoEvent === true ? 'buttonOpen' : 'buttonInfo'}
              type="button"
            >
              Información
            </button>
            <button
              onClick={() => {
                closeAll();
                setOpenAboutEvent(!openAboutEvent);
              }}
              className={openAboutEvent === true ? 'buttonOpen' : 'buttonInfo'}
              type="button"
            >
              Detalle
            </button>
          </div>
          <div className={openInfoEvent === true ? 'openInfo' : 'about-the-event'}>

            {location?.description
              ? <div className="description-block"><p>{parse(location.description)}</p></div>
              : ""}

          </div>
          <div className={openAboutEvent === true ? 'openInfo servicios' : 'about-the-event servicios'}>
            {location && otherData && JSON.parse(location?.other_data).length > 0 ? (
              <div className="detail-information-restaurant">
                {JSON.parse(location?.other_data).map((data) => (
                  <div>
                    <img src={CheckDetailRestaurant} alt="" />
                    {' '}
                    <p>{getOtherDataName(data, otherData)}</p>
                  </div>
                ))}
              </div>
            ) : ''}
          </div>
        </div>
        {location && (
          (location.latitude && location.longitude)
          || location.name
          || location.address
          || location.phone
          || (location.facebook_link || location.instagram_link)) ? (
          <div className="detail-information-restaurant">

            <div className="event-data">
              <img src={IconUbication} alt="" />
              <div className="event-data-date">
                <h2>{location?.name}</h2>
                <p>{location?.address}</p>
              </div>
            </div>

            <div className="event-data">
              <img src={PhoneLocation} alt="" />
              <div className="event-data-date">
                <h2>{location?.phone}</h2>
              </div>
            </div>

            <div className="event-data redes-restaurant">
              <div>
                {location?.facebook_link ? (
                  <a href={location?.facebook_link} target="_blank" rel="noreferrer">
                    <img src={FacebookIcon} alt="" />
                  </a>
                ) : (
                  ''
                )}
                {location?.instagram_link ? (
                  <a href={location?.instagram_link} target="_blank" rel="noreferrer">
                    <img src={InstagramIcon} alt="" />
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        ) : ''}
      </div>

      {location?.locationPromotions?.length > 0 ? (
        <div className="information-promotions-event">
          <div className="sub-information-promotions-event">
            <div className="promotion-event">
              <h2>Beneficios</h2>

              <div className="container-promotions-event">
                {location?.locationPromotions?.map((p, index) => {
                  const sponsor = getSponsor(p.sponsor_id, sponsors);
                  const promotion = getPromotion(p.sponsor_promotion_id, sponsors);
                  return (
                    <div className="img-promotion-event">
                      {sponsor.file?.webp_thumbnail_url ? (
                        <img
                          src={endpointCloudfront + sponsor.file?.webp_thumbnail_url}
                          alt="cocarda promotion"
                        />
                      ) : (
                        <h2>{sponsor.name}</h2>
                      )}
                      <div>
                        <h3>{promotion.name}</h3>
                        <p>{p.terms}</p>
                      </div>
                      <div className="img-promotion-event-open" style={{ display: showPromotionDetails === index ? 'flex' : 'none' }}>
                        <div>
                          <h3>{promotion.name}</h3>
                          <p>{p.terms}</p>
                        </div>
                      </div>
                      {p.terms ? (
                        <button type="button" onClick={() => { handlePromotionDetails(index); }}>Ver más</button>
                      ) : ''}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : ''}

      {filteredEvents.length > 0 ? (
        <div className="container-events-recommended">
          <div className="title-recommended">
            Próximos eventos en
            {' '}
            {location?.name}
          </div>
          <div className="sub-container-events-recommended">
            <Events
              subTitleTypeEvent=""
              numberShow={4}
              modeCenter={false}
              filteredEvents={filteredEvents}
            />
          </div>
        </div>
      ) : ''}

      <div className="map-where-go">
        <span>¿Dónde voy?</span>
        <h2>{location?.name}</h2>
        <p>{location?.address}</p>
        {location
          && location?.longitude !== '""'
          && location?.latitude !== '""'
          && location?.longitude !== 'null'
          && location?.latitude !== 'null' ? (
            <div className="container-map-where-go">
              <EventInfoMap
                latitude={location?.latitude}
                longitude={location?.longitude}
                isRestaurant={location?.is_restaurant === '1'}
                isLocation={location?.is_restaurant === '0'}
                restaurants={filteredRestaurants}
              />
            </div>
          ) : (
            ''
          )}
      </div>
      {location?.files.length > 0 ? (
        <div className="container-video-slider">
          {location?.files.map((file) => {
            if (file.file_type_id === '4') {
              return (
                <iframe
                  src={`https://www.youtube.com/embed/${file.url}`}
                  width="100%"
                  height="80%"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen="100%"
                  title="video"
                />
              );
            }
            return null;
          })}
        </div>
      ) : (
        ''
      )}
      {location?.is_restaurant === '1' ? (
        <div className="container-events-recommended">
          <div className="title-recommended">También te recomendamos</div>
          <div className="sub-container-events-recommended">
            <Events
              locationsSlider="1"
              iconTitleTypeEvent={RestaurantsIcon}
              titleTypeEvent="Oferta gastronómica"
              subTitleTypeEvent=""
              numberShow={4}
              modeCenter={false}
              filteredEvents={filteredRestaurantsForRestaurant}
            />
          </div>
        </div>
      ) : ''}

      {filteredRestaurants.length > 0 ? (
        <div className="container-events-recommended">
          <div className="title-recommended">Restaurantes cercanos</div>
          <div className="sub-container-events-recommended">
            <Events
              locationsSlider="1"
              iconTitleTypeEvent={RestaurantsIcon}
              titleTypeEvent="Oferta Gastronómica"
              subTitleTypeEvent=""
              numberShow={4}
              modeCenter={false}
              filteredEvents={filteredRestaurants}
            />
          </div>
        </div>
      ) : ''}
      <Clients />
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  restaurants: state.app.restaurants,
  sponsors: state.app.sponsors,
  eventTypes: state.app.eventTypes,
  genres: state.app.genres,
  otherData: state.app.otherData,
  localities: state.app.localities,
});

export default connect(mapStateToProps, {
  setLoadingState: setLoading,
})(LocationInformation);
