/* eslint-disable linebreak-style */
/* eslint-disable react/no-unescaped-entities */
import { React, useEffect } from 'react';
import '../../style/Site/terms.css';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import Clients from '../Advertisments/Clients';
import { BlurPruple, BlurGreen } from '../../img';

function TermsDrawSummer() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className="terms">
      <Header />
      <img src={BlurPruple} alt="" className="blur-purple blur" />
      <img src={BlurGreen} alt="" className="blur-green blur" />
      <div className="container-terms">
        <h1>
          BASES Y CONDICIONES PARA EL SORTEO “VERANO EN VOY”
        </h1>
        <h2>I. EL SORTEO:</h2>
        <p>
          Pepe Paquetes S.A. (en adelante “VOY”) persona jurídica regularmente constituida inscrita con el R.U.T.  número 218037010013, realizará sorteos de acuerdo a los términos y condiciones establecidos en este documento.
        </p>
        <p>
          Los sorteos denominados “Verano en VOY” (en adelante “los sorteos” o “el sorteo”), son de carácter nacional.  El plazo para registrarse a efectos de participar en los sorteos será hasta 48 hrs antes de cada sorteo y hasta el 27 de enero de 2023. La participación en los sorteos es libre y sin obligación de compra.
        </p>
        <p>
          Los sorteos, se realizarán en los meses de diciembre 2022 y de enero 2023, y cada uno tendrá un único ganador que se hará acreedor de una entrada para un espectáculo específico, comunicado previamente en cada caso. La persona que gane cada sorteo, podrá hacer uso de la entrada para el evento específico en todos los casos.
        </p>
        <h2>II. ¿QUIÉNES PARTICIPAN?:</h2>
        <p>
          Podrán participar de los sorteos todas aquellas personas uruguayas, mayores de edad y residentes en el Uruguay.
        </p>
        <p>
          No podrán participar en los sorteos los empleados, proveedores, asesores y colaboradores eventuales y permanentes de 5M Travel Group, ni VOY ni de la agencia de publicidad contratada, ni sus parientes por consanguinidad o afinidad hasta el segundo grado inclusive.
        </p>
        <p>
          En caso que el ganador no cumpla con alguno de los requisitos establecidos en estas bases y condiciones, perderá el derecho a recibir el premio y se procederá a seleccionar un nuevo ganador en las mismas condiciones, sin derecho a reclamación de especie alguna.
        </p>
        <h2>III. ¿CÓMO PARTICIPAR?:</h2>
        <p>
          Las personas que quieran participar en los sorteos deberán registrarse a tales efectos en la página web www.voy.com.uy ingresando todos los datos allí solicitados, y deberán aceptar las presentes bases y condiciones.
        </p>
        <h2>IV. PREMIOS:</h2>
        <p>
          Cada ganador tendrá derecho al siguiente premio: 1 entrada general para los eventos que se detallan a continuación:
        </p>
        <p>
          <b>
            Entrada para Fiesta Fantasy
          </b>
        </p>
        <p>
          Sorteo a realizarse el 27/12/22 a las 23:59. Premio: Ingreso a Evento Fantasy, a realizarse el 29 de diciembre de 2022 en Open Park - La Barra - Punta del Este, Uruguay.
        </p>
        <p>
          <b>
            Fiesta Nómade
          </b>
        </p>
        <p>
          Sorteo a realizarse el 27/12/22 a las 23:59. Premio: Ingreso a Evento Nómade, a realizarse el 29 de diciembre de 2022 en Open Park - La Barra - Punta del Este, Uruguay.
        </p>
        <p>
          <b>
            Entrada para Festival Full Moon
          </b>
        </p>
        <p>
          Sorteo a realizarse el 27/12/22 a las 23:59. Premio: Ingreso a Evento Festival Full Moon, a realizarse el 29 de diciembre de 2022 en Open Park - La Barra - Punta del Este, Uruguay.
        </p>
        <p>
          <b>
            Entrada para Festival at Open Park Powered by Coco & Bliss
          </b>
        </p>
        <p>
          Sorteo a realizarse el 27/12/22 a las 23:59. Premio: Ingreso a Evento Festival at Open Park Powered by Coco & Bliss, a realizarse el 29 de diciembre de 2022 en Open Park - La Barra - Punta del Este, Uruguay.
        </p>
        <p>
          <b>
            Entrada para Fiesta Pixel NYE 2023
          </b>
        </p>
        <p>
          Sorteo a realizarse el 28/12/22 a las 23:59. Premio: Ingreso a Evento Fiesta Pixel NYE 2023, a realizarse el 01 de enero de 2023 en Open Park - La Barra - Punta del Este, Uruguay.
        </p>
        <p>
          <b>
            Entrada para Johnnie Walker Highball Festival
          </b>
        </p>
        <p>
          Sorteo a realizarse el 28/12/22 a las 23:59. Premio: Ingreso al Evento Johnnie Walker Highball Festival, a realizarse el 02 de enero de 2023 en Open Park - La Barra - Punta del Este, Uruguay.
        </p>
        <p>
          <b>
            Entrada para David Guetta PDE 2023
          </b>
        </p>
        <p>
          Sorteo a realizarse el 28/12/22 a las 23:59. Premio: Ingreso al Evento David Gueta PDE 2023, a realizarse el 02 de enero de 2023 en Parada 6 - Playa Brava, Punta del Este, Uruguay.
        </p>
        <p>
          <b>
            Entrada para Houston y los problemas
          </b>
        </p>
        <p>
          Sorteo a realizarse el 28/12/22 a las 23:59. Premio: Ingreso a Evento Houston y los problemas, a realizarse el 03 de enero de 2023 en Ferona Club Social, José Ignacio, Uruguay.
        </p>
        <p>
          <b>
            Entrada para Ruben Rada
          </b>
        </p>
        <p>
          Sorteo a realizarse el 04/01/23 a las 23:59. Premio: Ingreso al Evento Ruben Rada en Andresito, a realizarse el 07 de enero de 2023 en Teatro de Verano - Parque Andresito - La Paloma, Rocha, Uruguay.
        </p>
        <p>
          <b>
            Entrada para Slow Burnin
          </b>
        </p>
        <p>
          Sorteo a realizarse el 05/01/23 a las 23:59. Premio: Ingreso a Evento Slow Burnin, a realizarse el 11 de enero de 2023 en Ferona Club Social, José Ignacio, Uruguay.
        </p>
        <p>
          Cada una de las entradas deberá utilizarse únicamente para el evento específico y en la fecha comunicada  por las en el momento de la adquisición (no antes ni después) y una vez definido el mismo, no se podrá modificar, sin derecho a reclamo alguno. El premio, no es canjeable por dinero, no incluye otros bienes, prestaciones ni gastos de ninguna clase.
        </p>
        <p>
          En caso que por un motivo ajeno a “VOY” el ganador y/u otra/s persona/s no pudieran utilizar el premio, no tendrán derecho a reclamo de ninguna clase ni a recibir una suma de dinero por ningún concepto. Este premio no es transferible a otras personas, únicamente el ganador podrá hacer uso del mismo sin derecho a reclamo alguno.
        </p>
        <h2>V. COMUNICACIÓN DE LAS PERSONAS GANADORAS Y RETIRO DEL PREMIO:</h2>
        <p>
          Cada ganador será comunicado en la cuenta de Instagram de VOY, y se le enviará un e-mail para coordinar el retiro de la entrada correspondiente. No es responsabilidad de VOY si la persona no incluyó sus datos correctamente en el formulario de registro.
        </p>
        <p>
          El ganador tendrá un plazo máximo de 1 día, contado desde el día que le fue notificado el resultado del sorteo, para acordar en conjunto con VOY el destino y las características del mismo. A estos efectos, el ganador deberá exhibir su cédula de identidad.
        </p>
        <p>
          Si por cualquier motivo el premio no fuera adjudicado o retirado en dicho plazo, se procederá al resorteo en las mismas condiciones, y así sucesivamente, hasta que se le adjudique a un ganador.
        </p>
        <h2>VI. OTROS:</h2>
        <p>
          La sola participación en el sorteo implica: a) el conocimiento y aceptación de las presentes bases y condiciones y de los términos y condiciones de VOY ; b) que VOY tendrá derecho a, en cualquier momento, difundir los datos personales del ganador y su imagen con fines de comunicación, en los medios y formas que determinen libremente, sin derecho a compensación o remuneración alguna, así como a citar al ganador a una premiación y publicar su identidad e imagen en cualquier medio publicitario y en los sitios web, sin límite de tiempo y sin derecho a compensación o remuneración alguna; c) la autorización a VOY, por parte de los participantes a utilizar los datos ingresados por estos para incorporarlos en las Bases de Datos de estas a efectos de seleccionar al ganador y para enviarles periódicamente boletines informativos.
        </p>
        <p>
          VOY no se hace responsable por pérdidas de información, interrupciones o incapacidades de la red, servidor, fallas en las transmisiones de las líneas telefónicas o fallas técnicas, sean estas provenientes de un error de tipo humano, defecto mecánico o electrónico, o de cualquier otra clase, que pudieren afectar la participación de los interesados en el sorteo. Cualquier cambio o inexactitud de la dirección de correo electrónico o datos otorgados previamente por el participante, son responsabilidad de este y no darán derecho a reclamación de ninguna clase.
        </p>
        <p>
          VOY no será responsables por ningún daño o perjuicio, de cualquier tipo, que pudieren sufrir el ganador y/u otro/s, en sus personas o bienes, con motivo de o con relación a su participación en el sorteo o a la utilización del premio; ni por desperfectos técnicos, errores humanos o acciones deliberadas de terceros que pudieran interrumpir o alterar el normal desarrollo del sorteo.
        </p>
        <p>
          VOY se reservan el derecho de establecer y pronunciarse sobre aquellas situaciones o circunstancias que no estén expresamente previstas en estas bases y condiciones, reservándose asimismo el derecho de cancelar, suspender, o modificar las bases y condiciones del sorteo por circunstancias no previstas que sean ajenas a su voluntad, aclarándose que, cualquier modificación será ajustada a la legislación vigente en la materia y debidamente comunicada a los participantes, todo sin derecho a reclamación e indemnización alguna de parte de estos.
        </p>
        <p>
          La sola participación en el sorteo implica el conocimiento y aceptación de las presentes bases, términos y condiciones.
        </p>
      </div>
      <Clients />
      <Footer />
    </div>
  );
}

export default TermsDrawSummer;
