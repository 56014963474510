/* eslint-disable linebreak-style */
import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import '../../style/Filters/orderBy.css';
import { isMobile } from 'react-device-detect';
import { OrderByIcon } from '../../img';

function OrderBy({ orderBy, setOrderBy }) {
  const handleChange = (event) => {
    setOrderBy(event.target.value);
  };

  return (
    <div className="orderBy">
      <p className="desktopElement">Ordenar por</p>
      <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
        <InputLabel
          className="inputLabel desktopElement"
          id="demo-select-small"
          focused={false}
          sx={{
            '& .MuiFormLabel-colorSecondary': {
              color: 'white',
            },
          }}
        >
          Ordenar por
        </InputLabel>
        <img className="mobileElement" src={OrderByIcon} alt="Ordenar" />
        <Select
          sx={{
            '& .MuiSvgIcon-root': {
              color: 'white',
            },
            '&.MuiInputBase-root': {
              '& fieldset': {
                borderColor: 'white',
                borderRadius: '100px',
                width: '180',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#47FFBD',
                borderWidth: '1px',
              },
              '& .MuiPaper-root .MuiList-padding': {
                backgroundColor: 'black',
                padding: '0',
              },

            },
          }}
          labelId="demo-select-small"
          id="demo-select-small"
          value={orderBy}
          label="Relevancia"
          onChange={handleChange}
          renderValue={isMobile ? (selected) => 'Ordenar' : null}
        >
          <MenuItem
            value="relevance"
            sx={{
              color: 'white',
            }}
          >
            Relevancia
          </MenuItem>
          <MenuItem
            value="priceHigh"
            sx={{
              color: 'white',
            }}
          >
            Mayor precio
          </MenuItem>
          <MenuItem
            value="priceLow"
            sx={{
              color: 'white',
            }}
          >
            Menor precio
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default OrderBy;
