/* eslint-disable linebreak-style */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { React, useEffect } from 'react';
import 'antd/dist/antd.css';
import '../../style/Popups/calendar.css';
/* import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; */
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { setFilters } from '../../state/AppState';

function Calendar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const applyFilters = (item) => {
    const newFilters = { ...props.filters };
    newFilters.date = [];
    if (item.length === 2) {
      const range = [item[0].startOf('day')._d.toISOString(), item[1].endOf('day')._d.toISOString()];
      newFilters.date?.push('range', range);
    }
    props.setFiltersState(newFilters);
    const view = location.pathname.split('/')[1];
    if (view !== 'ver-eventos' && view !== 'view-map') {
      navigate('/ver-eventos');
    }
  };

  // const [value, setValue] = React.useState(null);
  const { RangePicker } = DatePicker;
  const dateFormat = 'DD/MM/YYYY';
  // const StyledRangePicker = styled(RangePicker);
  return (
    <Space id="filtro-cuando-calendario" direction="vertical" size={12}>
      <RangePicker
        placeholder={props.filters.date?.includes('range') ? [props.filters.date[1][0], props.filters.date[1][1]] : ['Fecha Inicio', 'Fecha Final']}
        format={dateFormat}
        onChange={(item) => applyFilters(item)}
        disabledDate={(current) => {
          const customDate = moment().format('YYYY-MM-DD');
          return current && current < moment(customDate, 'YYYY-MM-DD');
        }}
      />
    </Space>

  );
}
const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  eventTypes: state.app.eventTypes,
  filters: state.app.filters,
});

export default connect(mapStateToProps, {
  setFiltersState: setFilters,
})(
  Calendar,
);
