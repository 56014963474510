/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import '../../style/Site/contact.css';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { connect } from 'react-redux';
import { endpointContactForm } from '../../endpoints';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import { setLoading } from '../../state/AppState';
import FilterSwitch from '../Filters/FilterSwtich';
import Message from '../Popups/Message';

function Contact({ setLoadingState }) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  const [response, setResponse] = useState([]);

  async function onSubmitHandler(data) {
    const checkboxes = document.querySelectorAll('input[type=checkbox]');
    const contactData = {
      contact: {
        ...data,
        is_artist: checkboxes[0].checked ? 1 : 0,
        is_producer: checkboxes[1].checked ? 1 : 0,
        has_venue: checkboxes[2].checked ? 1 : 0,
      },
    };
    try {
      // setLoadingState(true);
      await axios.post(endpointContactForm, contactData).then((res) => {
        setLoadingState(false);
        setResponse(res.data);
      }, (err) => err);
    } catch (error) {
      setLoadingState(false);
      return error;
    }
  }

  return (
    <div className="contact">
      <Header />
      <div className="container-form">
        <h1>Formá parte</h1>
        <h2>todo está en voy</h2>

        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="container-swtich-form">
            <FilterSwitch
              className="is_artist"
              titleCheckbox="Soy artista"
            />
            <FilterSwitch
              className="is_producer"
              titleCheckbox="Soy productor"
            />
            <FilterSwitch
              className="has_venue"
              titleCheckbox="Gestiono un espacio"
            />
            <FilterSwitch
              titleCheckbox="Otro"
            />
          </div>
          <div className="sub-container-inputs">
            <div className="container-inputs">
              <input {...register('name', { required: '*El campo de nombre es obligatorio' })} placeholder="Nombre" />
              <span>{errors.name?.message}</span>
              <input {...register('email', { required: '*El campo de email es obligatorio' })} type="email" placeholder="Email" />
              <span>{errors.email?.message}</span>
              <input {...register('phone', { required: '*El campo de celular es obligatorio' })} placeholder="Celular" />
              <span>{errors.phone?.message}</span>
            </div>
            <div className="container-inputs">
              <textarea {...register('message')} placeholder="Mensaje" />
            </div>
          </div>
          <Message value="Enviar" response={response} />
        </form>
      </div>
      <Footer />
    </div>
  );
}
const mapStateToProps = (state) => ({
  loginStage: state.app.loginStage,
});
export default connect(mapStateToProps, {
  setLoadingState: setLoading,
})(Contact);
