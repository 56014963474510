/* eslint-disable linebreak-style */
/* eslint-disable react/no-unescaped-entities */
import { React, useEffect } from 'react';
import '../../style/Site/frequentQuestions.css';
import Question from '../Questions/Question';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import Clients from '../Advertisments/Clients';
import { BlurPruple, BlurGreen } from '../../img';

function FrequentQuestions() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className="frequent-questions">
      <Header />
      <img src={BlurPruple} alt="" className="blur-purple blur" />
      <img src={BlurGreen} alt="" className="blur-green blur" />
      <div className="container-frequent-questions">
        <h2>Preguntas frecuentes</h2>
        <div className="title-frequent-questions">Quiero saber sobre voy</div>
        <Question
          titleQuestion="¿Que es voy?"
          textQuestion="Voy es la plataforma que integra en un mismo lugar toda la oferta cultural y para el disfrute de Uruguay. Es el puente entre el universo cultural y las personas que aman disfrutar de su tiempo libre a través de la variedad de propuestas que ofrece nuestro país."
        />
        <Question
          titleQuestion="¿Que beneficios ofrece?"
          textQuestion="En VOY está todo. Espectáculos, conciertos, teatro, cine, danza y toda la actividad cultural. Desde lo ya conocido hasta lo más emergente. Si sos artista, productor/a, director/a de sala o escenario o tenés un espacio que ofrece propuestas artísticas podés cargar tu evento o espectáculo en voy.com.uy y hacerlo visible a miles de personas de manera muy sencilla y gratuita."
        />
        <Question
          titleQuestion="¿Quiero recibir información sobre los espectáculos que me gustan?"
          textQuestion="Registrate en voy.com.uy, seleccioná tus preferencias y te haremos llegar las propuestas que mejor se ajusten a ti."
        />
        <Question
          titleQuestion="Soy artista/productor/a y quiero cargar mi espectáculo"
          textQuestion="Registrate en voy.com.uy y completá tu perfil de artista. Una vez que el equipo de VOY, apruebe tu perfil, ya estarás habilitado para cargar tu evento."
        />
        <Question
          titleQuestion="Soy director/a de sala o escenario que gestiona un espacio y quiero cargar mi evento"
          textQuestion="Registrate en voy.com.uy, si tu locación no está en la plataforma, deberás cargarla. Una vez que el equipo de VOY apruebe tu locación, ya estarás habilitado para cargar tu evento."
        />
        <Question
          titleQuestion="Necesito ayuda para cargar mi perfil de artista o locación"
          textQuestion="Contactate con el equipo de VOY a info@voy.com.uy y te ayudaremos a hacerlo."
        />
      </div>
      <Clients />
      <Footer />
    </div>
  );
}

export default FrequentQuestions;
