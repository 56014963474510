/* eslint-disable linebreak-style */
import { React, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import DarkStyle from './stylesMap';
import { setFilters } from '../../state/AppState';
import LocationMarker from './LocationMarker';
import Location from './Location';
import '../../style/Map/map.css';

function Map(props) {
  const [selectedLocation, setSelectedLocation] = useState(
    props.filters.location.length > 0
      ? props.currentLocations.find(
        (l) => l.location.id === props.filters.location[0],
      )
      : null,
  );

  const [coords, setCoords] = useState(null);
  const [myLocation, setMyLocation] = useState(false);

  useEffect(() => {
    if (isMobile) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMyLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
          if (props.centerOnMe) {
            setCoords({ lat: position.coords.latitude, lng: position.coords.longitude });
          }
        },
        (error) => {
          console.log(error);
        },
      );
    }
  }, []);

  useEffect(() => {
    if (props.centerOnMe) {
      setCoords(myLocation);
    }
  }, [myLocation]);

  useEffect(() => {
    setSelectedLocation(
      props.filters.location.length > 0
        ? props.currentLocations.find(
          (l) => l.location.id === props.filters.location[0],
        )
        : null,
    );
  }, [props.filters.location, props.currentLocations]);

  useEffect(() => {
    if (props.filters?.neighbourhood?.length > 0) {
      const neighbourhood = props.localities.find(
        (l) => l.id === props.filters.neighbourhood[0],
      );
      setCoords({
        lat: parseFloat(neighbourhood?.lat),
        lng: parseFloat(neighbourhood?.lng),
      });
    }
  }, [props?.filters?.neighbourhood, props.localities]);

  useEffect(() => {
    if (props.filters?.location?.length > 0) {
      const location = props.currentLocations.find(
        (l) => l.location.id === props.filters.location[0],
      );
      setCoords({
        lat: parseFloat(location?.location.latitude),
        lng: parseFloat(location?.location.longitude),
      });
    }
  }, [props.filters?.location, props.currentLocations]);

  useEffect(() => {
    if (props.filters?.city?.length > 0) {
      const city = props.cities.find((c) => c.id === props.filters.city[0]);
      setCoords({
        lat: parseFloat(city?.latitude),
        lng: parseFloat(city?.longitude),
      });
    }
  }, [props.filters?.city, props.cities]);

  const unsetSelectedLocation = () => {
    setSelectedLocation(null);
    const filters = { ...props.filters };
    filters.location = [];
    props.setFiltersState(filters);
  };
  return (
    <div className="map-container">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyAYXNdR0-Ve0SW03CBIgjM16KONtIBoHEw',
          language: 'es',
        }}
        style={{ height: '100%', width: '100%' }}
        options={{ styles: DarkStyle.darkStyle, gestureHandling: 'greedy' }}
        defaultZoom={15}
        defaultCenter={{
          lat: props.latitude ? parseFloat(props.latitude) : -34.90328,
          lng: props.longitude ? parseFloat(props.longitude) : -56.18816,
        }}
        center={coords}
      >
        {myLocation ? (
          <LocationMarker
            key="myLocation"
            location=""
            lat={myLocation?.lat}
            lng={myLocation?.lng}
            eventTypes="0"
          />
        ) : ''}
        {props.currentLocations.map((location) => (
          <LocationMarker
            key={location.location.id}
            location={location}
            lat={location.location.latitude}
            lng={location.location.longitude}
            eventTypes={location.eventTypes}
            onClick={(location) => {
              setSelectedLocation(location);
              const newFilters = { ...props.filters };
              newFilters.distance = [];
              newFilters.neighbourhood = [];
              newFilters.location = [location.location.id];
              props.setFiltersState(newFilters);
            }}
            selectedLocation={selectedLocation}
          />
        ))}
      </GoogleMapReact>
      {selectedLocation && (
        <Location
          location={selectedLocation}
          close={unsetSelectedLocation}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  locations: state.app.locations,
  localities: state.app.localities,
  filters: state.app.filters,
  events: state.app.events,
  cities: state.app.cities,
});

export default connect(mapStateToProps, {
  setFiltersState: setFilters,
})(Map);
