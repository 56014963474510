/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import '../../style/Advertisments/clients.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import {
  ClientOne,
  ClientTwo,
  ClientThree,
  ClientFour,
  ClientFive,
  ClientSix,
  ClientSeven,
  ClientEight,
  ClientNine,
  ClientTen,
  ClientEleven,
  ClientTwelve,
  ClientThirteen,
  Buemes,
  Hiper,
  Jetmar,
  TiendaViajes,
  TocToc,
} from '../../img';

function Clients() {
  const clients = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    draggable: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3,
          centerPadding: '50px',
          autoplay: true,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          centerPadding: '70px',
          centerMode: true,
          autoplay: true,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="containerClients">
      <Slider {...clients}>
        {/*<img src={ClientTwo} alt="Clients" className="heightCLient" />*/}
        {/*<img src={ClientThree} alt="Clients" className="heightCLient" />*/}
        {/*<img src={ClientFour} alt="Clients" className="heightCLientItau" />*/}
        <img src={ClientFive} alt="Clients" />
        <img src={ClientSix} alt="Clients" />
        {/*<img src={ClientSeven} alt="Clients" />*/}
        {/*<img src={ClientNine} alt="Clients" />*/}
        <img src={ClientTen} alt="Clients" />
        {/*<img src={ClientEleven} alt="Clients" />*/}
        {/*<img src={ClientTwelve} alt="Clients" />*/}
        <img src={ClientThirteen} alt="Clients" />
        {window.location.pathname.includes('sorteos/participar') ? (
          <a
            href="https://buemes.com.uy/paquetes?utm_source=WEB&utm_campaign=VOY&utm_id=VOY"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Buemes} alt="Clients" />
          </a>
        ) : ''}
        {window.location.pathname.includes('sorteos/participar') ? (
          <a
            href="https://www.hiperviajes.com.uy/?utm_source=voy&utm_medium=voy&utm_campaign=voy&utm_term=voy&utm_content=sorteo"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Hiper} alt="Clients" />
          </a>
        ) : ''}
        {window.location.pathname.includes('sorteos/participar') ? (
          <a
            href="https://jetmar.com.uy/vuelos?utm_source=WEB&utm_campaign=VOY&utm_id=VOY"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Jetmar} alt="Clients" />
          </a>
        ) : ''}
        {window.location.pathname.includes('sorteos/participar') ? (
          <a
            href="https://tiendaviajes.com.uy/?utm_source=WEB&utm_campaign=VOY&utm_id=VOY"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TiendaViajes} alt="Clients" />
          </a>
        ) : ''}
        {window.location.pathname.includes('sorteos/participar') ? (
          <a
            href="https://www.toctocviajes.com/promociones/?utm_source=VOY&utm_medium=Voy&utm_term=VOY&utm_content=Voy&utm_campaign=DICIEMBRE"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TocToc} alt="Clients" />
          </a>
        ) : ''}
      </Slider>
    </div>
  );
}

export default Clients;
