/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import '../../style/Buttons/star.css';

function Star(props) {
  const [openStar, setOpenStar] = useState(false);
  return (
    <motion.button
      href="."
      onClick={() => {
        setOpenStar(!openStar);
      }}
      className={
        openStar === true
          ? 'star starSelected'
          : 'star'
              }
      transition={{
        duration: 1,
        ease: 'easeInOut',
      }}
      whileHover={{
        scale: [1, 0.8, 1],
        rotate: [360, 360],
        borderRdius: ['0%', '20%', '0%', '20%'],
      }}
    >
      <svg className={props.typeEvent} width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="background-star" d="M11.1289 1.49918L13.1687 7.77688C13.3755 8.41328 13.9685 8.84416 14.6377 8.84416L21.2384 8.84416L15.8983 12.724C15.3569 13.1173 15.1304 13.8145 15.3372 14.4509L17.3769 20.7286L12.0368 16.8487C11.4955 16.4554 10.7624 16.4554 10.221 16.8487L4.88092 20.7286L6.92067 14.4509C7.12745 13.8145 6.90092 13.1173 6.35957 12.724L1.01944 8.84416L7.6202 8.84416C8.28935 8.84416 8.8824 8.41328 9.08918 7.77688L11.1289 1.49918Z" strokeWidth="1.54458" />
      </svg>
    </motion.button>

  );
}

export default Star;
