/* eslint-disable linebreak-style */
import React from 'react';
import '../../style/Filters/resultsFilters.css';
import { connect } from 'react-redux';
import { setFilters } from '../../state/AppState';
import {
  ArrowResultsFilters,
  Cruz,
} from '../../img';
import {
  getShortDate,
  getNeighbourhoodName, getLocation, getCityName, getEventTypeName, filtersWipe,
} from '../../service';

function ResultsFilters({
  filters, eventTypes, localities, cities, locations, setFiltersState,
}) {
  return (
    <div className="resultsFilters" data-aos="fade-right" data-aos-duration="1000">
      <p className="results">Voy</p>
      {filters.date?.length > 0 ? (
        <>
          <img src={ArrowResultsFilters} alt="icon" />
          <p className="results">
            {filters.date.includes('range') ? (
              `${getShortDate(filters.date[1][0].substring(0, 10))} - ${getShortDate(filters.date[1][1].substring(0, 10))}`
            ) : (
              filters.date?.map((date, index) => ((index > 0 ? ', ' : '') + date)).join('')
            )}
          </p>
        </>
      ) : ''}

      {filters.type?.length > 0 ? (
        <>
          <img src={ArrowResultsFilters} alt="icon" />
          <p className="results">
            {filters.type?.map((type, index) => ((index > 0 ? ', ' : '') + getEventTypeName(type, eventTypes))).join('')}
          </p>
        </>
      ) : ''}

      {filters.neighbourhood?.length > 0 ? (
        <>
          <img src={ArrowResultsFilters} alt="icon" />
          <p className="results">
            {getNeighbourhoodName(filters.neighbourhood, localities)}
          </p>
        </>
      ) : ''}

      {filters.location?.length > 0 ? (
        <>
          <img src={ArrowResultsFilters} alt="icon" />
          <p className="results">
            {getLocation(filters.location, locations).name}
          </p>
        </>
      ) : ''}

      {filters.city?.length > 0 ? (
        <>
          <img src={ArrowResultsFilters} alt="icon" />
          <p className="results">
            {getCityName(filters.city, cities)}
          </p>
        </>
      ) : ''}

      {Object.values(filters).flat().length > 0 ? (
        <button type="button" onClick={() => { filtersWipe(setFiltersState); }}><img src={Cruz} alt="Quitar Filtros" /></button>
      ) : ''}

    </div>
  );
}

const mapStateToProps = (state) => ({
  events: state.app.events,
  bands: state.app.bands,
  locations: state.app.locations,
  localities: state.app.localities,
  cities: state.app.cities,
  eventTypes: state.app.eventTypes,
  sponsors: state.app.sponsors,
  filters: state.app.filters,
  restaurants: state.app.restaurants,
});

export default connect(mapStateToProps, { setFiltersState: setFilters })(ResultsFilters);
