/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Routes, Route, Link, Outlet,
} from 'react-router-dom';
import '../../style/Popups/survey.css';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { connect } from 'react-redux';
import { endpointCloudfront, endpointSurveyAnswer, endpointSurveyEmail } from '../../endpoints';
import {
  setShowedPopup, setLoading, setSurveyPopup,
} from '../../state/AppState';
import {
  CloseToggle,
  SurveyImg,
  Surveys1,
  Surveys1Selected,
  Surveys2,
  Surveys2Selected,
  Surveys3,
  Surveys3Selected,
  Surveys4,
  Surveys4Selected,
  Surveys5,
  Surveys5Selected,
  SurveysBackground,
  SurveysBackgroundMobile,
} from '../../img';
import { getCurrentDate } from '../../service';

function Survey({
  surveys, surveyPopup, setSurveyPopupState,
}) {
  const [openSurvey, setOpenSurvey] = useState(false);
  const [surveyProcessing, setSurveyProcessing] = useState(false);
  const [surveyStage, setSurveyStage] = useState(1);
  const [surveyQuestion, setSurveyQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  useEffect(() => {
    const surveyId = surveys?.id;
    const date = getCurrentDate();
    const location = window.location.pathname;
    // setSurveyPopupState(false);
    if (surveyPopup !== surveyId && surveyPopup !== date && !location.includes('view-map') && surveys?.id?.length > 0) {
      setOpenSurvey(true);
    } else {
      setOpenSurvey(false);
    }
  }, [surveys]);

  function handleSelect(id) {
    setSelectedOption(id);
  }

  function renderSurveyQuestion() {
    const question = surveys?.surveyQuestions[surveyQuestion];
    if (question.survey_question_type_id === '1') {
      return (
        <>
          <h2>{question.text}</h2>
          <div className="rating">
            <button
              data-id="1"
              type="button"
              className={selectedOption === 1 ? 'selected' : ''}
              onClick={() => (!isMobile ? handleSelect(1) : '')}
              onTouchEnd={() => (isMobile ? handleSelect(1) : '')}
            >
              <img src={Surveys1} alt="1" />
              <img src={Surveys1Selected} alt="1selected" />
            </button>
            <button
              data-id="2"
              type="button"
              className={selectedOption === 2 ? 'selected' : ''}
              onClick={() => (!isMobile ? handleSelect(2) : '')}
              onTouchEnd={() => (isMobile ? handleSelect(2) : '')}
            >
              <img src={Surveys2} alt="2" />
              <img src={Surveys2Selected} alt="2selected" />
            </button>
            <button
              data-id="3"
              type="button"
              className={selectedOption === 3 ? 'selected' : ''}
              onClick={() => (!isMobile ? handleSelect(3) : '')}
              onTouchEnd={() => (isMobile ? handleSelect(3) : '')}
            >
              <img src={Surveys3} alt="3" />
              <img src={Surveys3Selected} alt="3selected" />
            </button>
            <button
              data-id="4"
              type="button"
              className={selectedOption === 4 ? 'selected' : ''}
              onClick={() => (!isMobile ? handleSelect(4) : '')}
              onTouchEnd={() => (isMobile ? handleSelect(4) : '')}
            >
              <img src={Surveys4} alt="4" />
              <img src={Surveys4Selected} alt="4selected" />
            </button>
            <button
              data-id="5"
              type="button"
              className={selectedOption === 5 ? 'selected' : ''}
              onClick={() => (!isMobile ? handleSelect(5) : '')}
              onTouchEnd={() => (isMobile ? handleSelect(5) : '')}
            >
              <img src={Surveys5} alt="5" />
              <img src={Surveys5Selected} alt="5selected" />
            </button>
          </div>
        </>
      );
    }
    if (question.survey_question_type_id === '2') {
      return (
        <>
          <h2>{question.text}</h2>
          <textarea
            // onKeyUp={() => {
            //   setSelectedOption(document
            //     .querySelectorAll('.surveyPopup textarea')[0]?.value.length > 0 ? 0 : null);
            // }}
            placeholder="Coméntanos que te gustaría mejorar..."
          />
        </>
      );
    }
    if (question.survey_question_type_id === '3') {
      return (
        <>
          <h2>{question.text}</h2>
          <div className="options">
            {question.options.map((o) => (
              <button
                key={o.id}
                data-id={o.id}
                type="button"
                className={selectedOption === o.id ? 'selected' : ''}
                onClick={() => (!isMobile ? handleSelect(o.id) : '')}
                onTouchEnd={() => (isMobile ? handleSelect(o.id) : '')}
              >
                {o.title}

              </button>
            ))}
          </div>
        </>
      );
    }
    return null;
  }

  async function handleSubmitAnswer() {
    let data = {};
    const question = surveys?.surveyQuestions[surveyQuestion];
    data = {
      survey_question_id: question.id,
      answer: {
        value: question.survey_question_type_id !== '2' ? selectedOption.toString() : 0,
        text: document.querySelectorAll('.surveyPopup textarea')[0]?.value || '-',
      },
    };

    await axios.post(endpointSurveyAnswer, data).then((res) => {
      if (res.data.status === 'ok') {
        setSelectedOption(null);
        if (surveyQuestion < surveys?.surveyQuestions.length - 1) {
          setSurveyQuestion(surveyQuestion + 1);
        } else {
          setSurveyStage(surveyStage + 1);
        }
        setSurveyProcessing(false);
      }
    }, (err) => err);
  }

  async function handleSubmitEmail() {
    const data = {
      survey_id: surveys?.id,
      contact: {
        name: document.querySelectorAll('.surveyPopup .name')[0]?.value,
        email: document.querySelectorAll('.surveyPopup .email')[0]?.value,
        phone: document.querySelectorAll('.surveyPopup .phone')[0]?.value,
      },
    };

    await axios.post(endpointSurveyEmail, data).then((res) => {
      if (res.data.status === 'ok') {
        setOpenSurvey(false);
        setSurveyPopupState(surveys?.id);
        setSurveyProcessing(false);
      }
    }, (err) => err);
  }

  function handleContinue() {
    setSurveyProcessing(true);
    setTimeout(() => {
      if (surveyStage === 1) {
        setSurveyProcessing(false);
        setSurveyStage(surveyStage + 1);
      }
      if (surveyStage === 2) {
        handleSubmitAnswer();
      }
      if (surveyStage === 3) {
        handleSubmitEmail();
      }
    }, 500);
  }

  function renderSwitch() {
    if (surveys) {
      const question = surveys?.surveyQuestions[surveyQuestion];
      switch (surveyStage) {
        case 1:
          return (
            <>
              <img src={endpointCloudfront + surveys?.file?.webp_url || SurveyImg} alt="survey" />
              <div className="flex">
                <h1>{surveys?.title}</h1>
                <h3>{surveys?.subtitle}</h3>
                <button
                  className="continueButton"
                  onClick={() => (!isMobile ? handleContinue() : '')}
                  onTouchEnd={() => (isMobile ? handleContinue() : '')}
                  type="button"
                >
                  Continuar

                </button>
              </div>
            </>
          );
        case 2:
          return (
            <>
              {renderSurveyQuestion()}
              <button
                disabled={selectedOption === null && surveyStage !== 1 && question.survey_question_type_id !== '2'}
                className="continueButton"
                onClick={() => (!isMobile ? handleContinue() : '')}
                onTouchEnd={() => (isMobile ? handleContinue() : '')}
                type="button"
              >
                Continuar

              </button>
            </>
          );
        case 3:
          return (
            <>
              <h1>
                ¡Gracias por compartir
                <br />
                tu experiencia en VOY!
              </h1>
              <h3>
                Dejanos tus datos para participar
                <br />
                del sorteo por entradas.
              </h3>
              <form onSubmit={handleSubmit(handleContinue)}>
                <input
                  className="name"
                  {...register('name', { required: '*El campo de nombre es obligatorio' })}
                  type="text"
                  placeholder="Nombre"
                />
                <span>{errors.name?.message}</span>
                <input
                  className="email"
                  {...register('email', { required: '*El campo de email es obligatorio' })}
                  type="email"
                  placeholder="Email"
                />
                <span>{errors.email?.message}</span>
                <input
                  className="phone"
                  {...register('phone', { required: '*El campo de teléfono es obligatorio' })}
                  type="text"
                  placeholder="Teléfono"
                />
                <span>{errors.phone?.message}</span>
                <button className="continueButton" type="submit">Continuar</button>
              </form>
            </>
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  return (
    <>
      {openSurvey ? (
        <div className="surveyPopup">
          <div style={{ backgroundImage: `url(${!isMobile ? SurveysBackground : SurveysBackgroundMobile})` }}>
            <button
              type="button"
              onClick={() => {
                if (!isMobile) {
                  setOpenSurvey(false);
                  const date = getCurrentDate();
                  setSurveyPopupState(date);
                }
              }}
              onTouchEnd={() => {
                if (isMobile) {
                  setOpenSurvey(false);
                  const date = getCurrentDate();
                  setSurveyPopupState(date);
                }
              }}
              className="closeLogin"
            >
              <img src={CloseToggle} alt="" />
            </button>
            <div className={(surveyStage === 1 ? 'flex firstSlide' : surveyStage === 2 ? 'question' : 'flex')
            + (surveyProcessing ? ' hidden' : '')}
            >
              {renderSwitch()}
            </div>
          </div>
        </div>
      ) : ''}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  showedPopup: state.app.showedPopup,
  surveyPopup: state.app.surveyPopup,
  surveys: state.app.surveys,
});
export default connect(mapStateToProps, {
  setShowedPopupState: setShowedPopup,
  setSurveyPopupState: setSurveyPopup,
  setLoadingState: setLoading,
})(Survey);
