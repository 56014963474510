/* eslint-disable linebreak-style */
import { combineReducers } from 'redux';

// ## Generator Reducer Imports
import app from '../state/AppState';

export default combineReducers({
  // ## Generator Reducers
  app,
});
