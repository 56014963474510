/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import {
  Routes, Route, Link, Outlet,
} from 'react-router-dom';
import '../../style/Login/login.css';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  setOpenLogin, setLoginToken, setLoginStage, setReferralLink, setShowedPopup, setLoading,
  setDailyPopup,
} from '../../state/AppState';
import {
  SorteosVerano,
  SorteosVeranoMobile,
  CloseToggle,
  User,
  RegisterLoginImg,
  CopiarLogin,
  BienvenidoLoginImg,
} from '../../img';

import { getCurrentDate } from '../../service';

function DailyPopup({
  openLogin, setOpenLoginState, loginStage, loginToken, giveaways,
  setLoginStageState, setLoginTokenState, setLoadingState,
  setReferralLinkState, referredByCode, isLoading, showedPopup, setShowedPopupState,
  dailyPopup, setDailyPopupState,
}) {
  const [openDailyPopup, setOpenDailyPopup] = useState(false);

  useEffect(() => {
    const date = getCurrentDate();
    const location = window.location.pathname;
    if (dailyPopup !== date && !location.includes('sorteos/participar') && !location.includes('view-map') && giveaways?.length > 0) {
      // Descomentar para activar daily popup
      // setOpenDailyPopup(true);
      setDailyPopupState(date);
    }
    if (location.includes('sorteos/participar')) {
      setOpenDailyPopup(false);
    }
  }, [giveaways]);

  return (
    <>
      {openDailyPopup ? (
        <div className="dailyPopup">
          <div>
            <button
              type="button"
              onClick={() => {
                setOpenDailyPopup(false);
              }}
              className="closeLogin"
            >
              <img src={CloseToggle} alt="" />
            </button>
            <Link onClick={() => { setOpenDailyPopup(false); }} to="/sorteos/participar">
              <img src={SorteosVerano} alt="sorteoVerano" className="desktopElement" />
              <img src={SorteosVeranoMobile} alt="sorteoVerano" className="mobileElement" />
            </Link>
          </div>
        </div>
      ) : ''}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  openLogin: state.app.openLogin,
  loginStage: state.app.loginStage,
  loginToken: state.app.loginToken,
  referredByCode: state.app.referredByCode,
  showedPopup: state.app.showedPopup,
  dailyPopup: state.app.dailyPopup,
  giveaways: state.app.giveaways,
});
export default connect(mapStateToProps, {
  setOpenLoginState: setOpenLogin,
  setLoginTokenState: setLoginToken,
  setLoginStageState: setLoginStage,
  setReferralLinkState: setReferralLink,
  setShowedPopupState: setShowedPopup,
  setDailyPopupState: setDailyPopup,
  setLoadingState: setLoading,
})(DailyPopup);
